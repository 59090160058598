import React, { useEffect, useRef, useState, useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormProvider } from 'react-hook-form';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../components/ui/select';
import { DialogFooter, DialogClose } from '../../components/ui/dialog';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { useFetchLocationData } from '@/queries/location-query';
import { useCreateFacultyData, useFetchFacultyData } from '@/queries/faculty-query';
import {
    useFetchSubjectData,
    useFetchBatchSlotData,
    useUpdateFacultyData,
} from '@/queries/faculty-query';
import { log } from 'console';
import CustomSelect from '@/components/shared/custom-select';
import { Value } from '@radix-ui/react-select';

const formSchema = z.object({
    facultyCode: z.string().min(1, {
        message: 'This is required',
    }),
    //Faculties: z.array(z.string()).min(1, { message: 'This is required' }),
    firstName: z
        .string()
        .min(2, { message: 'This is required' })
        .regex(/^[A-Za-z]+$/, { message: 'Only alphabetic characters are allowed' }),
    lastName: z
        .string()
        .min(2, { message: 'This is required' })
        .regex(/^[A-Za-z\s]+$/, { message: 'Only alphabetic characters are allowed' })
        .transform((val) => val.trim()),
    Email: z
        .string()
        .min(6, { message: 'This is required' })
        .regex(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, { message: 'Invalid email format' }),
    Age: z.string().optional(),
    Address: z.string().optional(),
    locations: z.array(z.string()).min(1, { message: 'Please select at least one subject' }),
    subjects: z.array(z.string()).min(1, { message: 'Please select at least one subject' }),
    gender: z.string().min(2, { message: 'This is required' }),
    colorCode: z.string().optional(),
    batchSlots: z.array(z.string()).min(1, { message: 'Please select at least one batch slot' }),
    phoneNo: z
        .string()
        .max(12, { message: 'This is required' })
        .regex(/^[0-9]+$/, { message: 'This is required' }),
    facultyExperience: z.string().regex(/^[0-9]+$/, { message: 'This is required' }),
    image: z.instanceof(File).optional(), // File input handling
});

type FacultyCreateProps = {
    faculty?: any; // Define the `faculty` prop type
    onClose: () => void; // Function to close the dialog
};

const FacultyCreate: React.FC<FacultyCreateProps> = ({ faculty, onClose }) => {
    console.log('faculty--', faculty);

    const params = { page: 1, limit: 10, sortBy: 'updated_at', sortOrder: 'DESC', filter: '' };
    const [currentStep, setCurrentStep] = useState(1);
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            Email: '',
            Age: '',
            Address: '',
            facultyCode: '',
            //Faculties: [],
            locations: faculty?.locatiin?.map((loc: any) => loc.id) || [],
            subjects: faculty?.subject?.map((sub: any) => sub.id) || [],
            gender: faculty?.gender
                ? faculty.gender.charAt(0).toUpperCase() + faculty.gender.slice(1)
                : '',
            colorCode: '',
            batchSlots: faculty?.batch_slot?.map((slot: any) => slot.id) || [],
            phoneNo: '',
            facultyExperience: '',
            // image: faculty?.image_url || '', // Default image URL
        },
    });
    const { handleSubmit, control, setValue } = form;

    // const { data: Faculties } = useFetchFacultyData(params);
    const { data: locationData } = useFetchLocationData(params);
    const { data: subjectData }: { data: any } = useFetchSubjectData(params);
    const { data: batchSlotData } = useFetchBatchSlotData(params);
    // const { data: batchStreamData } = useFetchBatchStreamData(params);
    const { mutate: createFacultyData } = useCreateFacultyData();
    const { mutate: updateFacultyData } = useUpdateFacultyData();
    const { toast } = useToast();

    // multiselect location,subject,batchSlot
    const locationOptions =
        locationData?.map((location: any) => ({
            value: location.id, // Use appropriate key for value
            label: location.name, // Use appropriate key for label
        })) || [];
    const batchSlotOptions =
        batchSlotData?.map((batchSlot: any) => ({
            value: batchSlot.id,
            label: batchSlot.name,
        })) || [];
    const subjectOptions =
        subjectData?.data?.map((subject: any) => ({
            value: subject.id,
            label: subject.subject_name,
        })) || [];
    // When faculty data changes, update the form's default values
    useEffect(() => {
        if (faculty) {
            setValue('firstName', faculty.first_name);
            setValue('lastName', faculty.last_name.trim());
            setValue('Email', faculty.mail);
            setValue('Age', faculty.age || '');
            setValue('Address', faculty.address || '');
            setValue('facultyCode', faculty.faculty_code);
            // setValue('locations', faculty.location_id);
            setValue(
                'locations',
                faculty.location ? faculty.location.map((loc: any) => loc.id) : []
            );
            setValue('subjects', faculty.subject ? faculty.subject.map((sub: any) => sub.id) : []);
            setValue(
                'gender',
                faculty.gender.charAt(0).toUpperCase() + faculty.gender.slice(1) || ''
            );
            setValue('colorCode', faculty.color_code);
            setValue(
                'batchSlots',
                faculty.batch_slot ? faculty.batch_slot.map((batch: any) => batch.id) : []
            );
            setValue('phoneNo', faculty.phone);
            setValue('facultyExperience', faculty.experience);
            // setValue('image', faculty.image_url || ''); // Update image URL
        }
    }, [faculty, setValue]);
    // capitalizeFirstLetter
    const capitalizeFirstLetter = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };
    function onSubmit(values: z.infer<typeof formSchema>) {
        const formData = new FormData();

        if (values.Age !== undefined) {
            formData.append('age', values.Age);
        }
        if (values.Address !== undefined) {
            formData.append('address', values.Address);
        }

        // const facultyIds = values.Faculties?.map((item: any) => item) || [];

        const morning: string[] = []; // Assuming time intervals are strings
        const afternoon: string[] = []; // Assuming time intervals are strings
        const timeData = [];
        //values.slotTimes.forEach((timeInterval) => {
        //    const [startTime] = timeInterval.split('-');
        //    const [hours] = startTime.split(':');
        //    const startHour = parseInt(hours, 10);

        //    if (startHour < 12) {
        //        morning.push(timeInterval);
        //    } else {
        //        afternoon.push(timeInterval);
        //    }
        //});

        // return { morning, afternoon };

        // Handle form submission
        console.log('values--', values);
        const locationIds = values.locations.map((item: any) => item);
        console.log('locationIds--', locationIds);

        const subjectIds = values.subjects.map((item: any) => item);
        const batchslotIds = values.batchSlots.map((item: any) => item);

        formData.append('first_name', values.firstName);
        formData.append('last_name', values.lastName.trim());
        formData.append('mail', values.Email);
        formData.append('age', values.Age ?? '');
        formData.append('address', values.Address ?? '');
        formData.append('faculty_code', values.facultyCode);
        formData.append('gender', values.gender);
        formData.append('color_code', values.colorCode || '#000000');
        formData.append('phone', values.phoneNo);
        formData.append('experience', values.facultyExperience);
        locationIds.forEach((id) => formData.append('location_id[]', id));
        subjectIds.forEach((id) => formData.append('subject_id[]', id));
        batchslotIds.forEach((id) => formData.append('batch_slot_id[]', id));

        const file = values.image;
        if (file) {
            formData.append('image', file); // File input handling
        }
        try {
            if (faculty && faculty.id) {
                updateFacultyData(
                    { id: faculty.id, postData: formData },
                    {
                        onSuccess: () => {
                            toast({
                                title: 'Faculty Successfully Updated',
                                description: 'Faculty updated successfully.',
                            });
                            onClose();
                            setTimeout(() => {
                                window.location.reload();
                            }, 100);
                        },
                        onError: (error: any) => {
                            const errorMsg =
                                error.response && error.response.data
                                    ? error.response.data.errors.mail
                                    : error;
                            toast({
                                title: 'ERROR:',
                                variant: 'destructive',
                                description: errorMsg || 'There was an error updating the faculty',
                            });
                        },
                    }
                );
            } else {
                createFacultyData(formData, {
                    onSuccess: () => {
                        toast({
                            title: 'Faculty Successfully Created',
                            description: 'Faculty created successfully.',
                        });
                        onClose();
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    },
                    // onError: (error: any) => {
                    //     const errorMsg =
                    //         error.response && error.response.data
                    //             ? error.response.data.errors.mail
                    //             : error;
                    //     console.log('Submission failed:', error);

                    //     toast({
                    //         title: 'ERROR:',
                    //         variant: 'destructive',
                    //         description: errorMsg || 'There was an error creating the faculty. \n',
                    //     });
                    // },
                    onError: (error: any) => {
                        let errorMsg = 'There was an error creating the faculty.';

                        if (error.response && error.response.data.errors) {
                            // all validation error messages dynamically
                            const errors = error.response.data.errors;
                            errorMsg = Object.keys(errors)
                                .map((field) => ` ${errors[field].join(', ')}`)
                                .join('\n');
                        } else {
                            errorMsg = error.message || errorMsg;
                        }

                        console.log('Submission failed:', error);

                        toast({
                            title: 'ERROR:',
                            variant: 'destructive',
                            description: errorMsg,
                        });
                    },
                });
            }
        } catch (error) {
            toast({
                title: 'ERROR:',
                description: 'There was an error creating the faculty.',
            });
        }
    }
    const handleNext = () => {
        setCurrentStep((prev) => prev + 1);
    };

    const handlePrevious = () => {
        setCurrentStep((prev) => prev - 1);
    };
    console.log(subjectData);
    console.log(batchSlotData);
    return (
        <div className="bg-muted-40">
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <FormField
                            control={control}
                            name="firstName"
                            render={({ field }) => (
                                <FormItem>
                                    {/* <FormLabel>First Name</FormLabel> */}
                                    First Name:<span style={{ color: 'red' }}>*</span>
                                    <FormControl>
                                        <Input
                                            placeholder="Enter First Name"
                                            {...field}
                                            onChange={(e) => {
                                                setValue(
                                                    'firstName',
                                                    capitalizeFirstLetter(e.target.value)
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="lastName"
                            render={({ field }) => (
                                <FormItem>
                                    Last Name:<span style={{ color: 'red' }}>*</span>
                                    <FormControl>
                                        <Input
                                            placeholder="Enter Last Name"
                                            {...field}
                                            onChange={(e) => {
                                                setValue(
                                                    'lastName',
                                                    capitalizeFirstLetter(e.target.value)
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="facultyCode"
                            render={({ field }) => (
                                <FormItem>
                                    Faculty Code:<span style={{ color: 'red' }}>*</span>
                                    <FormControl>
                                        <Input
                                            placeholder="Enter Faculty Code"
                                            {...field}
                                            onChange={(e) => {
                                                const uppercaseValue = e.target.value.toUpperCase();
                                                field.onChange(uppercaseValue);
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="Email"
                            render={({ field, fieldState: { error } }) => (
                                <FormItem>
                                    Email:<span style={{ color: 'red' }}>*</span>
                                    <FormControl>
                                        <Input placeholder="Enter Email" {...field} />
                                    </FormControl>
                                    {error && (
                                        <p className="text-red-500 mt-1">{error.message}</p> // Display the error message
                                    )}
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="phoneNo"
                            render={({ field }) => (
                                <FormItem>
                                    Phone No:<span style={{ color: 'red' }}>*</span>
                                    <FormControl>
                                        <Input placeholder="Enter Phone No" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="gender"
                            render={({ field }) => (
                                <FormItem>
                                    Gender:<span style={{ color: 'red' }}>*</span>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select Gender" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {['Male', 'Female', 'Other'].map((gender) => (
                                                <SelectItem key={gender} value={gender}>
                                                    {gender}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="Age"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Age:</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter Age" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="Address"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Address:</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter Address" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="facultyExperience"
                            render={({ field }) => (
                                <FormItem>
                                    Faculty Experience:<span style={{ color: 'red' }}>*</span>
                                    <FormControl>
                                        <Input placeholder="Enter Experience" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="locations"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="location_ids">
                                        Locations<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <CustomSelect
                                            options={locationOptions}
                                            onChange={(selectedValues: string | string[]) => {
                                                field.onChange(selectedValues);
                                            }}
                                            multiple={true}
                                            selectedValues={field.value || []}
                                            label={'Locations'}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="subjects"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="subject_ids">
                                        Subjects<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <CustomSelect
                                            options={subjectOptions}
                                            onChange={(selectedValues: string | string[]) => {
                                                field.onChange(selectedValues);
                                            }}
                                            multiple={true}
                                            selectedValues={field.value || []}
                                            label={'subjects'}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="batchSlots"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="batch_slot_id">
                                        BatchSlots<span className="text-red-500">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <CustomSelect
                                            options={batchSlotOptions} // Use the mapped options
                                            onChange={(selectedValues: string | string[]) => {
                                                field.onChange(selectedValues); // Update the form with the selected locations
                                            }}
                                            multiple={true}
                                            selectedValues={field.value || []}
                                            label={'BatchSlots'}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="colorCode"
                            render={({ field }) => (
                                <FormItem>
                                    Color Code:
                                    <FormControl>
                                        <Input
                                            type="color"
                                            {...field}
                                            style={{
                                                cursor: 'pointer',
                                                width: '4rem',
                                                height: '3rem',
                                                padding: 0,
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                                appearance: 'none',
                                                marginTop: '0rem',
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="image"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Upload Image:</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files?.[0]) {
                                                    setValue('image', e.target.files[0]);
                                                }
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="flex justify-end bg-background gap-3 sticky bottom-0 pt-3">
                        <DialogFooter className="sm:justify-start">
                            <DialogClose asChild>
                                <Button
                                    className="bg-white-2 border border-gray-300"
                                    variant="secondary"
                                    onClick={onClose}>
                                    Close
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                        <Button type="submit">Submit</Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default FacultyCreate;
