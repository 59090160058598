import Layout from '@/components/layout/layout';
import FacultyDetailPage from '@/pages/faculty/faculty-detail';
import LoginPage from '@/pages/login/login-page';
import NotFound from '@/pages/not-found';
import { useAuth } from '@/providers/auth-provider';
import { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom';
import UpdateFaculty from '@/pages/faculty/faculty-edit/faculty-edit-page';
import BatchList from '@/pages/batch/batch-list';
import SchedulePage from '@/pages/schedule/schedule';
import BatchDetailsPage from '@/pages/batch/batch-details';
import FacultyDetailsPage from '@/pages/faculty/faculty-details';
import SchedulePage2 from '@/pages/schedule/schedule';
import FacultyList from '@/pages/faculty/faculty-list';
import LeaveCalendar from '@/pages/leave/leave-calendar';
import DashboardPage from '@/pages/dashboard/dashbaord-page';
import UserListPage from '@/pages/user-list/user-list-page';
import DoubtSession from '@/pages/doubt-session/doubt-session';
import CreatPassword from '@/pages/create-password/create-password';
import ForgotPassword from '@/pages/forgot-password/forgot-password';
import ResetPasswordRedirect from '@/pages/reset-Password/reset-Password';
import ResetPassword from '@/pages/reset-Password/reset-Password';
const ProtectedRoutes = () => {
    const { token } = useAuth();
    if (token) {
        return (
            <Suspense>
                <Layout>
                    <Outlet />
                </Layout>
            </Suspense>
        );
    } else {
        return <Navigate to="/login" />;
    }
};
export const routes = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoutes />,
        handle: [{ name: 'Home', path: '/' }],
        children: [
            {
                path: '/',
                element: <SchedulePage />,
                handle: [{ name: 'Schedules', path: '/' }],
            },
            {
                path: '/doubt-session',
                element: <DoubtSession />,
                handle: [{ name: 'Doubt Session', path: '/doubt-session' }],
            },
            {
                path: '/dashboard',
                element: <DashboardPage />,
                handle: [{ name: 'Dashbaord', path: '/dashboard' }],
            },
            {
                path: '/faculty-detail',
                element: <FacultyDetailPage />,
                handle: [{ name: 'Detail', path: '/faculty-detail' }],
            },
            {
                path: '/faculties/:id',
                element: <FacultyDetailsPage />,
                handle: [
                    { name: 'Faculties', path: '/faculties' },
                    { name: 'Details', path: '/faculties/:id' },
                ],
            },
            {
                path: '/batches',
                handle: [{ name: 'Batches', path: '/batches' }],
                children: [
                    {
                        path: '',
                        element: <BatchList />,
                        handle: [{ name: 'Batches', path: '/batches' }],
                    },
                    {
                        path: ':id',
                        element: <BatchDetailsPage />,
                        handle: [
                            { name: 'Batches', path: '/batches' },
                            { name: 'Details', path: '/batches/:id' },
                        ],
                    },
                ],
            },
            {
                path: 'faculties',
                element: <FacultyList />,
                handle: [{ name: 'Faculties', path: '/faculties' }],
            },
            {
                path: '/leaves',
                element: <LeaveCalendar />,
                handle: [{ name: 'Leaves and Holidays', path: '/leaves' }],
            },
            {
                path: '/users',
                element: <UserListPage />,
                handle: [{ name: 'Users', path: '/users' }],
            },
        ],
    },
    {
        path: '/login',
        element: <LoginPage />,
        handle: [{ name: 'Login', path: '/login' }],
    },
    {
        path: '/createPassword',
        element: <CreatPassword />,
        handle: [{ name: 'createPassword', path: '/createPassword' }],
    },
    {
        path: '/forgot',
        element: <ForgotPassword />,
        handle: [{ name: 'forgot', path: '/forgot' }],
    },
    {
        path: '/resetPassword',
        element: <ResetPassword />, // Add this route
    },

    {
        path: '/404',
        element: <NotFound />,
        handle: [{ name: 'Not Found', path: '/404' }],
    },
    // {
    //     path: '*',
    //     element: <Navigate to="/404" replace />,
    // },
]);
