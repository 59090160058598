import { useFetchBatch } from '@/queries/batch-query';
import { useDeleteSchedule, useGetSchedules } from '@/queries/schedule-query';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BatchSubjects } from './batch-subjects';
import { BatchInfo } from './batch-info';
import BigCalendar, { Event } from '@/components/shared/big-calendar/big-calendar';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useMutation } from '@tanstack/react-query';

import { Toaster } from '@/components/ui/toaster';
import { useToast } from '@/components/ui/use-toast';
const BatchDetailsPage: React.FC = () => {
    const [events, setEvents] = useState([]);
    const [scheduleEditId, setScheduleEditId] = useState<any>('');
    const [scheduleDeleteId, setScheduleDeleteId] = useState<any>(''); // Initialize as an empty string
    const [subjectId, setSubjectId] = useState<any>('');
    const { toast } = useToast();

    const [searchParams, setSearchParams] = useSearchParams();
    const date = searchParams.get('date');

    function handleDateChange(d: Date) {
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            date: moment(d).format('YYYY-MM-DD'),
        });
    }
    const { id } = useParams();
    const { data: batch, error: batchError, isLoading, refetch } = useFetchBatch(id || '');

    const { data: scheduleList = [], refetch: refetchScheduleList } = useGetSchedules(
        id && batch
            ? {
                  batch_id: id, //TODO: date range?
                  location_id: batch.locations[0].id,
              }
            : {},
        true
    );
    useEffect(() => {
        const eventList = scheduleList.map((schedule: any) => {
            const [startTime, endTime] = schedule.slot_time.split('-');
            const start = new Date(schedule.date + ' ' + startTime);
            const end = new Date(schedule.date + ' ' + endTime);
            const color = schedule.subject.subject_color_code;
            const title = `${schedule.faculty.faculty_code} - ${schedule.faculty.first_name} ${schedule.faculty.last_name[0]} `;
            const scheduleId = schedule.id;
            const schedules = schedule;

            const hasSchedule = true;
            // today or any future date is true it means editable schedule
            const isScheduleEditable =
                new Date(schedule.date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
            const scheduleEvent: Event = {
                start,
                end,
                color,
                title,
                scheduleId,
                hasSchedule,
                isScheduleEditable,
                schedules,
                ...{ batch: schedule.batch.batch_code },
                schedule,
                subjectId: schedule.subject.subject_id,
            };
            return scheduleEvent;
        });
        setEvents(eventList);
    }, [scheduleList]);

    const minTime = new Date();
    minTime.setHours(7, 0, 0, 0);
    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week
        },
    });
    const handleEditSchedule = (scheduleId: string) => {
        setScheduleEditId(scheduleId);
        console.log('Editing schedule with ID:', scheduleId);
        // Aap yahan apni edit logic likh sakte hain
    };

    const handleDeleteSchedule = (scheduleId: string) => {
        setScheduleDeleteId(scheduleId);
        // Aap yahan apni delete logic likh sakte hain
    };

    const { mutate: deleteScheduleData } = useMutation<any>({
        mutationFn: useDeleteSchedule,
        onSuccess: (data: any) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            refetchScheduleList();
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Can`t Delete',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    async function deleteSchedule() {
        await deleteScheduleData(scheduleDeleteId);
    }

    if (isLoading) return <>Loading</>;

    if (batchError) return <>ERROR</>;

    return (
        <div className="flex gap-2">
            <Toaster />

            <div className="w-full md:w-1/3 lg:w-1/4 h-[calc(100vh-6rem)] p-4 bg-background relative border">
                <BatchInfo batch={batch} refetchBatch={refetch} />
                <BatchSubjects batch={batch} refetchBatch={refetch} setSubjectId={setSubjectId} />
            </div>
            <div className="w-full md:w-2/3 lg:w-5/6 h-[calc(100vh-6rem)] overflow-auto p-4 bg-background relative border">
                <BigCalendar
                    events={events}
                    currentDate={date ? new Date(date) : new Date()}
                    onDateChange={handleDateChange}
                    handleEditSchedule={handleEditSchedule}
                    handleDeleteSchedule={handleDeleteSchedule}
                    subjectId={subjectId}
                />
            </div>
            <AlertDialog
                open={!!scheduleDeleteId}
                onOpenChange={(open) => !open && setScheduleDeleteId('')}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This schedule will be deleted permanently. Do you want to continue ?
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => setScheduleDeleteId('')}>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction onClick={deleteSchedule}>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default BatchDetailsPage;
