import { useFetchBatchDetails } from '@/queries/batch-query';
import SmallPieChart from '@/components/shared/small-pie-chart';
import { Edit } from 'lucide-react';
import React, { useState } from 'react';
import BatchConfig from './batch-config';

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../components/ui/dialog'; // Replace with your actual dialog library imports
import moment from 'moment';
export const BatchSubjects: React.FC<{
    batch: any;
    refetchBatch?: () => void;
    setSubjectId?: React.Dispatch<React.SetStateAction<any>>;
}> = ({ batch, refetchBatch, setSubjectId }) => {
    const {
        data: batchDetails,
        error,
        isLoading,
        refetch: refetchBatchDeatils,
    } = useFetchBatchDetails(batch.id || '');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    if (isLoading) return <>Loading...</>;
    if (error) return <>error... {error}</>;
    return (
        <div className="mt-2 overflow-auto h-[calc(100vh-300px)]">
            {' '}
            {/* Add overflow-auto here */}
            <div className=" relative flex justify-between items-center overflow-auto">
                <span className="text-base font-bold">Subjects:</span>

                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogTrigger asChild>
                        <div className=" top-4 right-0">
                            <Edit className="p-1 hover:text-primary hover:scale-100  transition-transform duration-200 top-4 right-0 mt-0  cursor-pointer " />
                        </div>
                    </DialogTrigger>
                    <DialogContent className="mx-auto p-3 max-w-[550px]  ">
                        <DialogHeader>
                            <DialogTitle>Update Batch</DialogTitle>
                            <DialogDescription>Update the batch details</DialogDescription>
                        </DialogHeader>
                        <div className="bg-muted/40">
                            <BatchConfig
                                batch={batch}
                                refetchBatchDeatils={refetchBatchDeatils}
                                onClose={() => {
                                    setIsDialogOpen(false);
                                    refetchBatch && refetchBatch(); // Refetch batch details after closing dialog
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>{' '}
            <div className=" space-y-3 ">
                {batch?.batch_subjects?.map((subject: any) => {
                    const progressData = batchDetails?.subjects?.find(
                        (data: any) => data.subject_id === subject.subject.id
                    );
                    // one subject multiple faculty---------
                    const facultyNames = batch?.faculties
                        ?.filter((faculty: any) =>
                            faculty.subject.some((sub: any) => sub.id === subject.subject.id)
                        )
                        ?.map((faculty: any) => (
                            <span key={faculty.faculty_code}>
                                <b>{faculty.faculty_code}</b> - {faculty.first_name}{' '}
                                {faculty.last_name}
                            </span>
                        ));

                    const percentage = Math.round(
                        (progressData?.completed_sessions / progressData?.total_session_of_year) *
                            100
                    );

                    const weeks = moment().diff(batchDetails.start_date, 'weeks');
                    const totalRequiredLectures = (weeks + 1) * progressData?.sessions_per_week;
                    const leads = progressData?.completed_sessions - totalRequiredLectures;

                    const goal = Math.round(
                        ((progressData?.completed_sessions - leads) /
                            progressData?.total_session_of_year) *
                            100
                    );

                    return (
                        <>
                            <div
                                key={subject.id}
                                className="flex items-start pb-2  space-x-4  cursor-pointer hover:bg-muted"
                                onMouseEnter={() =>
                                    setSubjectId && setSubjectId(subject.subject_id)
                                }
                                onMouseLeave={() => setSubjectId && setSubjectId('')}>
                                {progressData && (
                                    <div className="flex flex-col items-center w-1/8">
                                        <SmallPieChart
                                            percent={Number.isNaN(percentage) ? 0 : percentage}
                                            goal={goal}
                                            color={subject?.subject.subject_color_code} // Subject color code
                                            width={80} // Adjust width as needed
                                            height={80} // Adjust height as needed
                                        />
                                        <div className="flex items-center rounded-lg w-22">
                                            <div
                                                className="w-2 h-2"
                                                style={{
                                                    backgroundColor:
                                                        subject.subject.subject_color_code ||
                                                        'white',
                                                }}></div>
                                            <span
                                                className="px-2 font-semibold text-sm "
                                                style={{
                                                    width: '100px', // Fixed width for the subject name
                                                    whiteSpace: 'nowrap', // Prevent text wrapping
                                                    overflow: 'hidden', // Hide overflow text
                                                    textOverflow: 'ellipsis', // Show ellipsis when text overflows
                                                }}>
                                                {subject.subject.subject_name}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {/* Information Section */}
                                <div className="flex-1 ">
                                    <p className="text-sm mt-1">
                                        {facultyNames && facultyNames.length > 0
                                            ? facultyNames.map((name: any, idx: any) => (
                                                  <span className="block" key={idx}>
                                                      {name}
                                                  </span>
                                              ))
                                            : 'N/A'}
                                    </p>
                                    <div className="mt-1 text-sm">
                                        <b>{subject.session_per_weeks}</b> session's per week.
                                    </div>
                                    <div className="mt-1 text-sm">
                                        <b className="mr-2">
                                            {progressData?.completed_sessions} /&nbsp;
                                            {progressData?.total_session_of_year}
                                        </b>
                                        sessions completed.
                                    </div>
                                    <div className="mt-1 text-sm">
                                        {' '}
                                        {leads < 0 ? 'Lagging' : 'Leading'} by
                                        <b className="mr-2"> {Math.abs(leads)} sessions</b>
                                        {leads >= 0 && <>&#x1F60A;</>}
                                        {leads < 0 && <>&#x1F61E;</>}
                                    </div>
                                </div>
                            </div>
                            <hr className=" w-full bg-gray-200 h-[0.2vh]  " />
                        </>
                    );
                })}
            </div>
        </div>
    );
};
