// components/Loader.tsx
import { useLoadingProvider } from '@/providers/loading-provider';
import React from 'react';
import { SquareLoader } from 'react-spinners';
const Loader: React.FC = () => {
    const { isLoading } = useLoadingProvider();

    if (!isLoading) return null;
    return (
        <div className="fixed top-0 left-0 w-full h-full bg-muted opacity-75 flex justify-center items-center z-[100]">
            <SquareLoader cssOverride={{ backgroundColor: 'hsl(var(--primary))' }} />
        </div>
    );
};

export default Loader;
