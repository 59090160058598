import { PanelLeft, Search, User } from 'lucide-react';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ThemeToggle } from './theme-toggle';
import { useCommonSearch } from '../shared/common-search';
import { ChangeEvent, useMemo } from 'react';
import { debounce } from 'lodash';
import Breadcrumbs from '@/routes/breadcrumbs';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { useAuth } from '@/providers/auth-provider';

function Header() {
    const { setSearchQuery } = useCommonSearch();
    const { Logout } = useAuth();
    const handleLogout = () => {
        Logout();
    };

    const location = useLocation(); // Get the current location

    const DEBOUNCE_TIME_MS = 600;

    const handleSearchOnChange = useMemo(
        () =>
            debounce((e: ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value);
            }, DEBOUNCE_TIME_MS),
        [setSearchQuery]
    );
    const showSearchBar = ['/users', '/batches', '/faculties'].includes(location.pathname);

    return (
        <div className="flex flex-col sm:gap-4 sm:py-2 sm:pl-14 bg-background shadow-md mb-4">
            <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
                <Sheet>
                    <SheetTrigger asChild>
                        <Button size="icon" variant="outline" className="sm:hidden">
                            <PanelLeft className="h-5 w-5" />
                            <span className="sr-only">Toggle Menu</span>
                        </Button>
                    </SheetTrigger>
                    <SheetContent side="left" className="sm:max-w-xs">
                        mobile view menue here...
                    </SheetContent>
                </Sheet>
                {/* <Breadcrumb className="hidden md:flex">
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <a href="/">Dashboard</a>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <a href="/">Users</a>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>Add User</BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb> */}
                <Breadcrumbs />
                <div className="relative ml-auto flex-1 md:grow-0">
                    {showSearchBar && ( // Render the search bar conditionally
                        <div>
                            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                type="search"
                                placeholder="Search..."
                                onChange={handleSearchOnChange}
                                className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                            />
                        </div>
                    )}
                </div>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="outline"
                            size="icon"
                            className="overflow-hidden rounded-full">
                            <User className="overflow-hidden rounded-full"></User>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>My Account</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>Settings</DropdownMenuItem>
                        <DropdownMenuItem>Support</DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
                <ThemeToggle />
            </header>
        </div>
    );
}
export default Header;
