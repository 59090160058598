import { convertTimeTo12HourFormat, convertTimeTo24TimeFormat } from '@/common/functions';
import { Card, CardContent } from '@/components/ui/card';
import { useCommonProvider } from '@/providers/common-provider';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import { isValidDate, ScheduleDetails } from './schedule';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { Briefcase, Calendar, CalendarDays, Edit, Trash, X } from 'lucide-react';
import {
    AlertDialogHeader,
    AlertDialogFooter,
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { useToast } from '@/components/ui/use-toast';
import { useMutation } from '@tanstack/react-query';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useDeleteDoubtSession } from '@/queries/doubt-query';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import Color from 'color';
import { useGetLeaves } from '@/queries/leave-query';

interface Faculty {
    id: string;
    faculty_code: string;
    first_name?: string;
}

interface DateCardProps {
    date: { day: number; label: string; date: Date };
}

interface FacultyTimesCardProps {
    faculties: Faculty[];
    dates: DateCardProps['date'][];
    scheduleData: any;
    handleCardClick: (schedule: any) => void;
    refresh: () => void;
    selectedLocation: any; // Corrected syntax
}

interface Schedule {
    id: string;
    schedules: any[];
    type: string;
    isHoliday: boolean;
    subject: { subject_color_code: string };
}

const FacultyList: FC<{ faculty: Faculty }> = React.memo(({ faculty }) => {
    const { selectedDate } = useCommonProvider();
    const formattedDate = useMemo(
        () => moment(selectedDate?.from).format('YYYY-MM-DD'),
        [selectedDate]
    );

    return (
        <div className="w-36 h-full text-xs m-0.5 p-1.5 text-center flex flex-col justify-center hover:font-bold ">
            <a
                href={`/faculties/${faculty.id}?date=${formattedDate}`}
                target="_blank"
                rel="noreferrer">
                <span>
                    {faculty.faculty_code}- {faculty.first_name}
                </span>
            </a>
        </div>
    );
});

const DateCard: FC<DateCardProps> = React.memo(({ date }) => (
    <Card className="flex flex-col border-0 shadow-none mb-2">
        <CardContent className="border-0 w-48 h-auto p-1.5 bg-muted text-center flex-col items-center justify-center">
            <div className="flex h-12 justify-center flex-col border text-primary shadow">
                <span className="text-sm font-medium m-2">
                    {moment(date.date).format('Do MMM, dddd')}
                </span>
            </div>
        </CardContent>
    </Card>
));

export const DoubtSessionDetails: React.FC<any> = ({ item, onEdit, refresh }) => {
    item.formatted_date = moment(item.date).format('LL');
    const { selectedDate } = useCommonProvider();
    const { toast } = useToast();

    const handleEditClick = () => {
        const data = { ...item, slot_time: convertTimeTo24TimeFormat(item.time), type: 'doubt' }; // Create a shallow copy to avoid mutating the original object
        onEdit(data); // Pass the modified object to the onEdit function
    };

    const { mutate: deleteDoubtSession } = useMutation<any>({
        mutationFn: useDeleteDoubtSession,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Doubt session deleted successfully.',
            });
            refresh([{ doubt: true }]);
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Failed to delete',
                variant: 'destructive',
                duration: 5000,
                description: 'Please try again.',
            });
        },
    });

    async function handleDelete() {
        await deleteDoubtSession(item.id);
    }

    return (
        <div>
            <div className="absolute top-1 right-1 mt-1 mr-1 w-90 ">
                {selectedDate && isValidDate(selectedDate) && (
                    <div className="flex m-1 text-lg">
                        <Edit
                            onClick={handleEditClick}
                            className="p-1 w-8 h-8 text-primary cursor-pointer"
                        />
                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <Trash className="p-1 text-red-500 w-8 h-8 cursor-pointer" />
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        This doubt session will be deleted permanently. Do you want
                                        to continue?
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={handleDelete}>
                                        Continue
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>
                )}
            </div>

            <div className="items-center flex flex-col">
                <div className="-mt-14 mb-1 items-center">
                    <Avatar className="w-16 h-16 shadow-xl" style={{ border: '1px solid #ccc' }}>
                        <AvatarImage
                            src={item.faculty?.image || '/default-avatar.png'}
                            alt={item.faculty?.faculty_code}
                        />
                        <AvatarFallback>
                            <p className="text-sm">{item.faculty?.faculty_code}</p>
                        </AvatarFallback>
                    </Avatar>
                </div>

                <p className="text-sm">
                    {item.faculty?.first_name} {item.faculty?.last_name}
                </p>
                <span className="text-xs text-primary mt-1">{item.faculty?.mail}</span>
                <span className="mt-4">Doubt Session</span>
            </div>

            <hr className="m-3" />

            <div className="text-sm grid grid-cols-3 gap-2">
                <div className="shadow-md p-2 bg-muted/40">
                    <p className="font-semibold text-primary">Location</p>
                    <p className="mt-1">{item.location?.name}</p>
                </div>

                <div className="shadow-md p-2 bg-muted/40  col-span-2">
                    <p className="font-semibold text-primary">Date & Time</p>
                    <p className="mt-1">
                        {item.formatted_date} {item.time}
                    </p>
                </div>
            </div>
        </div>
    );
};
const EmptyScheduleContent: React.FC<any> = ({ item, onEdit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<'schedule' | 'doubt'>();
    const { selectedDate } = useCommonProvider();

    const handleSelectOption = (type: 'schedule' | 'doubt') => {
        const data = { ...item, type }; // Create a shallow copy to avoid mutating the original object
        delete data.time; // Delete the slot_time key
        onEdit(data); // Pass the modified object to the onEdit function
        setSelectedOption(type);
        setIsOpen(true);
    };
    return selectedDate && isValidDate(selectedDate) ? (
        <div className="flex flex-col">
            <div className="flex space-x-4 animate-fadeIn p-3">
                <Button
                    variant={'outline'}
                    onClick={() => handleSelectOption('schedule')}
                    className={`w-full text-base h-34 py-4 flex flex-col items-center justify-center space-y-2 transform transition-transform hover:scale-105 ${
                        selectedOption === 'schedule'
                            ? 'border-2 border-primary scale-105'
                            : 'border'
                    }`}>
                    <CalendarDays className="w-8 h-8" />
                    <span>Create Schedule</span>
                </Button>

                <Button
                    variant={'outline'}
                    onClick={() => handleSelectOption('doubt')}
                    className={`w-full text-base h-34 py-4 flex flex-col items-center justify-center space-y-2 transform transition-transform hover:scale-105 ${
                        selectedOption === 'doubt' ? 'border-2 border-primary scale-105' : 'border'
                    }`}>
                    <Calendar className="w-8 h-8" />
                    <span>Create Doubt Session</span>
                </Button>
            </div>
        </div>
    ) : (
        <h1 className="text-center">No schedule or Doubt Session available</h1>
    );
};
const SchedulePopover: FC<{
    schedule: Schedule;
    handleCardClick: (schedule: any) => void;
    refresh: () => void;
}> = ({ schedule, handleCardClick, refresh }) => {
    // Render content based on schedule type
    const renderScheduleContent = () => {
        switch (schedule.type) {
            case 'doubt_session':
                return (
                    <DoubtSessionDetails
                        item={schedule}
                        onEdit={handleCardClick}
                        refresh={refresh}
                    />
                );
            case 'schedule':
                return (
                    <ScheduleDetails item={schedule} onEdit={handleCardClick} refresh={refresh} />
                );
            default:
                return <EmptyScheduleContent item={schedule} onEdit={handleCardClick} />;
        }
    };
    const color =
        schedule.type === 'doubt_session' || !schedule?.id
            ? Color('#d5d5d5')
            : Color(schedule.subject?.subject_color_code);
    return (
        <Popover>
            <PopoverTrigger asChild>
                <span
                    className="w-5 h-5 border-2 cursor-pointer text-xs text-foreground/40 rounded-full"
                    style={{
                        backgroundColor:
                            schedule.type === 'doubt_session'
                                ? '#d5d5d5'
                                : schedule.subject?.subject_color_code,
                    }}>
                    {schedule?.isHoliday ? 'H' : ''}
                </span>
            </PopoverTrigger>

            <PopoverContent
                style={{
                    borderColor: color.hex(),
                    boxShadow: '0 4px 6px -1px ' + color.rgb() + ', 0 2px 4px -2px ' + color.rgb(),
                }}
                className="relative w-[28rem] z-50 bg-background border-2 p-4 flex flex-col">
                <PopoverClose className="mr-auto">
                    <X size={15} />
                </PopoverClose>
                {renderScheduleContent()}
            </PopoverContent>
        </Popover>
    );
};

const FacultyTimesCard: FC<FacultyTimesCardProps> = React.memo(
    ({
        faculties = [],
        dates = [],
        scheduleData = [],
        handleCardClick,
        refresh,
        selectedLocation = [], // Corrected syntax
    }) => {
        const locationString = selectedLocation || '';
        const { data: leaveData, refetch: refetchLeaveData } = useGetLeaves(locationString);
        if (!scheduleData) return null;

        const safeLeaveData = Array.isArray(leaveData) ? leaveData : [];

        const getMatchedLeaves = (facultyId: any, dateLabel: any) => {
            return safeLeaveData.filter(
                (leave) =>
                    leave.faculty_id === facultyId && leave.dates.split(',').includes(dateLabel)
            ); // This will return an array of all matching records
        };

        return (
            <div className="inline-flex p-2 pt-0 pl-0 relative">
                <div className="flex flex-col sticky top-0 left-0 z-20">
                    {/* Faculty Codes */}
                    <div className="flex sticky top-0 z-30">
                        <div className="sticky w-36 left-0 z-30 bg-muted h-auto m-0.5 mb-2">
                            <span className="w-full h-full flex text-center items-center justify-center" />
                        </div>
                        <div className="sticky top-0 z-10">
                            <div className="flex">
                                {dates.map((date) => (
                                    <DateCard key={`date_${date.day}_${date.label}`} date={date} />
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Faculty List & Schedules */}
                    <div className="sticky top-0 left-0 flex flex-col bg-muted space-y-1">
                        {faculties.map((faculty) => (
                            <div className="flex" key={`faculty_${faculty.id}`}>
                                <div className="sticky bg-background left-0 z-10 items-center justify-center">
                                    <FacultyList faculty={faculty} />
                                </div>
                                <div className="flex">
                                    <div className="flex">
                                        {scheduleData[faculty.id]?.schedules?.map((date: any) => {
                                            const matchedLeaves = getMatchedLeaves(
                                                faculty.id,
                                                date.date
                                            ); // Get all matched leaves
                                            const backgroundColor =
                                                matchedLeaves.length > 0 ? 'bg-foreground/40' : '';

                                            return (
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <Card
                                                            key={date.date}
                                                            className={`flex flex-col border-0 border-l-2 shadow-none ${backgroundColor}`}>
                                                            <CardContent className="border-0 w-48 h-auto p-1 text-center flex-col items-center justify-center">
                                                                <div className="grid grid-cols-6 gap-1 text-primary px-2">
                                                                    {date.schedules?.map(
                                                                        (schedule: Schedule) => (
                                                                            <SchedulePopover
                                                                                key={schedule.id}
                                                                                schedule={schedule}
                                                                                handleCardClick={
                                                                                    handleCardClick
                                                                                }
                                                                                refresh={refresh}
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </TooltipTrigger>

                                                    {matchedLeaves.length > 0 && (
                                                        <TooltipContent side="right">
                                                            <ul className="space-y-1">
                                                                {matchedLeaves.map((leave: any) => (
                                                                    <li key={leave.id}>
                                                                        {leave.batch_slot_name}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </TooltipContent>
                                                    )}
                                                </Tooltip>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
);

export default FacultyTimesCard;
