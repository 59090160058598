import React, { useEffect, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { string, z } from 'zod';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { DatePicker } from '@/components/ui/date-picker';
import { useCommonProvider } from '@/providers/common-provider';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { useMutation } from '@tanstack/react-query';
import { useSubmitSchedule } from '@/queries/schedule-query';
import moment from 'moment';
import CustomSelect from '@/components/shared/custom-select';
import { convertTimeTo12HourFormat } from '@/common/functions';
import { useSubmitDoubtSession } from '@/queries/doubt-query';
const DoubtSessionFormSchema = z.object({
    date: z.date().refine((val) => val instanceof Date, {
        message: 'Date is required',
    }),
    faculty_id: z.string().min(1, 'Faculty is required'),
    slot_time: z.string().min(1, 'Slot Time is required'),
});

type FormData = z.infer<typeof DoubtSessionFormSchema>;
interface DoubtSessionFormDefaultValues {
    date: Date;
    slot_time: string;
    faculty_id: string;
    id: string | undefined;
}

export const DoubtSessionForm = ({
    isOpen,
    onClose,
    setIsOpen,
    defaultValues,
    facultyList,
}: {
    isOpen: boolean;
    onClose: (data: any) => void;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    defaultValues: DoubtSessionFormDefaultValues;
    facultyList: any[];
}) => {
    const timeSlotList = [
        {
            start: '10:05',
            end: '12:00',
            originalTime: '10:05-12:00',
            time: convertTimeTo12HourFormat('10:05-12:00'),
        },
        {
            start: '12:20',
            end: '13:00',
            originalTime: '12:20-13:00',
            time: convertTimeTo12HourFormat('12:20-13:00'),
        },
        {
            start: '15:00',
            end: '15:35',
            originalTime: '15:00-15:35',
            time: convertTimeTo12HourFormat('15:00-15:35'),
        },
        {
            start: '16:00',
            end: '18:00',
            originalTime: '16:00-18:00',
            time: convertTimeTo12HourFormat('16:00-18:00'),
        },
    ];
    const { toast } = useToast();
    const { selectedLocation } = useCommonProvider();
    const formMethods = useForm<FormData>({
        resolver: zodResolver(DoubtSessionFormSchema),
        defaultValues: defaultValues || {
            date: new Date(),
            slot_time: '',
            faculty_id: '',
        },
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = formMethods;

    const handleSlotTimeChange = useCallback(
        (value: string) => {
            formMethods.setValue('slot_time', value);
        },
        [formMethods]
    );

    const handleFacultyChange = useCallback(
        (value: string) => {
            formMethods.setValue('faculty_id', value);
        },
        [facultyList, formMethods]
    );

    useEffect(() => {
        if (isOpen) {
            reset(defaultValues); // Reset the form with default values on modal open
            handleSlotTimeChange(defaultValues.slot_time);
            handleFacultyChange(defaultValues.faculty_id);
        }
    }, [isOpen, reset, defaultValues]);

    const { mutate } = useMutation<any>({
        mutationFn: useSubmitDoubtSession,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            onClose([{ doubt: true }]); // Close the dialog after submission
        },
        onError: (error: any) => {
            console.log(error);
            toast({
                title: error?.response?.data?.message || 'Invalid data',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });

    const onSubmit = async (value: any) => {
        try {
            value.location_id = selectedLocation;
            value.date = moment(value.date).format('YYYY-MM-DD');
            if (defaultValues.id) {
                // Update operation
                await mutate({
                    ...value,
                    id: defaultValues.id,
                });
            } else {
                // Create operation
                await mutate({ ...value });
            }
        } catch (error: any) {
            console.error('Error submitting form:', error.message);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-md p-4">
                <DialogHeader>
                    <DialogTitle> {defaultValues.id ? 'Edit' : 'Create'} Doubt Session</DialogTitle>
                    <DialogDescription>Fill out the form below:</DialogDescription>
                </DialogHeader>
                <FormProvider {...formMethods}>
                    <form className="space-y-2 p-3 bg-muted/40">
                        <div className="grid grid-cols-1 gap-4">
                            <FormField
                                control={control}
                                name="date"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="date">
                                            Date<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <DatePicker
                                                initialDate={field.value}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.date?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={control}
                                name="slot_time"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="slot_time" className="flex">
                                            Slot Time<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <CustomSelect
                                                options={[
                                                    ...(timeSlotList ?? []).map((item: any) => ({
                                                        value: item.originalTime,
                                                        label: item.time,
                                                    })),
                                                ]}
                                                onChange={(value: any) => {
                                                    if (value !== field.value) {
                                                        field.onChange(value);
                                                        handleSlotTimeChange(value);
                                                    }
                                                }}
                                                selectedValues={field.value}
                                                label="Slot Time"
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.slot_time?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={control}
                                name="faculty_id"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel htmlFor="faculty_id">
                                            Faculty<span className="text-red-500">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <CustomSelect
                                                options={[
                                                    ...(facultyList ?? []).map((item: any) => ({
                                                        value: item.id,
                                                        label: item.facultyLabel,
                                                        group: 'Other Faculties',
                                                    })),
                                                ]}
                                                onChange={(value: any) => {
                                                    if (value !== field.value) {
                                                        field.onChange(value);
                                                        handleFacultyChange(value);
                                                    }
                                                }}
                                                selectedValues={field.value}
                                                label="Faculty"
                                            />
                                        </FormControl>
                                        <FormMessage>{errors.faculty_id?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </form>
                </FormProvider>
                <DialogFooter>
                    <Button type="button" variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="button" onClick={handleSubmit(onSubmit)} variant="default">
                        Submit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
