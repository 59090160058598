import BatchHeatMap from '@/components/charts/BatchHeatMap';
import Heatmap from '@/components/charts/HeatMap';
import { Lollipop } from '@/components/charts/Lollipop';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import {
    useGetBatchProgress,
    useGetFacultyUtilization,
    useGetFacultyWeeklySchedule,
    useGetLocationsWithCounts,
} from '@/queries/dashboard-query';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

interface WeeklySchedule {
    faculty: string;
    value: number;
    first_name: string;
    last_name: string;
    color_code: string;
    subject_color_code: string;
    day: string;
    working_hours: number;
    subject_no: number;
    teaching_hours: number;
    leave: string[];
}

interface FacultyUtilization {
    faculty_code: string;
    utilization_percentage: number;
    full_name: string;
    color_code: string;
    subject_color_code: string;
}
const selectOptions = [
    { value: 'Thours', name: 'Teaching Hours' },
    { value: 'Whours', name: 'Working Hours' },
    { value: 'NLectures', name: 'Number Of Lectures' },
];

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
function DashboardPage() {
    const [selectedRange, setSelectedRange] = useState<any>('30');
    const [facultyUtilizationChartData, setFacultyUtilizationChartData] = useState<any[]>([]);
    const [weeklyScheduleChartData, setWeeklyScheduleChartData] = useState<any[]>([]);
    const [batchProgressData, setBatchProgressData] = useState<any[]>([]);
    const [batchProgressTitles, setBatchProgressTitles] = useState<string[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string | ''>('');
    const [weeklyType, setWeeklyType] = useState<any>('NLectures');
    const [groupBy, setGroupBy] = useState<boolean>(true);

    const { mutate: fetchFacultyUtilization, data: facultyUtilization } =
        useGetFacultyUtilization();

    const { mutate: fetchWeeklySchedules, data: weeklySchedules } = useGetFacultyWeeklySchedule();
    const { mutate: fetchBatchProgress, data: batchProgress } = useGetBatchProgress();
    useEffect(() => {
        fetchFacultyUtilization({ location_ids: selectedLocationId, to_day: selectedRange });
        fetchWeeklySchedules({ location_ids: selectedLocationId, weekType: weeklyType });
        fetchBatchProgress({ location_ids: selectedLocationId });
    }, [selectedLocationId]);
    useEffect(() => {
        setFacultyUtilizationChartData([]);
        fetchFacultyUtilization({ location_ids: selectedLocationId, to_day: selectedRange });
    }, [selectedRange]);
    useEffect(() => {
        const data = _.chain(weeklySchedules)
            .map((item: WeeklySchedule) => ({
                code: item.faculty.trim(),
                value:
                    weeklyType === 'NLectures'
                        ? item.value
                        : weeklyType === 'Whours'
                          ? item.working_hours
                          : item.teaching_hours,
                full_name: `${item.first_name} ${item.last_name}`,
                color_code: item.color_code,
                subject_no: item.subject_no,
                subject_color_code: item.subject_color_code,
                day: item.day,
                details: item,
            }))
            .orderBy(
                [
                    'subject_no', // Sort by subject first,
                    'value',
                ],
                ['asc', 'desc'] // 'asc' for subject-wise sorting, 'desc' for value sorting
            )
            .value();
        setWeeklyScheduleChartData(data);
    }, [weeklyType, weeklySchedules]);
    useEffect(() => {
        if (batchProgress) {
            const data = _.map(batchProgress, (item: any) => ({
                code: item.batch_code, // Trim to remove any extra spaces
                value: Math.abs(item.leads),
                full_name: item.batch_code + ' (' + item.location_name + ') ',
                day: item.subject_name,
                max: item.total_session_of_year,
                hideValue: item.hideValue,
                percentage: item.percentage,
                details: item,
            }));
            const uniqueBatches = _.uniqBy(data, 'day').map((batch) => batch.day);
            setBatchProgressData(data);
            console.log(data);
            setBatchProgressTitles(uniqueBatches);
        }
    }, [batchProgress, selectedLocationId]);

    useEffect(() => {
        if (!groupBy) {
            const data = _.orderBy(
                _.map(facultyUtilization, (item: FacultyUtilization) => ({
                    name: item.faculty_code.trim(), // Trim to remove any extra spaces
                    value: item.utilization_percentage,
                    full_name: item.full_name,
                    color_code: item.color_code,
                    subject_color_code: item.subject_color_code,
                    details: item,
                })),
                ['value'],
                ['desc']
            );
            setFacultyUtilizationChartData(data);
        } else {
            const data = _.map(facultyUtilization, (item: FacultyUtilization) => ({
                name: item.faculty_code, // Trim to remove any extra spaces
                value: item.utilization_percentage,
                full_name: item.full_name,
                color_code: item.color_code,
                subject_color_code: item.subject_color_code,
                details: item,
            }));
            setFacultyUtilizationChartData(data);
        }
    }, [facultyUtilization, selectedLocationId, groupBy]);

    const { data: locationList = [], refetch: refetchLocationList } = useGetLocationsWithCounts();

    const handleClick = (locationId: any) => {
        // Toggle selection
        setSelectedLocationId((prevId: any) => (prevId === locationId ? '' : locationId));
    };

    return (
        <div className="h-[88vh] overflow-auto">
            {/* <FacultyWeeklyClasses data={data}></FacultyWeeklyClasses> */}
            <div className="flex my-2 py-3 sticky top-0 z-50 space-x-4 overflow-auto scrollbar-hide bg-background shadow">
                {locationList?.map((item: any, index: number) => (
                    <Card
                        key={item.location_id}
                        className={`max-w-xs border-2 shadow-md cursor-pointer transition-all duration-300 ease-in-out transform ${
                            selectedLocationId === item.location_id
                                ? 'border-primary border-2 bg-primary mx-4 shadow-xl scale-110'
                                : selectedLocationId
                                  ? ' scale-90 '
                                  : ''
                        }`}
                        onClick={(e) => {
                            handleClick(item?.location_id);
                            // Scroll the selected card into view
                            e.currentTarget.scrollIntoView({
                                behavior: 'smooth', // Smooth scrolling
                                block: 'nearest', // Scrolls to the nearest edge
                                inline: 'center', // Centers the card horizontally
                            });
                        }}>
                        <CardContent
                            className={`flex p-3 bg-muted transition-colors duration-300 ${selectedLocationId === item.location_id ? 'bg-primary' : 'bg-muted'}`}>
                            <div className="flex flex-col justify-center items-center w-full h-full">
                                <h4
                                    className={`text-sm font-semibold leading-none text-center cursor-pointer transition-colors duration-300 ${selectedLocationId === item.location_id ? 'text-background' : ''}`}>
                                    {item?.location_name}
                                </h4>
                            </div>
                        </CardContent>
                        <CardFooter className="flex flex-row border-t p-2 bg-background">
                            <div className="flex w-full items-center gap-1">
                                <div className="grid flex-1 auto-rows-min gap-0.5 space-y-1">
                                    <div className="text-xs text-foreground leading-none">
                                        Lectures
                                    </div>
                                    <div className="flex items-baseline text-primary gap-1 text-base font-semibold tabular-nums leading-none justify-center">
                                        {item?.schedule_count}
                                    </div>
                                </div>
                                <Separator orientation="vertical" className="mx-1 h-10 w-px" />
                                <div className="grid flex-1 auto-rows-min gap-0.5 space-y-1">
                                    <div className="text-xs text-foreground leading-none">
                                        Batches
                                    </div>
                                    <div className="flex items-baseline gap-1 text-primary text-base font-semibold tabular-nums leading-none justify-center">
                                        {item?.batches_count}
                                    </div>
                                </div>
                                <Separator orientation="vertical" className="mx-1 h-10 w-px" />
                                <div className="grid flex-1 auto-rows-min gap-0.5 space-y-1">
                                    <div className="text-xs text-foreground leading-none">
                                        Faculties
                                    </div>
                                    <div className="flex items-baseline gap-1 text-primary text-base font-semibold tabular-nums leading-none justify-center">
                                        {item?.faculties_count}
                                    </div>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                ))}
            </div>

            <div className="grid lg:grid-cols-2 gap-2">
                <Card className="mt-2">
                    <div className="flex">
                        <CardHeader className="p-2">
                            <CardTitle className="flex p-0  text-lg">
                                Faculties Utilization
                            </CardTitle>
                            <CardDescription className="text-xs">
                                Showing faculties lectures utilization for the last {selectedRange}{' '}
                                days
                            </CardDescription>
                        </CardHeader>
                        <div className="ml-auto p-2 flex justify-center items-center">
                            <div className=" flex justify-center items-center">
                                <Switch
                                    checked={groupBy}
                                    onCheckedChange={() => setGroupBy(!groupBy)}
                                />
                                <label
                                    htmlFor="terms"
                                    className="text-xs ml-2 w-28 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                    Group By Subject
                                </label>
                            </div>
                            <Select
                                value={selectedRange}
                                onValueChange={(event) => setSelectedRange(event)}>
                                <SelectTrigger className="w-full ">
                                    <SelectValue placeholder={'placeholder'} />
                                </SelectTrigger>
                                <SelectContent className="mt-1 w-full">
                                    <SelectItem value="30">30 Days</SelectItem>
                                    <SelectItem value="60">60 Days</SelectItem>
                                    <SelectItem value="90">90 Days</SelectItem>
                                    <SelectItem
                                        value={moment()
                                            .diff(moment().startOf('year').month(3).date(1), 'days')
                                            .toString()}>
                                        {moment().year()} - Academic Year
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    <CardContent className="bg-background">
                        <Lollipop data={facultyUtilizationChartData} height={250} />
                    </CardContent>
                </Card>
                <Card className="mt-2">
                    <div className="flex">
                        <CardHeader className="p-2">
                            <CardTitle className="flex p-0  text-lg">Weekly Utilization</CardTitle>
                            <CardDescription className="text-xs">
                                Showing current week faculties utilization.
                            </CardDescription>
                        </CardHeader>
                        <div className="ml-auto p-2">
                            <Select
                                value={weeklyType}
                                onValueChange={(event) => setWeeklyType(event)}>
                                <SelectTrigger className="w-full ">
                                    <SelectValue placeholder={'placeholder'} />
                                </SelectTrigger>
                                <SelectContent className="mt-1 w-full">
                                    {selectOptions.map((option) => (
                                        <SelectItem key={option.value} value={option.value}>
                                            {option.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                    <CardContent className="bg-background">
                        <Heatmap height={250} data={weeklyScheduleChartData} rows={daysOfWeek} />
                    </CardContent>
                </Card>
                <Card className="mt-2 col-span-2">
                    <div className="flex">
                        <CardHeader className="p-2">
                            <CardTitle className="flex items-center p-0  text-lg">
                                Batch Progress
                                {/* <Tooltip>
                                    <TooltipTrigger asChild>
                                        <LucideInfo
                                            size="16"
                                            className="text-primary ml-4 cursor-pointer"
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent
                                        side="top"
                                        align="start"
                                        className="bg-muted p-4 rounded-md shadow-lg max-w-xs">
                                        <div className="text-sm text-gray-700 whitespace-nowrap">
                                            <p className="mt-0">
                                                Showing number of lectures lagging for batch{' '}
                                            </p>
                                        </div>
                                    </TooltipContent>
                                </Tooltip> */}
                            </CardTitle>
                            <CardDescription className="text-xs">
                                Showing number of lectures lagging for batch
                            </CardDescription>
                        </CardHeader>
                    </div>

                    <CardContent className="bg-background">
                        <BatchHeatMap
                            height={190}
                            data={batchProgressData}
                            rows={batchProgressTitles}
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default DashboardPage;
