import React from 'react';
import { useSearchParams } from 'react-router-dom'; // Import useSearchParams from react-router-dom
import {
    UserIcon,
    Briefcase,
    BookOpenText,
    EllipsisVertical,
    PhoneCall,
    GraduationCapIcon,
    MapPinIcon,
    Mail,
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Faculty } from '@/types';
import { Button } from '@/components/ui/button';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { Badge } from '@/components/ui/badge';
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

interface Props {
    columns: [];
    data: Faculty[];
    handleViewDetails: (facultyId: string) => void;
    setSelectedFacultyId: React.Dispatch<React.SetStateAction<string | null>>;
    pageCount: number;
    pageSizeOptions?: number[];
}

const FacultyCardComponent: React.FC<Props> = ({
    columns,
    data,
    handleViewDetails,
    setSelectedFacultyId,
    pageCount,
    pageSizeOptions = [10, 20, 30, 40, 50],
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sorting, setSorting] = React.useState<SortingState>([]);
    // Search params
    const page = searchParams?.get('page') ?? '1';
    const pageAsNumber = Number(page);
    const fallbackPage = isNaN(pageAsNumber) || pageAsNumber < 1 ? 1 : pageAsNumber;
    const per_page = searchParams?.get('limit') ?? 50;
    const perPageAsNumber = Number(per_page);
    const fallbackPerPage = isNaN(perPageAsNumber) ? 50 : perPageAsNumber;

    // Handle server-side pagination
    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: fallbackPage - 1,
        pageSize: fallbackPerPage,
    });
    React.useEffect(() => {
        const defaultSortOrder =
            sorting.length > 0
                ? sorting[0]?.desc
                    ? 'DESC'
                    : 'ASC'
                : searchParams.get('sortOrder') ?? 'ASC';

        // Update the URL with the new page number and limit
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            page: (pageIndex + 1).toString(), // Update the page number (assuming pageIndex is 0-based)
            limit: pageSize.toString(), // Update the limit
            sortBy: sorting[0]?.id ?? searchParams.get('sortBy') ?? 'updated_at',
            sortOrder: defaultSortOrder,
            // sortOrder: sorting[0]?.desc ? 'DESC' : 'ASC' ?? searchParams.get('sortOrder') ?? 'DESC',
        });
    }, [pageIndex, pageSize, sorting, searchParams, setSearchParams]);
    const handlePageChange = (newPage: number) => {
        if (newPage >= 0 && newPage < pageCount) {
            setSearchParams({
                page: (newPage + 1).toString(), // 1-based for URL
                limit: pageSize.toString(),
            });
        }
    };

    // Handle page size change
    const handlePageSizeChange = (size: number) => {
        setSearchParams({
            page: '1', // Reset to the first page when page size changes
            limit: size.toString(),
        });
    };
    const table = useReactTable({
        data,
        columns,
        pageCount: pageCount ?? -1,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            pagination: { pageIndex, pageSize },
            sorting,
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        manualPagination: true,
        manualFiltering: true,
    });

    return (
        <div>
            <ScrollArea className="h-[calc(100vh-14rem)] overflow-auto  bg-muted/40">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 m-2">
                    {data?.map((faculty) => (
                        <Card
                            key={faculty.id}
                            className="relative shadow-lg  rounded-lg overflow-hidden transition-transform transform hover:scale-105"
                            style={{
                                borderLeft: `8px solid ${faculty.subject?.[0]?.subject_color_code || '#f0f0f0'}`,
                                borderTop: `2px solid ${faculty.subject?.[0]?.subject_color_code || '#f0f0f0'}`,
                                borderRight: `2px solid ${faculty.subject?.[0]?.subject_color_code || '#f0f0f0'}`,
                                borderBottom: `2px solid ${faculty.subject?.[0]?.subject_color_code || '#f0f0f0'}`,
                            }}>
                            <div className="absolute top-2 right-2">
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent side="bottom" align="end">
                                        <DropdownMenuItem
                                            onClick={() => handleViewDetails(faculty?.id)}
                                            className="cursor-pointer">
                                            View Details
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() => setSelectedFacultyId(faculty?.id)}
                                            className="cursor-pointer">
                                            Delete Faculty
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                            <CardHeader className={`  bg-muted p-2 `}>
                                <CardTitle className="text-base font-bold">
                                    <div className="flex flex-col items-center">
                                        <div className="flex flex-col items-center">
                                            {/* <UserIcon size="16" className="text-primary mr-2" /> */}
                                            <div className="flex justify-center">
                                                <span
                                                    className="cursor-pointer hover:text-blue-600 transition-colors"
                                                    onClick={() => handleViewDetails(faculty?.id)}>
                                                    {faculty.faculty_code}
                                                </span>
                                            </div>
                                            <span className="cursor-pointer  text-gray-600 text-sm">
                                                {`${faculty.first_name} ${faculty.last_name}`}
                                            </span>
                                        </div>
                                        {/* <hr className="relative w-full bg-gray-300 mt-0  border-0 h-[1px]" /> */}
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <div className="flex items-center text-blue-600 mt-2 ml-6 mb-4">
                                <Mail size="16" className="text-primary mr-2" />
                                <p className="text-sm font-medium">{faculty.mail}</p>
                            </div>
                            <CardContent className="grid grid-cols-1 sm:grid-cols-2 gap-4   ">
                                <div className="flex items-center col-span-1">
                                    <PhoneCall size="16" className="text-primary mr-2" />
                                    <p className="text-sm font-medium">{faculty.phone}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex flex-wrap">
                                        {faculty.subject?.map((subject, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center mr-3 mb-1">
                                                {/* Subject color square */}
                                                <div
                                                    className="w-3 h-3 mr-2 rounded-sm"
                                                    style={{
                                                        backgroundColor:
                                                            subject.subject_color_code || '#f0f0f0',
                                                    }}></div>

                                                {/* Subject name */}
                                                <p className="text-sm font-medium">
                                                    {subject.subject_name}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* <div className="flex items-center mt-2">
                                    <MapPinIcon size="30" className="text-primary mr-2" />
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis w-48 hover:cursor-pointer">
                                                {faculty.location.map((loc, index) => (
                                                    <span key={loc.id}>
                                                        {loc.name}
                                                        {index < faculty.location.length - 1
                                                            ? ', '
                                                            : ''}
                                                    </span>
                                                ))}
                                            </p>
                                        </TooltipTrigger>
                                        <TooltipContent side="top">
                                            {faculty.location.map((loc) => loc.name).join(', ')}
                                        </TooltipContent>
                                    </Tooltip>
                                </div> */}
                                <div className="flex items-center mt-2">
                                    <MapPinIcon
                                        size={18}
                                        className="text-primary mr-1 flex-shrink-0"
                                    />
                                    {faculty.location.length > 1 ? ( // Check if there are multiple locations
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis  hover:cursor-pointer flex items-center">
                                                    <span>
                                                        {faculty.location[0]?.name}{' '}
                                                        {/* Show full name of the first location */}
                                                        {', '}
                                                        {faculty.location[1]?.name.charAt(
                                                            0
                                                        )}...{' '}
                                                        {/* Show only the first letter of the second location */}
                                                    </span>
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent side="top" className="ml-12">
                                                {faculty.location.map((loc) => loc.name).join(', ')}
                                            </TooltipContent>
                                        </Tooltip>
                                    ) : (
                                        <p className="text-sm font-medium whitespace-nowrap flex items-center">
                                            {faculty.location[0]?.name}{' '}
                                        </p>
                                    )}
                                </div>

                                {faculty?.experience ? (
                                    <div className="flex items-center space-x-1 mt-2">
                                        <Briefcase size="16" className="text-primary" />
                                        <span>{`${faculty.experience} Yrs Exp`}</span>
                                    </div>
                                ) : null}
                            </CardContent>
                        </Card>
                    ))}
                </div>
                <ScrollBar orientation="horizontal" />
            </ScrollArea>

            <div className="flex flex-col items-center justify-end gap-2 space-x-2 py-4 sm:flex-row">
                <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-6 lg:gap-8">
                        <div className="flex items-center space-x-2">
                            <p className="whitespace-nowrap text-sm font-medium">Rows per page</p>
                            <Select
                                value={`${table.getState().pagination.pageSize}`}
                                onValueChange={(value: string) => {
                                    table.setPageSize(Number(value));
                                }}>
                                <SelectTrigger className="h-8 w-[70px]">
                                    <SelectValue
                                        placeholder={table.getState().pagination.pageSize}
                                    />
                                </SelectTrigger>
                                <SelectContent side="top">
                                    {pageSizeOptions.map((pSize) => (
                                        <SelectItem key={pSize} value={`${pSize}`}>
                                            {pSize}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="flex w-full items-center justify-between gap-2 sm:justify-end">
                    <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                    </div>
                    <div className="flex items-center space-x-2">
                        <Button
                            aria-label="Go to first page"
                            variant="outline"
                            className="hidden h-8 w-8 p-0 lg:flex"
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}>
                            <DoubleArrowLeftIcon className="h-4 w-4" aria-hidden="true" />
                        </Button>
                        <Button
                            aria-label="Go to previous page"
                            variant="outline"
                            className="h-8 w-8 p-0"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}>
                            <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                        </Button>
                        <Button
                            aria-label="Go to next page"
                            variant="outline"
                            className="h-8 w-8 p-0"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}>
                            <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                        </Button>
                        <Button
                            aria-label="Go to last page"
                            variant="outline"
                            className="hidden h-8 w-8 p-0 lg:flex"
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}>
                            <DoubleArrowRightIcon className="h-4 w-4" aria-hidden="true" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FacultyCardComponent;
