import { Button } from '@/components/ui/button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/providers/auth-provider';
import { useRouter } from '@/routes/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Link, NavLink } from 'react-router-dom';

const formSchema = z.object({
    email: z
        .string()
        .nonempty({ message: 'This is required. *' })
        .email({ message: 'Enter a valid email address' }),
    password: z
        .string()
        .nonempty({ message: 'This is required. *' })
        .min(8, { message: 'Password must be at least 8 characters long' })
        .max(50, { message: 'Password must not exceed 50 characters' }),
});

type UserFormValue = z.infer<typeof formSchema>;

export default function LoginForm() {
    const [showNewPassword, setShowNewPassword] = useState(false); // For new password field

    const { Login } = useAuth();
    const { toast } = useToast();
    const navigate = useNavigate();
    const [loading] = useState(false);
    const defaultValues = {
        email: '',
        password: '',
    };
    const form = useForm<UserFormValue>({
        resolver: zodResolver(formSchema),
        defaultValues,
    });

    const onSubmit = async (data: UserFormValue) => {
        const response = await Login(data.email, data.password);
        if (response) {
            navigate('/');
        } else {
            toast({
                title: 'Invaild Credentials',
                variant: 'destructive',
                duration: 1000,
                description: 'Please Try Again..',
            });
        }
    };

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-2">
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input
                                        type="email"
                                        placeholder="Enter your email..."
                                        disabled={loading}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input
                                        type={showNewPassword ? 'text' : 'password'}
                                        placeholder="Enter your password..."
                                        disabled={loading}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                                <div className="flex items-center space-x-2 mt-2">
                                    <input
                                        type="checkbox"
                                        id="showNewPassword"
                                        checked={showNewPassword}
                                        onChange={() => setShowNewPassword(!showNewPassword)}
                                        className="h-4 w-4"
                                    />
                                    <label
                                        htmlFor="showNewPassword"
                                        className="text-sm text-muted-foreground">
                                        Show Password
                                    </label>
                                </div>
                            </FormItem>
                        )}
                    />
                    <div className="flex justify-end">
                        <Link to="/forgot">
                            {' '}
                            <p className="">Forgot Password?</p>
                        </Link>
                    </div>

                    <Button disabled={loading} className="ml-auto w-full" type="submit">
                        Continue
                    </Button>
                </form>
            </Form>
            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                </div>
            </div>
        </>
    );
}
