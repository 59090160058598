import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import React, { useState, useEffect } from 'react';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import { FormProvider, useForm } from 'react-hook-form';
import { Checkbox } from '@/components/ui/checkbox';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import CustomSelect from '@/components/shared/custom-select';
import moment from 'moment';
import { Batch, Faculty } from '@/types';
import { convertTimeTo12HourFormat } from '@/common/functions';
import Color from 'color';
import { useCommonProvider } from '@/providers/common-provider';
import { useToast } from '@/components/ui/use-toast';
import { useSubmitSchedule } from '@/queries/schedule-query';
import { useMutation } from '@tanstack/react-query';
import CreateSlotTime from './create-slot-time';

interface ScheduleFormDefaultValues {
    date: Date;
    batch_id: string;
    slot_time: string;
    faculty_id: string;
    subject_id: string;
    id: string | undefined;
}
type FormData = z.infer<typeof FormSchema>;

const FormSchema = z.object({
    date: z.date().refine((val) => val instanceof Date, {
        message: 'Date is required',
    }),
    batch_id: z.string().min(1, 'Batch is required'),
    faculty_id: z.string().min(1, 'Faculty is required'),
    subject_id: z.string().min(1, 'Subject is required'),
    slot_time: z.string().min(1, 'Slot Time is required'),
});

export const CreateSchedule = ({
    isOpen,
    onClose,
    setIsOpen,
    defaultValues,
    batchList,
    facultyList,
    timeSlotList,
    allSchedules,
}: {
    isOpen: boolean;
    onClose: (data: any) => void;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    defaultValues: ScheduleFormDefaultValues;
    batchList: any[];
    facultyList: any[];
    timeSlotList: any[];
    allSchedules: any[];
}) => {
    const form = useForm<FormData>({
        resolver: zodResolver(FormSchema),
        defaultValues: defaultValues || {
            date: new Date(),
            batch_id: '',
            slot_time: '',
            faculty_id: '',
            subject_id: '',
        },
    });
    const { handleSubmit, control, setValue, reset } = form;
    const { toast } = useToast();
    const { selectedLocation } = useCommonProvider();
    const [matchedBatch, setMatchedBatch] = useState<Batch | null>(null);
    const [newSlotTime, setNewSlotTime] = useState<any[]>([]);

    useEffect(() => {
        if (defaultValues?.batch_id && batchList?.length > 0) {
            const foundBatch = batchList.find((batch) => batch.id === defaultValues.batch_id);
            setMatchedBatch(foundBatch || null);
        }
    }, [defaultValues, batchList]);
    useEffect(() => {
        if (isOpen) {
            reset(defaultValues); // Reset the form with default values on modal open
        }
    }, [isOpen, reset, defaultValues]);

    const ifFacultyNotAvailable = (facultyId: any) => {
        // Check if the faculty is scheduled on the selected date and time
        return allSchedules.some((schedule) => {
            const isSameDate = moment(schedule.date).isSame(defaultValues.date, 'day');
            const isSameSlot = schedule.slot_time === defaultValues.slot_time;
            const isFacultyInSchedule = schedule.faculty_id === facultyId;
            const isDifferentSchedule = schedule.id !== defaultValues.id;

            // Check if faculty is on leave on the selected date
            const faculty = facultyList.find((f) => f.id === facultyId);
            const isFacultyOnLeave = checkFacultyOnLeave(faculty, defaultValues.date);
            return (
                isFacultyOnLeave ||
                (isSameDate && isSameSlot && isFacultyInSchedule && isDifferentSchedule)
            );
        });
    };
    const { mutate } = useMutation<any>({
        mutationFn: useSubmitSchedule,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            onClose([{ add: data.data }]); // Close the dialog after submission
        },
        onError: (error: any) => {
            console.log(error);
            toast({
                title: error?.response?.data?.message || 'Invalid data',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    const onSubmit = async (value: any) => {
        try {
            value.location_id = selectedLocation;
            value.date = moment(value.date).format('YYYY-MM-DD');
            const isFacultyInBatch = facultyList.some((faculty) => faculty.id === value.faculty_id);
            if (defaultValues.id) {
                // Update operation
                await mutate({
                    ...value,
                    id: defaultValues.id,
                    isBatchFaculty: isFacultyInBatch,
                });
            } else {
                // Create operation
                await mutate({ ...value, isBatchFaculty: isFacultyInBatch });
            }
        } catch (error: any) {
            console.error('Error submitting form:', error.message);
        }
    };
    const handleNewSlotTime = async (time: any) => {
        const { startTime, endTime } = time;

        // Parse the start and end times
        const startMoment = moment(startTime, 'HH:mm');
        const endMoment = moment(endTime, 'HH:mm');
        // Create the formatted output
        const newSlot = {
            originalTime: `${startMoment.format('HH:mm')}-${endMoment.format('HH:mm')}`,
            time: `${startMoment.format('h:mm A')} - ${endMoment.format('h:mm A')}`,
            startTime: startMoment.format('h:mm A'),
            endTime: endMoment.format('h:mm A'),
        };
        setNewSlotTime((prev: any) => {
            const updatedSlots = [...prev, ...timeSlotList];
            const isUnique = !updatedSlots.some(
                (slot: any) => slot.originalTime === newSlot.originalTime
            );
            if (isUnique) {
                return [...prev, newSlot];
            }
            return [...prev];
        });
        form.setValue('slot_time', newSlot.originalTime);
    };
    const checkFacultyOnLeave = (faculty: any, selectedDate: Date): boolean => {
        return faculty?.leave?.some((leave: any) => {
            const leaveDates = leave.dates.split(','); // Split the leave dates into an array
            return leaveDates.includes(moment(selectedDate).format('YYYY-MM-DD')); // Check if the selected date is in the leave dates
        });
    };

    return (
        <FormProvider {...form}>
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="max-w-2xl p-4">
                    <DialogHeader>
                        <DialogTitle>{defaultValues.id ? 'Edit' : 'Create'} Schedule</DialogTitle>
                        <DialogDescription>Fill out the form below:</DialogDescription>
                    </DialogHeader>
                    <div className=" p-2">
                        <div className="text-sm grid grid-cols-3 gap-2 mb-4">
                            <div className="shadow-md flex flex-col p-2 bg-muted/40 space-x-2">
                                <p className="font-semibold text-primary ml-2">Batch</p>
                                <p>{matchedBatch?.batch_code}</p>
                            </div>
                            <div className="shadow-md flex flex-col p-2 bg-muted/40 space-x-2">
                                <p className="font-semibold flex text-primary ml-2">Date</p>
                                <p>{moment(defaultValues?.date).format('LL')}</p>
                            </div>
                            <div className="shadow-md flex flex-col p-2 bg-muted/40 space-x-2 ">
                                <p className="font-semibold flex text-primary ml-2">
                                    Time
                                    <CreateSlotTime onSubmit={handleNewSlotTime} />
                                </p>
                                <p>
                                    {form.getValues()?.slot_time &&
                                        convertTimeTo12HourFormat(form.getValues()?.slot_time)}
                                </p>
                            </div>
                        </div>
                        <hr className="w-full  h-[0.2vh] my-4" />
                        <p className="font-semibold text-primary my-3">Faculty Selection</p>
                        <div className="p-4">
                            <div className="mb-4">
                                <FormLabel className="text-sm">Option 1: Batch Faculty</FormLabel>
                            </div>
                            <div className="grid grid-cols-2 mt-3 gap-4">
                                {matchedBatch?.faculties?.map((faculty: any) => (
                                    <div
                                        key={faculty.id}
                                        className={`py-1 shadow-md cursor-pointer rounded border flex border-l-8 ${ifFacultyNotAvailable(faculty.id) ? 'opacity-40' : ''} `}
                                        style={{
                                            pointerEvents: ifFacultyNotAvailable(faculty.id)
                                                ? 'none'
                                                : 'auto',
                                            borderColor:
                                                faculty?.subject[0]?.subject_color_code || '#ccc',
                                        }}>
                                        <FormField
                                            control={control}
                                            name="faculty_id"
                                            render={({ field }) => (
                                                <FormItem className="flex items-center space-y-0 w-full p-2">
                                                    <label
                                                        htmlFor={`checkbox-${faculty.id}`}
                                                        className="flex items-center space-x-3 cursor-pointer">
                                                        <FormControl>
                                                            <Checkbox
                                                                id={`checkbox-${faculty.id}`}
                                                                className="rounded-full"
                                                                checked={field.value === faculty.id}
                                                                onCheckedChange={() => {
                                                                    field.onChange(faculty.id);
                                                                    const foundFaculty =
                                                                        facultyList.find(
                                                                            (f) =>
                                                                                f.id === faculty.id
                                                                        );
                                                                    setValue(
                                                                        'subject_id',
                                                                        foundFaculty?.subject[0]?.id
                                                                    );
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <span className="text-sm">
                                                            {faculty.facultyLabel}
                                                        </span>
                                                    </label>
                                                    {checkFacultyOnLeave(
                                                        faculty,
                                                        defaultValues.date
                                                    ) && (
                                                        <span className="text-xs ml-auto text-destructive">
                                                            On Leave
                                                        </span>
                                                    )}
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="my-4">
                                <FormLabel className="text-sm">
                                    Option 2: Other Batch Faculty
                                </FormLabel>
                            </div>

                            <div className="mt-1">
                                <FormField
                                    control={control}
                                    name="faculty_id"
                                    render={({ field }) => (
                                        <FormItem>
                                            <CustomSelect
                                                options={[
                                                    ...(
                                                        facultyList?.filter(
                                                            (faculty) =>
                                                                !matchedBatch?.faculties?.some(
                                                                    (f) => f.id === faculty.id
                                                                )
                                                        ) ?? []
                                                    ).map((item: any) => ({
                                                        value: item.id,
                                                        label: item.facultyLabel,
                                                        disabled: ifFacultyNotAvailable(item.id),
                                                    })),
                                                ]}
                                                onChange={(value) => {
                                                    field.onChange(value); // Set the faculty field value
                                                    const foundFaculty = facultyList.find(
                                                        (f) => f.id === value
                                                    );
                                                    setValue(
                                                        'subject_id',
                                                        foundFaculty?.subject[0]?.id
                                                    );
                                                }}
                                                selectedValues={field.value}
                                                label="Other Faculty"
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <DialogFooter className="mt-3">
                        <Button type="button" variant="outline" onClick={() => setIsOpen(false)}>
                            Cancel
                        </Button>
                        <Button type="button" variant="default" onClick={handleSubmit(onSubmit)}>
                            Schedule
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </FormProvider>
    );
};
