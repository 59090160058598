interface RoundedProgressBarProps {
    totalSessions: number;
    completedSessions: number;
    sessionsLead: number;
}

const RoundedProgressBar: React.FC<RoundedProgressBarProps> = ({
    totalSessions,
    completedSessions,
    sessionsLead,
}) => {
    const percentage =
        ((completedSessions - (sessionsLead > 0 ? sessionsLead : 0)) / totalSessions) * 100;
    const percentage1 = ((completedSessions - 5 - sessionsLead) / totalSessions) * 100; // 5 is to adjust the width of icon element

    return (
        <div className="relative w-full">
            <div className="flex justify-end text-xs font-light text-slate-500">
                {completedSessions} / {totalSessions}
            </div>
            <div className="w-full bg-slate-300 h-2 mt-1 flex flex-column">
                <div className="h-full bg-slate-500" style={{ width: `${percentage}%` }}></div>
            </div>
            <div className="w-full bg-slate-300 h-2 -mt-2 flex flex-column">
                <div className="h-full flex justify-end" style={{ width: `${percentage1}%` }}></div>
                &#x25b2;
            </div>
            <div className="flex justify-end text-xs font-light pt-2 text-slate-500">
                <span className="px-0" style={{ color: sessionsLead < 0 ? 'red' : 'green' }}>
                    {sessionsLead > 0 ? 'Leading' : 'Lagging'} {Math.abs(sessionsLead)} sessions
                    {sessionsLead > 0 && <> &#x1F60A;</>}
                    {sessionsLead <= 0 && <> &#x1F61E;</>}
                </span>
            </div>
        </div>
    );
};

export default RoundedProgressBar;
