import { z } from 'zod';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { DatePickerWithRange } from '@/components/shared/date-range-picker';
import CustomSelect from '@/components/shared/custom-select';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { useEffect, useState } from 'react';
import { useDeleteLeave, useSubmitLeave } from '@/queries/leave-query';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { getDatesBetween } from '@/common/functions';
import { Calendar, Briefcase } from 'lucide-react'; // Importing icons
import { Input } from '@/components/ui/input';
import { useDeleteHoliday, useSubmitHoliday } from '@/queries/holiday-query';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useFetchBatchTypeData } from '@/queries/batch-query';
import { useFetchBatchSlotData } from '@/queries/faculty-query';
import _ from 'lodash';

// Zod validation schema
const LeaveFormSchema = z.object({
    dateRange: z
        .object({
            from: z.date({ required_error: 'Start date is required' }),
            to: z.date({ required_error: 'End date is required' }),
        })
        .refine(
            (data) => data.from && data.to, // Ensure both `from` and `to` are present
            {
                message: 'Date range is required',
            }
        ),
    batch_slot_name: z.array(z.string()).refine((value) => value.some((item) => item), {
        message: 'You have to select at least one Slot',
    }),
    faculty_id: z
        .union([z.string(), z.array(z.string())])
        .refine(
            (value) =>
                (typeof value === 'string' && value.length > 0) ||
                (Array.isArray(value) && value.length > 0),
            {
                message: 'At least one faculty is required',
            }
        ),
});

// Form default values interface
interface LeaveFormDefaultValues {
    dateRange: { from: Date; to: Date };
    id: string | undefined;
}

type FormData = z.infer<typeof LeaveFormSchema>;

const HolidayFormSchema = z.object({
    dateRange: z
        .object({
            from: z.date({ required_error: 'Start date is required' }),
            to: z.date({ required_error: 'End date is required' }),
        })
        .refine(
            (data) => data.from && data.to, // Ensure both `from` and `to` are present
            {
                message: 'Date range is required',
            }
        ),
    name: z.string().nonempty('Holiday name is required'),
    location_id: z.array(z.string()),
    batch_type_id: z.array(z.string()).refine((value) => value.some((item) => item), {
        message: 'You have to select at least one Batch Type',
    }),
    batch_slot_id: z.array(z.string()).refine((value) => value.some((item) => item), {
        message: 'You have to select at least one Slot',
    }),
    batch_id: z.array(z.string()),
});

interface HolidayFormDefaultValues {
    dateRange: { from: Date; to: Date };
    name: string;
    location_id: string[];
    batch_type_id: string[];
    batch_slot_id: string[];
    batch_id: string[];
    id: string | undefined;
}
type HolidayFormData = z.infer<typeof HolidayFormSchema>;

export const LeaveForm = ({
    isOpen,
    onClose,
    defaultFormValues,
    facultyList,
    batchList,
    locationList,
    editData,
}: {
    isOpen: boolean;
    onClose: () => void;
    defaultFormValues: LeaveFormDefaultValues | HolidayFormDefaultValues;
    facultyList: any | undefined;
    batchList: any | undefined;
    locationList: any | undefined;
    editData?: any;
}) => {
    const { toast } = useToast();
    const [selectedOption, setSelectedOption] = useState<'leave' | 'holiday' | null>(null);
    const [deleteId, setDeleteId] = useState<any>();
    const [batchTypes, setBatchTypes] = useState<any>();
    const [slotTypes, setSlotTypes] = useState<any>();
    const params = { page: 1, limit: 10, sortBy: 'updated_at', sortOrder: 'DESC', filter: '' };
    const { data: batchTypeData } = useFetchBatchTypeData(params);
    const { data: slotTypeData } = useFetchBatchSlotData(params);
    const leaveFormMethods = useForm<FormData>({
        resolver: zodResolver(LeaveFormSchema),
        defaultValues: {
            dateRange: { from: new Date(), to: new Date() },
            faculty_id: [],
        },
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = leaveFormMethods;
    const holidayFormMethods = useForm<HolidayFormData>({
        resolver: zodResolver(HolidayFormSchema),
        defaultValues: defaultFormValues || {
            dateRange: { from: new Date(), to: new Date() },
            name: '',
            location_id: [],
            batch_type_id: batchTypes?.map((type: any) => type.id),
            batch_slot_id: slotTypes?.map((type: any) => type.id),
            batch_id: [],
        },
    });
    const {
        handleSubmit: handleHolidaySubmit,
        control: holidayControl,
        formState: { errors: holidayErrors },
        setValue: setHolidayValue,
        reset: resetHolidayForm,
    } = holidayFormMethods;
    useEffect(() => {
        const type = batchTypeData?.map((batchType: any) => ({
            id: batchType.id,
            name: batchType.name,
        }));
        setBatchTypes(type);
    }, [batchTypeData]);
    useEffect(() => {
        const type = slotTypeData?.map((slotType: any) => ({
            id: slotType.id,
            name: slotType.name,
        }));
        if (selectedOption === 'leave') {
            const updatedType = [{ id: 'FULLDAY', name: 'Full Day' }, ...type];
            setSlotTypes(updatedType);
        } else {
            const updatedType = [...type];
            setSlotTypes(updatedType);
        }
    }, [slotTypeData, selectedOption]);
    useEffect(() => {
        console.log(editData);
        if (isOpen) {
            if (!editData) {
                reset({
                    batch_slot_name: ['FULLDAY'],
                    ...defaultFormValues,
                });
                resetHolidayForm({
                    batch_type_id: batchTypes?.map((type: any) => type.id),
                    batch_slot_id: slotTypes?.map((type: any) => type.id),
                    location_id: [],
                    batch_id: [],
                    ...defaultFormValues,
                });
                setSelectedOption(null);
            } else {
                if (editData.type === 'Leave') {
                    const dates = editData.dateArray.split(',');
                    reset({
                        dateRange: {
                            from: new Date(dates[0] + ' 00:00:00'),
                            to: new Date(dates[dates.length - 1] + ' 00:00:00'),
                        },
                        faculty_id: editData.faculty_id,
                        batch_slot_name: editData.batch_slot
                            ? [editData.batch_slot['id']]
                            : ['FULLDAY'],
                    });
                } else {
                    resetHolidayForm({
                        batch_type_id:
                            editData.batch_types?.length > 0
                                ? editData.batch_types?.map((type: any) => type.id)
                                : batchTypes?.map((type: any) => type.id),
                        batch_slot_id:
                            editData.batch_slots?.length > 0
                                ? editData.batch_slots?.map((type: any) => type.id)
                                : slotTypes?.map((type: any) => type.id),
                        location_id:
                            editData.locations?.length > 0
                                ? editData.locations?.map((type: any) => type.id)
                                : [],
                        batch_id:
                            editData.batches?.length > 0
                                ? editData.batches?.map((type: any) => type.id)
                                : [],
                        name: editData.name,
                        dateRange: {
                            from: new Date(editData.dates[0] + ' 00:00:00'),
                            to: new Date(editData.dates[editData.dates.length - 1] + ' 00:00:00'),
                        },
                    });
                }
                setSelectedOption(editData.type === 'Leave' ? 'leave' : 'holiday');
            }
        }
    }, [isOpen, editData]);

    const { mutate } = useMutation<any>({
        mutationFn: useSubmitLeave,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            onClose(); // Close the dialog after submission
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Invalid data',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    const { mutate: holidaySubmit } = useMutation<any>({
        mutationFn: useSubmitHoliday,
        onSuccess: (data) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            onClose(); // Close the dialog after submission
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Invalid data',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    const handleSelectOption = (option: 'leave' | 'holiday') => {
        setSelectedOption(option);
        // You can trigger further actions like opening forms based on selected option
    };
    const onSubmit = async (value: any) => {
        try {
            console.log(
                value.dateRange.from,
                value.dateRange.to,
                getDatesBetween(value.dateRange.from, value.dateRange.to)
            );
            value.dates = getDatesBetween(value.dateRange.from, value.dateRange.to);
            if (value.batch_slot_name[0] === 'FULLDAY') {
                value.batch_slot_name = _.find(slotTypes, { id: value.batch_slot_name[0] })['id'];
            } else {
                value.batch_slot_name = _.find(slotTypes, { id: value.batch_slot_name[0] })['name'];
            }

            if (editData && editData.id) {
                await mutate({
                    ...value,
                    id: editData.id,
                });
            } else {
                value.batch_slot_name = [value.batch_slot_name];
                await mutate(value);
            }
        } catch (error: any) {
            console.error('Error submitting form:', error.message);
        }
    };
    const onHolidaySubmit = async (data: any) => {
        console.log('Submitted holiday form data:', data);
        if (data.batch_slot_id.length === slotTypes.length) {
            data.batch_slot_id = [];
        }
        if (data.batch_type_id.length === batchTypes.length) {
            data.batch_type_id = [];
        }
        if (editData && editData.id) {
            // Update operation
            await holidaySubmit({
                ...data,
                starting_date: moment(data.dateRange.from).format('YYYY-MM-DD'),
                ending_date: moment(data.dateRange.to).format('YYYY-MM-DD'),
                id: editData.id,
            });
        } else {
            // Create operation
            await holidaySubmit({
                ...data,
                starting_date: moment(data.dateRange.from).format('YYYY-MM-DD'),
                ending_date: moment(data.dateRange.to).format('YYYY-MM-DD'),
            });
        }
        onClose();
    };
    const handleTypeClick = (itemId: string, field: any, hasMultiselect = true) => {
        const isSelected = field.value?.includes(itemId);
        let updatedValue;
        if (hasMultiselect) {
            // Multi-select: add or remove items from the array
            updatedValue = isSelected
                ? field?.value?.filter((value: any) => value !== itemId) // Remove item if selected
                : [...(field.value || []), itemId]; // Add item if not selected
        } else {
            // Single-select: either select the item or deselect it (if it's already selected)
            updatedValue = isSelected ? [] : [itemId]; // If selected, clear; otherwise, select only this item
        }
        field.onChange(updatedValue); // Pass the updated value to onChange
    };
    const { mutate: deleteLeave } = useMutation<any>({
        mutationFn: useDeleteLeave,
        onSuccess: (data: any) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            onClose();
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Can`t Delete',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    const { mutate: deleteHoliday } = useMutation<any>({
        mutationFn: useDeleteHoliday,
        onSuccess: (data: any) => {
            toast({
                title: data.message,
                duration: 2000,
                variant: 'default',
                description: 'Thank You...',
            });
            onClose();
        },
        onError: (error: any) => {
            toast({
                title: error?.response?.data?.message || 'Can`t Delete',
                variant: 'destructive',
                duration: 5000,
                description: 'Please Try Again..',
            });
        },
    });
    async function handleDelete() {
        console.log(deleteId, editData.type);
        if (deleteId && editData.type === 'Leave') deleteLeave(deleteId || '');
        if (deleteId && editData.type === 'Holiday') deleteHoliday(deleteId || '');
    }
    return (
        <>
            <Dialog open={isOpen} onOpenChange={onClose}>
                {!selectedOption && (
                    <DialogContent className="max-w-lg  h-100">
                        <div>
                            <h2 className="text-xl font-bold mb-4">What Would You Like to Do?</h2>
                            <div className="flex space-x-4 animate-fadeIn p-3">
                                <Button
                                    variant={'outline'}
                                    onClick={() => handleSelectOption('leave')}
                                    className={`w-full text-base h-34 py-4 flex flex-col items-center justify-center space-y-2 transform transition-transform hover:scale-105 ${
                                        selectedOption === 'leave'
                                            ? 'border-4 border-primary scale-105'
                                            : 'border'
                                    }`}>
                                    <Briefcase className="w-8 h-8" />
                                    <span>Create Leave</span>
                                </Button>

                                <Button
                                    variant={'outline'}
                                    onClick={() => handleSelectOption('holiday')}
                                    className={`w-full text-base h-34 py-4 flex flex-col items-center justify-center space-y-2 transform transition-transform hover:scale-105 ${
                                        selectedOption === 'holiday'
                                            ? 'border-4 border-primary scale-105'
                                            : 'border'
                                    }`}>
                                    <Calendar className="w-8 h-8" />
                                    <span>Create Holiday</span>
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                )}

                {selectedOption && selectedOption === 'leave' && (
                    <DialogContent className="max-w-lg -mt-8">
                        <DialogHeader>
                            <DialogTitle>{editData?.id ? 'Update' : 'Create'} Leave</DialogTitle>
                            <DialogDescription>Fill out the form below:</DialogDescription>
                        </DialogHeader>
                        <FormProvider {...leaveFormMethods}>
                            <form className="space-y-4">
                                <FormField
                                    control={control}
                                    name="dateRange"
                                    render={({ field: { value, onChange } }) => (
                                        <FormItem>
                                            <FormLabel htmlFor="dateRange">
                                                Date<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <FormControl>
                                                <DatePickerWithRange
                                                    width={'w-full'}
                                                    initialDateRange={value}
                                                    onSubmit={onChange}
                                                    numberOfMonths={1}
                                                    minDate={new Date()}
                                                />
                                            </FormControl>
                                            <FormMessage>{errors.dateRange?.message}</FormMessage>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={control}
                                    name="faculty_id"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel htmlFor="faculty_id">
                                                Faculty<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <FormControl>
                                                <CustomSelect
                                                    options={facultyList}
                                                    onChange={(value: any) => {
                                                        if (value !== field.value) {
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    multiple={editData?.id ? false : true}
                                                    selectedValues={field.value}
                                                    label="Faculty"
                                                />
                                            </FormControl>
                                            <FormMessage>{errors.faculty_id?.message}</FormMessage>
                                        </FormItem>
                                    )}
                                />
                                {/* <FormField
                                    control={control}
                                    name="batch_slot_name"
                                    render={({ field }) => (
                                        <FormItem className="space-y-3">
                                            <FormLabel>
                                                Leave Type<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <FormControl>
                                                <RadioGroup
                                                    onValueChange={field.onChange}
                                                    defaultValue={field.value}
                                                    className="flex space-x-6">
                                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                                        <FormControl>
                                                            <RadioGroupItem value="FULLDAY" />
                                                        </FormControl>
                                                        <span className="font-normal">
                                                            Full Day
                                                        </span>
                                                    </FormItem>
                                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                                        <FormControl>
                                                            <RadioGroupItem value="morning" />
                                                        </FormControl>
                                                        <span className="font-normal">Morning</span>
                                                    </FormItem>
                                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                                        <FormControl>
                                                            <RadioGroupItem value="afternoon" />
                                                        </FormControl>
                                                        <span className="font-normal">
                                                            Afternoon
                                                        </span>
                                                    </FormItem>
                                                </RadioGroup>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                /> */}
                                <FormField
                                    control={control}
                                    name="batch_slot_name"
                                    render={({ field }) => (
                                        <FormItem className="space-y-3">
                                            <FormLabel>
                                                Leave Type<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <div className="flex flex-wrap gap-4">
                                                {slotTypes?.map((item: any) => {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            className={`flex flex-col items-center justify-center w-32 h-12 p-4 border rounded-lg cursor-pointer transition-colors ${
                                                                field.value?.includes(item.id)
                                                                    ? 'border-primary bg-muted'
                                                                    : 'border-gray-300 bg-white hover:border-primary'
                                                            }`}
                                                            onClick={() =>
                                                                handleTypeClick(
                                                                    item.id,
                                                                    field,
                                                                    false
                                                                )
                                                            }>
                                                            <FormLabel className="text-center font-normal">
                                                                {item.name}
                                                            </FormLabel>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <FormMessage>
                                                {errors.batch_slot_name?.message}
                                            </FormMessage>
                                        </FormItem>
                                    )}
                                />
                            </form>
                        </FormProvider>
                        <DialogFooter className="mt-3">
                            {editData && editData.id && (
                                <Button
                                    className="mr-auto"
                                    type="button"
                                    variant="destructive"
                                    onClick={() => setDeleteId(editData.id)}>
                                    Delete Leave
                                </Button>
                            )}
                            <Button type="button" variant="outline" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="default">
                                Submit
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                )}

                {selectedOption && selectedOption === 'holiday' && (
                    <DialogContent className="max-w-lg -mt-4">
                        <DialogHeader>
                            <DialogTitle>{editData?.id ? 'Update' : 'Create'} Holiday</DialogTitle>
                            <DialogDescription>Fill out the form below:</DialogDescription>
                        </DialogHeader>
                        <FormProvider {...holidayFormMethods}>
                            <form className="space-y-4">
                                <FormField
                                    control={holidayControl}
                                    name="dateRange"
                                    render={({ field: { value, onChange } }) => (
                                        <FormItem>
                                            <FormLabel htmlFor="dateRange">
                                                Date<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <FormControl>
                                                <DatePickerWithRange
                                                    width={'w-full'}
                                                    initialDateRange={value}
                                                    onSubmit={onChange}
                                                    numberOfMonths={1}
                                                    minDate={new Date()}
                                                />
                                            </FormControl>
                                            <FormMessage>
                                                {holidayErrors.dateRange?.message}
                                            </FormMessage>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={holidayControl}
                                    name="name"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel htmlFor="name">
                                                Holiday Name<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Enter Holiday Name"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage>{holidayErrors.name?.message}</FormMessage>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={holidayControl}
                                    name="location_id"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel htmlFor="batch_id">
                                                Location<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <FormControl>
                                                <CustomSelect
                                                    options={locationList?.map(
                                                        (item: any, index: any) => ({
                                                            value: item.id,
                                                            label: item?.name,
                                                        })
                                                    )}
                                                    onChange={(value: any) => {
                                                        if (value !== field.value) {
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    multiple={true}
                                                    selectedValues={field.value}
                                                    label="Location"
                                                    placeHolder="All Location"
                                                />
                                            </FormControl>
                                            <FormMessage>{errors.faculty_id?.message}</FormMessage>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={holidayControl}
                                    name="batch_type_id"
                                    render={({ field }) => (
                                        <FormItem className="space-y-3">
                                            <FormLabel>
                                                Batch Type<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <div className="flex flex-wrap gap-4">
                                                {batchTypes?.map((item: any) => {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            className={`flex flex-col items-center justify-center w-32 h-12 p-4 border rounded-lg cursor-pointer transition-colors ${
                                                                field.value?.includes(item.id)
                                                                    ? 'border-primary bg-muted'
                                                                    : 'border-gray-300 bg-white hover:border-primary'
                                                            }`}
                                                            onClick={() =>
                                                                handleTypeClick(item.id, field)
                                                            }>
                                                            <FormLabel className="text-center font-normal">
                                                                {item.name}
                                                            </FormLabel>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <FormMessage>
                                                {holidayErrors.batch_type_id?.message}
                                            </FormMessage>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={holidayControl}
                                    name="batch_slot_id"
                                    render={({ field }) => (
                                        <FormItem className="space-y-3">
                                            <FormLabel>
                                                Slot Type<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <div className="flex flex-wrap gap-4">
                                                {slotTypes?.map((item: any) => {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            className={`flex flex-col items-center justify-center w-32 h-12 p-4 border rounded-lg cursor-pointer transition-colors ${
                                                                field.value?.includes(item.id)
                                                                    ? 'border-primary bg-muted'
                                                                    : 'border-gray-300 bg-white hover:border-primary'
                                                            }`}
                                                            onClick={() =>
                                                                handleTypeClick(item.id, field)
                                                            }>
                                                            <FormLabel className="text-center font-normal">
                                                                {item.name}
                                                            </FormLabel>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <FormMessage>
                                                {holidayErrors.batch_slot_id?.message}
                                            </FormMessage>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={holidayControl}
                                    name="batch_id"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel htmlFor="batch_id">
                                                Batch<span className="text-red-500">*</span>
                                            </FormLabel>
                                            <FormControl>
                                                <CustomSelect
                                                    options={batchList}
                                                    onChange={(value: any) => {
                                                        if (value !== field.value) {
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    multiple={true}
                                                    selectedValues={field.value}
                                                    label="Batch"
                                                    placeHolder="All Batches"
                                                />
                                            </FormControl>
                                            <FormMessage>{errors.faculty_id?.message}</FormMessage>
                                        </FormItem>
                                    )}
                                />
                            </form>
                        </FormProvider>
                        <DialogFooter className="mt-3">
                            {editData && editData.id && (
                                <Button
                                    className="mr-auto"
                                    type="button"
                                    variant="destructive"
                                    onClick={() => setDeleteId(editData.id)}>
                                    Delete Holiday
                                </Button>
                            )}
                            <Button type="button" variant="outline" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                onClick={handleHolidaySubmit(onHolidaySubmit)}
                                variant="default">
                                Submit
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                )}
            </Dialog>
            <AlertDialog open={deleteId && deleteId !== ''} onOpenChange={() => setDeleteId('')}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            Delete {editData && editData.type === 'Leave' ? 'Leave' : 'Holiday'}
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            Deleting records. Do you want to continue ?
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleDelete}>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};
