import React from 'react';

interface SmallPieChartProps {
    percent: number; // Percentage value for the progress (0 to 100)
    goal?: number;
    color: string;
    width?: number; // Optional width prop
    height?: number; // Optional height prop
}

const SmallPieChart: React.FC<SmallPieChartProps> = ({
    percent,
    goal = 0,
    color,
    width = 60,
    height = 60,
}) => {
    // const radius = 28; // Radius of the pie chart
    const radius = Math.min(width, height) / 2 - 4; // Adjust radius based on size

    const strokeWidth = 4; // Width of the stroke
    const normalizedRadius = radius - strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const percentValue = Math.min(percent, 100); // Ensure percent is capped at 100
    const strokeDashoffset = circumference - (percentValue / 100) * circumference;
    const strokeDashoffset2 = circumference + 2 - (goal / 100) * circumference;

    return (
        <div className="flex justify-center items-center">
            <svg height={height} width={width}>
                <circle
                    stroke="#ddd"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={width / 2}
                    cy={height / 2}
                />
                <circle
                    stroke={color}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${circumference} ${circumference}`}
                    style={{
                        strokeDashoffset,
                        transform: 'rotate(-90deg)',
                        transformOrigin: '50% 50%',
                    }}
                    r={normalizedRadius}
                    cx={width / 2}
                    cy={height / 2}
                />
                {goal && (
                    <circle
                        stroke={goal <= percent ? 'green' : 'red'}
                        fill="transparent"
                        strokeWidth={strokeWidth + 2}
                        strokeDasharray={`${5} ${circumference - 5}`}
                        style={{
                            strokeDashoffset: strokeDashoffset2,
                            transform: 'rotate(-90deg)',
                            transformOrigin: '50% 50%',
                        }}
                        r={normalizedRadius}
                        cx={width / 2}
                        cy={height / 2}
                    />
                )}
                <text
                    x="50%"
                    y="50%"
                    dy=".3em"
                    textAnchor="middle"
                    className="text-xs  font-medium fill-current ">
                    {percent}%
                </text>
            </svg>
        </div>
    );
};

export default SmallPieChart;
