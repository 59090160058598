import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import logo from '../../webassic.png'; // Adjust the path as needed
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { useCreatePassword } from '@/queries/create-password';

const formSchema = z
    .object({
        Newpassword: z
            .string()
            .nonempty({ message: 'This is required. *' })
            .min(8, { message: 'Password must be at least 8 characters.' })
            .regex(/[A-Z]/, { message: 'Password must contain at least one uppercase letter.' })
            .regex(/[a-z]/, { message: 'Password must contain at least one lowercase letter.' })
            .regex(/[0-9]/, { message: 'Password must contain at least one number.' })
            .regex(/[@$!%*?&]/, {
                message:
                    'Password must contain at least one special character (@, $, !, %, *, ?, &).',
            }),
        confirmPassword: z
            .string()
            .nonempty({ message: 'This is required. *' })
            .min(8, { message: 'Confirm password must be at least 8 characters' }),
    })
    .refine((data) => data.Newpassword === data.confirmPassword, {
        message: 'Passwords do not match',
        path: ['confirmPassword'], // Error will be shown under confirmPassword
    });

type UserFormValue = z.infer<typeof formSchema>;

export default function CreatPassword() {
    const location = useLocation();

    const token = new URLSearchParams(location.search).get('token'); // Extract token from URL
    const {
        mutate: createPassword,
        isSuccess: createIsSuccess,
        isError: createIsError,
        error,
    } = useCreatePassword(token || '');
    const { toast } = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const form = useForm<UserFormValue>({
        resolver: zodResolver(formSchema),
        defaultValues: { Newpassword: '', confirmPassword: '' },
    });
    const [showNewPassword, setShowNewPassword] = useState(false); // For new password field
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onSubmit = async (data: UserFormValue) => {
        if (!token) {
            toast({
                title: 'ERROR:',
                description: 'Token is missing. Please try again.',
            });
            return;
        }
        if (data.Newpassword !== data.confirmPassword) {
            toast({
                title: 'ERROR:',
                description: 'Passwords do not match. Please try again.',
            });
            return;
        }
        const { confirmPassword } = data; // Get the new password and confirm password
        setLoading(true);

        try {
            await createPassword(confirmPassword);
        } catch (err) {
            console.log('error', err);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    useEffect(() => {
        if (createIsSuccess) {
            toast({
                title: 'Password Created Successfully',
                description: 'The password was created successfully.',
                variant: 'default', // Success variant
            });
            navigate('/login'); // Redirect after success
        } else if (createIsError) {
            const typedError = error as any; // or as unknown
            console.log('typedError--', typedError);
            let errorMsg = 'There was an error created the password.';
            if (typedError?.response) {
                errorMsg = typedError?.response?.data?.error;
            } else {
                errorMsg = error?.message || errorMsg;
            }
            toast({
                title: 'ERROR:',
                variant: 'destructive',
                description: errorMsg,
            });
        }
    }, [createIsSuccess, error]);
    return (
        <div className="relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
            <Link
                to="/"
                className={cn(
                    buttonVariants({ variant: 'ghost' }),
                    'absolute right-4 top-4 hidden md:right-8 md:top-8'
                )}>
                Login
            </Link>

            <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
                <div className="absolute inset-0 bg-primary dark:bg-secondary" />
                <div className="relative z-20 flex items-center text-lg font-medium">
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-16 transition-all group-hover:scale-110"
                    />
                </div>
                <div className="relative z-20 mt-auto">
                    <blockquote className="space-y-2">
                        <p className="text-lg">
                            &ldquo;Simplify lecture scheduling with our dedicated platform. Manage
                            schedules effortlessly, coordinate sessions seamlessly, and optimize
                            your educational institution's timetable with ease.&rdquo;
                        </p>
                    </blockquote>
                </div>
            </div>

            <div className="flex h-full items-center p-4 lg:p-8">
                <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                    <div className="flex flex-col space-y-2 text-center">
                        <h1 className="text-2xl font-semibold tracking-tight">Create Password</h1>
                        <p className="text-sm text-muted-foreground">
                            Create your password to complete setup
                        </p>
                    </div>

                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-2">
                            <FormField
                                control={form.control}
                                name="Newpassword"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>New Password</FormLabel>
                                        <FormControl>
                                            <Input
                                                type={showNewPassword ? 'text' : 'password'}
                                                placeholder="Enter your new password..."
                                                disabled={loading}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                        <div className="flex items-center space-x-2 mt-2">
                                            <input
                                                type="checkbox"
                                                id="showNewPassword"
                                                checked={showNewPassword}
                                                onChange={() =>
                                                    setShowNewPassword(!showNewPassword)
                                                }
                                                className="h-4 w-4"
                                            />
                                            <label
                                                htmlFor="showNewPassword"
                                                className="text-sm text-muted-foreground">
                                                Show New Password
                                            </label>
                                        </div>
                                    </FormItem>
                                )}
                            />

                            {/* Confirm Password Field */}
                            <FormField
                                control={form.control}
                                name="confirmPassword"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Confirm Password</FormLabel>
                                        <FormControl>
                                            <Input
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                placeholder="Confirm your password..."
                                                disabled={loading}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                        <div className="flex items-center space-x-2 mt-2">
                                            <input
                                                type="checkbox"
                                                id="showConfirmPassword"
                                                checked={showConfirmPassword}
                                                onChange={() =>
                                                    setShowConfirmPassword(!showConfirmPassword)
                                                }
                                                className="h-4 w-4"
                                            />
                                            <label
                                                htmlFor="showConfirmPassword"
                                                className="text-sm text-muted-foreground">
                                                Show Confirm Password
                                            </label>
                                        </div>
                                    </FormItem>
                                )}
                            />

                            <Button disabled={loading} className="ml-auto w-full" type="submit">
                                Continue
                            </Button>
                        </form>
                    </Form>

                    <p className="px-8 text-center text-sm text-muted-foreground">
                        <Link
                            to="/terms"
                            className="underline underline-offset-4 hover:text-primary">
                            Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link
                            to="/privacy"
                            className="underline underline-offset-4 hover:text-primary">
                            Privacy Policy
                        </Link>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
}

// --------------------
