import { useToast } from '@/components/ui/use-toast';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from '@/components/ui/dialog';
import { Edit, FlagIcon, Trash, X } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { ScheduleDetails } from './schedule';
import Color from 'color';
import { PopoverClose } from '@radix-ui/react-popover';

interface ErrorProps {
    isOpen: boolean;
    onClose: () => void;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scheduleError: any[];
    facultyError: any[];
    setScheduleError: React.Dispatch<React.SetStateAction<any[]>>;
    setFacultyError: React.Dispatch<React.SetStateAction<any[]>>;
    searchError: string;
    setSearchError: React.Dispatch<React.SetStateAction<string>>;
    handleCardClick: (data: any) => void;
}

const ErrorDialog = ({
    isOpen,
    onClose,
    setIsOpen,
    scheduleError,
    facultyError,
    setScheduleError,
    setFacultyError,
    searchError,
    setSearchError,
    handleCardClick,
}: ErrorProps) => {
    const [sortConfig, setSortConfig] = useState<{
        key: string | null;
        direction: 'ascending' | 'descending';
    }>({
        key: null,
        direction: 'ascending',
    });
    const [activeTab, setActiveTab] = useState<'schedule' | 'faculty'>('schedule');

    const sortData = useCallback(
        (key: string) => {
            const direction =
                sortConfig.key === key && sortConfig.direction === 'descending'
                    ? 'ascending'
                    : 'descending';
            const sortedData = [...(activeTab === 'schedule' ? scheduleError : facultyError)].sort(
                (a, b) => {
                    if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
                    if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            );

            setSortConfig({ key, direction });
            activeTab === 'schedule' ? setScheduleError(sortedData) : setFacultyError(sortedData);
        },
        [sortConfig, activeTab, scheduleError, facultyError, setScheduleError, setFacultyError]
    );

    const getSortIcon = (key: string) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return null;
    };

    const formatHeader = (key: string): string => {
        const headers: Record<string, string> = {
            batch_name: 'Batch Name',
            date: 'Date',
            time: 'Time',
            subject_name: 'Subject Name',
            faculty_code: 'Faculty Code',
            faculty_name: 'Faculty Name',
            message: 'Message',
        };
        return headers[key] || key;
    };

    const filterData = useCallback(
        (data: any[]) => {
            if (!searchError) return data;
            return data.filter((item) =>
                Object.values(item).some((value) =>
                    String(value).toLowerCase().includes(searchError.toLowerCase())
                )
            );
        },
        [searchError]
    );

    const openDialog = useCallback(() => {
        setActiveTab(
            scheduleError.length === 0 && facultyError.length !== 0 ? 'faculty' : 'schedule'
        );
        setSearchError('');
        setIsOpen(true);
    }, [scheduleError.length, facultyError.length, setSearchError, setIsOpen]);

    const tableHeaders = useMemo(() => {
        return activeTab === 'schedule'
            ? [
                  '#',
                  'batch_name',
                  'date',
                  'time',
                  'subject_name',
                  'faculty_code',
                  'faculty_name',
                  'message',
              ]
            : ['#', 'date', 'faculty_code', 'faculty_name', 'message'];
    }, [activeTab]);

    const renderTableRows = (data: any[], isSchedule: boolean) => {
        return data.length === 0 ? (
            <tr>
                <td colSpan={9} className="text-center py-4">
                    No Errors available
                </td>
            </tr>
        ) : (
            data.map((item, i) => (
                <tr key={i}>
                    <td className="px-4 py-4 text-sm">{i + 1}</td>
                    {isSchedule ? (
                        <>
                            <td className="px-4 py-4 text-sm">{item.batch_name}</td>
                            <td className="px-4 py-4 text-sm">{moment(item.date).format('LL')}</td>
                            <td className="px-4 py-4 text-sm">{item.time}</td>
                            <td className="px-4 py-4 text-sm">{item.subject_name}</td>
                        </>
                    ) : (
                        <td className="px-4 py-4 text-sm">{moment(item.date).format('LL')}</td>
                    )}
                    <td className="px-4 py-4 text-sm">
                        <a
                            href={`/faculties/${item.faculty_id}?date=${moment(item.date).format('YYYY-MM-DD')}`}
                            target="_blank"
                            rel="noreferrer">
                            {item.faculty_code}
                        </a>
                    </td>
                    <td className="px-4 py-4 text-sm">{item.faculty_name}</td>
                    <td className="px-4 py-4 text-sm">{item.message}</td>
                    {isSchedule && (
                        <td className="px-4 py-4 flex items-center space-x-3 text-sm">
                            <Popover>
                                <PopoverTrigger asChild>
                                    <button
                                        onClick={(e) => e.stopPropagation()}
                                        className="text-xl text-green-700">
                                        <Edit className="text-primary" />
                                    </button>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="start"
                                    side={'left'}
                                    style={{
                                        borderColor: Color(
                                            item?.lesson?.subject?.subject_color_code
                                        ).hex(),
                                        boxShadow:
                                            '0 4px 6px -1px ' +
                                            Color(item?.lesson?.subject?.subject_color_code).rgb() +
                                            ', 0 2px 4px -2px ' +
                                            Color(item?.lesson?.subject?.subject_color_code).rgb(),
                                    }}
                                    className="relative w-[28rem] z-50 bg-background shadow-2xl border-2 p-4 ">
                                    <PopoverClose className="ml-auto">
                                        <X size={15} />
                                    </PopoverClose>
                                    <ScheduleDetails
                                        item={item?.lesson}
                                        onEdit={handleCardClick}
                                        refresh={onClose}
                                    />
                                </PopoverContent>
                            </Popover>
                        </td>
                    )}
                </tr>
            ))
        );
    };

    return (
        <>
            <div
                className="fixed right-64 z-30 bottom-12 rounded-r-full shadow-lg transition-all duration-300 hover:shadow-xl cursor-pointer"
                onClick={openDialog}>
                <div className="flex items-center justify-between bg-background p-3 border-2 rounded-full border-primary relative hover:shadow-md">
                    <FlagIcon className="text-2xl text-primary animate-spin-slow" />
                    <div className="absolute top-0 right-1 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white px-2 py-1 rounded-full text-xs font-bold">
                        {scheduleError?.length}
                    </div>
                    <div className="absolute -bottom-4 right-1 transform translate-x-1/2 -translate-y-1/2 bg-amber-400 text-white px-2 py-1 rounded-full text-xs font-bold">
                        {facultyError?.length}
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="max-w-full md:max-w-8xl p-4 md:p-8">
                    <div className="flex flex-col md:flex-row">
                        <DialogHeader className="flex-1">
                            <DialogTitle className="text-lg md:text-xl">
                                {activeTab == 'faculty' ? 'Warning List' : 'Conflict List'}
                            </DialogTitle>
                            <DialogDescription className="text-sm md:text-base">
                                {activeTab == 'faculty'
                                    ? 'Warning list below, This is for information only, These warnings do not cause any conflicts in the schedule'
                                    : ' Resolved Conflict by taking Action'}
                            </DialogDescription>
                        </DialogHeader>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchError}
                            onChange={(e) => setSearchError(e.target.value)}
                            className="mt-2 md:mt-0 mb-4 p-2 ml-auto border border-gray-300 rounded bg-background w-full md:w-auto"
                        />
                    </div>

                    <Tabs
                        value={activeTab}
                        onValueChange={(value) => setActiveTab(value as 'schedule' | 'faculty')}
                        className="w-full">
                        <TabsList className="grid grid-cols-2 w-full">
                            <TabsTrigger className="text-sm md:text-base" value="schedule">
                                Schedule Conflicts ({scheduleError?.length})
                            </TabsTrigger>
                            <TabsTrigger className="text-sm md:text-base" value="faculty">
                                Schedule Warnnings ({facultyError?.length})
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent value="schedule" className="h-[29rem] overflow-auto">
                            <table className="w-full divide-y divide-gray-200">
                                <thead className="sticky top-0 bg-background">
                                    <tr>
                                        {tableHeaders.map((key) => (
                                            <th
                                                key={key}
                                                onClick={() => sortData(key)}
                                                className="px-2 md:px-4 py-2 md:py-3 text-left text-sm font-semibold cursor-pointer">
                                                {formatHeader(key)} {getSortIcon(key)}
                                            </th>
                                        ))}
                                        <th className="px-2 md:px-4 py-2 md:py-3 text-left text-sm  font-semibold">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {renderTableRows(filterData(scheduleError), true)}
                                </tbody>
                            </table>
                        </TabsContent>
                        <TabsContent value="faculty" className="h-[24rem] overflow-auto">
                            <table className="w-full divide-y divide-gray-200">
                                <thead className="bg-background sticky top-0">
                                    <tr>
                                        {tableHeaders.map((key) => (
                                            <th
                                                key={key}
                                                onClick={() => sortData(key)}
                                                className="px-2 md:px-4 py-2 md:py-3 text-left text-sm md:text-base font-semibold cursor-pointer">
                                                {formatHeader(key)} {getSortIcon(key)}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {renderTableRows(filterData(facultyError), false)}
                                </tbody>
                            </table>
                        </TabsContent>
                    </Tabs>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ErrorDialog;
