import { DatePickerWithRange } from '@/components/shared/date-range-picker';
import { useCommonProvider } from '../providers/common-provider';
import { Label } from '@/components/ui/label';
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from '@/components/ui/select';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { startOfWeek, endOfWeek } from 'date-fns';
import { WeekPicker } from '@/components/shared/week-picker';
interface SelectItemProps {
    value: string;
    label: string;
}
export const SelectWithPlaceholder: React.FC<{
    labelName?: string;
    placeholder?: string;
    items?: SelectItemProps[] | undefined;
    selectedValue?: string | undefined;
    onChange: (value: string) => void; // onChange handler
}> = React.memo(({ labelName, placeholder, selectedValue, items, onChange }) => (
    <>
        <div className="flex flex-col">
            <Label className="mb-1 ml-1">{labelName}</Label>
            <Select onValueChange={(event) => onChange(event)} value={selectedValue}>
                <SelectTrigger className="w-full ">
                    <SelectValue placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent className="mt-1 w-full">
                    {items &&
                        items?.length > 0 &&
                        items?.map((item, index) => (
                            <SelectItem key={index} value={item.value}>
                                {item.label}
                            </SelectItem>
                        ))}
                </SelectContent>
            </Select>
        </div>
    </>
));
export function LocationComponent({ showLabel = true }: { showLabel?: boolean }) {
    const { locationList, setSelectedLocation, selectedLocation, userDetail } = useCommonProvider();
    const [list, setList] = useState<SelectItemProps[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const locationId = searchParams.get('location');

    useEffect(() => {
        if (locationList) {
            const transformedList: SelectItemProps[] = locationList.map((location: any) => ({
                value: location['id'],
                label: location['name'],
            }));
            setList(transformedList);
        }
    }, [locationList]);
    useEffect(() => {
        if (locationId) {
            setSelectedLocation(locationId);
        } else {
            if (userDetail) setSelectedLocation(userDetail['location_id']);
        }
    }, [locationId, userDetail]);
    const handleonChange = (value: string) => {
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            location: value,
        });
        setSelectedLocation(value);
    };
    return (
        <SelectWithPlaceholder
            onChange={handleonChange}
            // labelName="Location"
            {...(showLabel && { labelName: 'Location' })}
            placeholder="Select Location"
            selectedValue={selectedLocation}
            items={list}
        />
    );
}
export function SubjectComponent({ showLabel = true }: { showLabel?: boolean }) {
    const { subjectList, setSelectedSubject, selectedSubject } = useCommonProvider(); // Context values
    const [list, setList] = useState<SelectItemProps[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const subjectId = searchParams.get('subject');

    useEffect(() => {
        console.log('Subject List:', subjectList); // Log the subjectList

        // Check if subjectList has data and if it's an array
        if (subjectList && Array.isArray(subjectList.data)) {
            const transformedList: SelectItemProps[] = subjectList.data.map((subject: any) => ({
                value: subject['id'], // Assuming each subject has an `id`
                label: subject['subject_name'], // Assuming the property for label is `subject_name`
            }));
            // Add "All Subjects" option
            const allSubjectsOption = {
                value: 'all',
                label: 'All Subjects',
            };
            const updatedList = [allSubjectsOption, ...transformedList];
            setList(updatedList);
        } else {
            console.error('subjectList.data is not an array:', subjectList);
            setList([]);
        }
    }, [subjectList]);

    useEffect(() => {
        if (subjectId) {
            setSelectedSubject(subjectId);
        }
    }, [subjectId, setSelectedSubject]);

    const handleOnChange = (value: string) => {
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            subject: value,
        });
        setSelectedSubject(value);
    };

    return (
        <SelectWithPlaceholder
            onChange={handleOnChange}
            {...(showLabel && { labelName: 'Subject' })}
            // placeholder="Select Subject"
            selectedValue={selectedSubject || 'all'}
            items={list}
        />
    );
}

export function ScheduleTypeComponent() {
    const { scheduleTypeList, setSelectedScheduleType, selectedScheduleType } = useCommonProvider();
    const [searchParams, setSearchParams] = useSearchParams();
    const scheduleType = searchParams.get('scheduleType');
    useEffect(() => {
        if (scheduleType) {
            setSelectedScheduleType(scheduleType);
        } else {
            setSelectedScheduleType('j/m');
        }
    }, [scheduleType]);
    const handleonChange = (value: string) => {
        setSelectedScheduleType(value);
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            scheduleType: value,
        });
    };
    return (
        <SelectWithPlaceholder
            onChange={handleonChange}
            labelName="Schedule Type"
            placeholder="Select Schedule Type"
            selectedValue={selectedScheduleType}
            items={scheduleTypeList}
        />
    );
}

export function BatchComponent() {
    const { batchList, setSelectedBatch, selectedBatch } = useCommonProvider();
    const [list, setList] = useState<SelectItemProps[]>([]);
    const handleonChange = (value: string) => {
        setSelectedBatch(value);
    };
    useEffect(() => {
        if (batchList) {
            const transformedList: SelectItemProps[] = batchList.map((batch: any) => ({
                value: batch['id'],
                label: batch['batch_code'],
            }));
            const item = {
                value: 'ALL',
                label: 'All Batches',
            };
            const allTransformedList = [item, ...transformedList];
            setList(allTransformedList);
        }
    }, [batchList]);
    return (
        <SelectWithPlaceholder
            onChange={handleonChange}
            labelName="Batch"
            placeholder="Select Batch"
            selectedValue={selectedBatch}
            items={list}
        />
    );
}

export function DateRangeComponent() {
    const { selectedDate, setSelectedDate } = useCommonProvider();
    const [searchParams, setSearchParams] = useSearchParams();
    const from = searchParams.get('from');
    const to = searchParams.get('to');
    useEffect(() => {
        if (from && to) {
            console.log(selectedDate, { from: new Date(from), to: new Date(to) });
            setSelectedDate({ from: new Date(from), to: new Date(to) });
        } else {
            setSelectedDate({
                from: startOfWeek(new Date(), { weekStartsOn: 1 }),
                to: endOfWeek(new Date(), { weekStartsOn: 1 }),
            });
        }
    }, []);

    const handleonChange = (value: DateRange | undefined) => {
        setSelectedDate(value);
        if (value !== undefined) {
            setSearchParams({
                ...Object.fromEntries(searchParams), // Spread the existing search params
                from: moment(value.from).format('YYYY-MM-DD'),
                to: moment(value.to).format('YYYY-MM-DD'),
            });
        }
    };

    return (
        <div className="flex flex-col">
            <Label className="mb-1 ml-1">Date Range</Label>
            <WeekPicker
                width={'w-full'}
                initialDateRange={selectedDate}
                onSubmit={handleonChange}
            />{' '}
        </div>
    );
}
