import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import logo from '../../webassic.png'; // Adjust the path as needed
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { useForgotPassword } from '@/queries/forgot-password';

const formSchema = z.object({
    email: z
        .string()
        .nonempty({ message: 'This is required. *' })
        .email({ message: 'Enter a valid email address' }),
});

type UserFormValue = z.infer<typeof formSchema>;

export default function ForgotPassword() {
    const {
        mutate: forgotPassword,
        isSuccess: forgotIsSuccess,
        isError: forgotIsError,
        error,
    } = useForgotPassword();
    const { toast } = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const form = useForm<UserFormValue>({
        resolver: zodResolver(formSchema),
        defaultValues: { email: '' },
    });

    const onSubmit = async (data: UserFormValue) => {
        const postData = {
            email: data.email,
        };
        setLoading(true);

        try {
            await forgotPassword(postData);
        } catch (err) {
            console.log('error', err);
        }
    };

    useEffect(() => {
        if (forgotIsSuccess) {
            toast({
                title: 'Email Send Successfully',
                description: 'The email was sent successfully.',
                variant: 'default', // Success variant
            });
            setLoading(false);

            navigate('/login'); // Redirect after success
        } else if (forgotIsError) {
            const typedError = error as any; // or as unknown
            let errorMsg = 'There was an error send the email.';
            if (typedError?.response) {
                errorMsg = typedError?.response?.data?.error;
            } else {
                errorMsg = error?.message || errorMsg;
            }
            toast({
                title: 'ERROR:',
                variant: 'destructive',
                description: errorMsg,
            });
            setLoading(false);
        }
    }, [forgotIsSuccess, error]);
    return (
        <div className="relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
            <Link
                to="/forgot"
                className={cn(
                    buttonVariants({ variant: 'ghost' }),
                    'absolute right-4 top-4 hidden md:right-8 md:top-8'
                )}>
                Forgot Password
            </Link>

            <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
                <div className="absolute inset-0 bg-primary dark:bg-secondary" />
                <div className="relative z-20 flex items-center text-lg font-medium">
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-16 transition-all group-hover:scale-110"
                    />
                </div>
                <div className="relative z-20 mt-auto">
                    <blockquote className="space-y-2">
                        <p className="text-lg">
                            &ldquo;Simplify lecture scheduling with our dedicated platform. Manage
                            schedules effortlessly, coordinate sessions seamlessly, and optimize
                            your educational institution's timetable with ease.&rdquo;
                        </p>
                    </blockquote>
                </div>
            </div>

            <div className="flex h-full items-center p-4 lg:p-8">
                <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                    <div className="flex flex-col space-y-2 text-center">
                        <h1 className="text-2xl font-semibold tracking-tight">Forgot Password</h1>

                        <p className="text-sm text-muted-foreground">
                            Reset your password with email
                        </p>
                        <p className="mt-1 text-center text-sm text-gray-600">
                            Or
                            <Link to="/login" className="pl-1 ">
                                Login
                            </Link>
                        </p>
                    </div>

                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-2">
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="email"
                                                placeholder="Enter your email..."
                                                disabled={loading}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <Button disabled={loading} className="ml-auto w-full" type="submit">
                                Continue
                            </Button>
                        </form>
                    </Form>

                    <p className="px-8 text-center text-sm text-muted-foreground">
                        <Link
                            to="/terms"
                            className="underline underline-offset-4 hover:text-primary">
                            Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link
                            to="/privacy"
                            className="underline underline-offset-4 hover:text-primary">
                            Privacy Policy
                        </Link>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
}

// --------------------
