import axios, { AxiosRequestConfig } from 'axios';

export interface QueryParams {
    page?: number;
    limit?: number;
    sortBy?: string;
    sortOrder?: string;
    q?: string;
    location_id?: string; // todo: remove this from here
    location_ids?: string[]; // Array of location IDs
    stream_code?: string; // todo: remove this from here
    starting_date?: string;
    ending_date?: string;
    faculty_id?: string; // Optional parameter
}

export type User = {
    id: string;
    name: string;
    email: string;
};

const apiService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor
apiService.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.Accept = 'application/json';
            // Default Content-Type
            if (!config.headers['Content-Type']) {
                config.headers['Content-Type'] = 'application/json';
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor with retry logic
apiService.interceptors.response.use(
    async (response) => {
        return response.data;
    },
    async (error) => {
        const originalRequest = error.config;
        console.log(error);
        // Handle 401 Unauthorized
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/');
            return Promise.reject(error);
        }

        // Retry logic for server errors (500 and above)
        if (error.response && error.response.status >= 500) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                return apiService(originalRequest);
            }
        }

        return Promise.reject(error);
    }
);
// GET method
export const get = async (url: string, config?: AxiosRequestConfig) => {
    try {
        return apiService.get(url, config);
    } catch (error) {
        return Promise.reject(error);
    }
};

// POST method
export const post = async (url: string, data?: any, config?: AxiosRequestConfig) => {
    try {
        const response = await apiService.post(url, data, config);
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
};

// PATCH method
export const patch = async (url: string, data?: any, config?: AxiosRequestConfig) => {
    try {
        const response = await apiService.patch(url, data, config);
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
};

// PATCH method
export const put = async (url: string, data?: any, config?: AxiosRequestConfig) => {
    try {
        const response = await apiService.put(url, data, config);
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
};

// DELETE method
export const del = async (url: string, config?: AxiosRequestConfig) => {
    try {
        const response = await apiService.delete(url, config);
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
};

export async function fetchData(uri: string, queryParams: QueryParams): Promise<Array<any>> {
    const { page, limit, sortBy, sortOrder, q } = queryParams;
    try {
        return apiService.get(uri, {
            params: {
                q,
                limit,
                page,
                sortBy,
                sortOrder,
            },
        });
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const fetchById = async (uri: string, id?: string) => {
    if (id == '') {
        return null;
    }
    const res = await apiService.get(`${uri}/${id}`);
    return res.data || res;
};

export const getFile = async (url: string) => {
    try {
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(process.env.REACT_APP_API_URL + url, {
            responseType: 'blob',
            headers,
        });
        return response;
    } catch (error) {
        console.error(`Error in request:`, error);
        throw error;
    }
};

export default apiService;
