import React, { useState } from 'react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { EllipsisVertical } from 'lucide-react';
import { Event } from './big-calendar';
import { useNavigate } from 'react-router-dom';

interface BigCalendarEventViewProps {
    event: Event;
    handleEditSchedule?: (scheduleId: string) => void; // Add this line
    handleDeleteSchedule?: (scheduleId: string) => void; // Add this line
}
const BigCalendarEventView: React.FC<BigCalendarEventViewProps> = ({
    event,
    handleEditSchedule,
    handleDeleteSchedule,
}) => {
    console.log('event', event);
    const handleClick = (facultyId?: string, batchId?: string) => {
        if (batchId) {
            window.open(`/batches/${batchId}`, '_blank'); // Open batch details in new tab
        } else if (facultyId) {
            window.open(`/faculties/${facultyId}`, '_blank'); // Open faculty details in new tab
        }
    };

    return (
        <div className="text-xs bg-blend-lighten relative flex items-center justify-between rotate-incline">
            <div className="flex flex-col">
                {!event?.hasDoubtSessions && (
                    <>
                        <span
                            style={{ padding: '8px 0' }}
                            onClick={() =>
                                handleClick(event?.schedule?.faculty_id, event?.batchId)
                            }>
                            {event?.title}
                        </span>
                        <span>{event?.locationName}</span>
                    </>
                )}
            </div>
            {event.isScheduleEditable && event.hasSchedule && (
                <div className="absolute top-0 right-0 mt-0">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <EllipsisVertical />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom" align="end">
                            {/* <DropdownMenuItem
                                className="cursor-pointer"
                                onClick={() => {
                                    if (event.scheduleId && handleEditSchedule) {
                                        // Check if both are defined
                                        handleEditSchedule(event.scheduleId);
                                    }
                                }}>
                                Edit
                            </DropdownMenuItem> */}
                            <DropdownMenuItem
                                className="cursor-pointer"
                                onClick={() => {
                                    if (event.scheduleId && handleDeleteSchedule) {
                                        // Check if both are defined
                                        handleDeleteSchedule(event.scheduleId);
                                    }
                                }}>
                                Delete
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            )}
        </div>
    );
};

export default BigCalendarEventView;
