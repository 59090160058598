import apiService, { del, fetchById, get, patch, post } from '@/lib/api';
import { useQuery } from '@tanstack/react-query';

export const useGetLeaves = (id: string) => {
    return useQuery({
        queryKey: ['getAllLeaveData', id],
        queryFn: () => get('getAllLeave?location_id=' + id),
    });
};

// Define your mutation function with correct type signature
export const useSubmitLeave = async (formData: any): Promise<any> => {
    if (formData.id) {
        const response = await patch(`/updateLeave/${formData.id}`, formData);
        return response; // Assuming response.data is of type any
    } else {
        const response = await post('/createLeave', formData);
        return response; // Assuming response.data is of type any
    }
};
// Define your mutation function with correct type signature
export const useDeleteLeave = async (id: any): Promise<unknown> => {
    const response = await del('/deleteLeave/' + id);
    return response; // Assuming response.data is of type any
};
