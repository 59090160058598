import { Card, CardContent } from '@/components/ui/card';
import { ArrowLeftRight, ArrowRight, CalendarDays, ExternalLinkIcon, Plus, X } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { ScheduleDetails } from './schedule';
import { convertTimeTo12HourFormat, convertTimeTo24TimeFormat } from '@/common/functions';
import { Button } from '@/components/ui/button';
import Color from 'color';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { useUpdateScheduleFaculty, useSwapSchedule } from '@/queries/schedule-query';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@/components/ui/use-toast';
import { useCommonProvider } from '@/providers/common-provider';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';

export const ErrorPingIcon: React.FC<any> = React.memo(({ align = 'right' }) => (
    <div className="relative">
        <div className={`absolute ${align}-0 top-0 flex items-center justify-center w-4 h-4`}>
            <span className="absolute w-full h-full bg-red-400 rounded-full animate-ping opacity-75"></span>
            <span className="relative w-[10px] h-[10px] bg-red-500 rounded-full"></span>
        </div>
    </div>
));
export const BatchSlot: React.FC<{ batch: { id: string; batch_code: string }; type: string }> =
    React.memo(({ batch, type }) => {
        const { selectedDate } = useCommonProvider();
        return (
            <div
                className={`w-28 ${type === 'special' ? 'h-20' : 'h-16'}  text-xs p-1.5 border flex flex-col bg-muted/40 justify-center hover:font-bold `}>
                <a
                    href={`/batches/${batch.id}?date=${moment(selectedDate?.from).format(
                        'YYYY-MM-DD'
                    )}`}
                    target="_blank"
                    key={batch.id}
                    rel="noreferrer">
                    <span>{batch?.batch_code}</span>
                </a>
            </div>
        );
    });

export const SlotCard: React.FC<{
    lesson: any;
    label: any;
    allowToAddLesson: any;
    isHoliday: any;
    type: string;
    selectedFaculties: any;
    onCreateSchedule: (data: any) => void;
    onEdit: (data: any) => void;
    refresh: (data: any) => void;
}> = React.memo(
    ({
        lesson,
        label,
        allowToAddLesson,
        isHoliday,
        type,
        selectedFaculties = [],
        onCreateSchedule,
        onEdit,
        refresh,
    }) => {
        const color = Color(lesson?.subject?.subject_color_code);
        const { toast } = useToast();
        const [showConfirmModal, setShowConfirmModal] = useState(false);
        const [draggedLesson, setDraggedLesson] = useState<any>();
        const [droppedLesson, setDroppedLesson] = useState<any>();
        const [scheduleIds, setScheduleIds] = useState<any[]>([]);

        const handleCardClick = () => {
            onCreateSchedule(label); // Correct usage of onEdit
        };
        const handleDragStart = (event: React.DragEvent<HTMLDivElement>, schedule: any) => {
            event.dataTransfer.setData('schedule', JSON.stringify(schedule));
        };

        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
        };

        const handleDrop = async (event: React.DragEvent<HTMLDivElement>, slot?: any) => {
            event.preventDefault();
            const schedule = JSON.parse(event.dataTransfer.getData('schedule'));
            if (slot.id && schedule.id === slot.id) {
                return;
            } else {
                setDraggedLesson(schedule);
                setDroppedLesson(slot);
                setShowConfirmModal(true);
            }
        };
        const handleConfirm = async () => {
            if (droppedLesson.id) {
                const data: any = {
                    schedule1: draggedLesson.id,
                    schedule2: droppedLesson.id,
                };
                setScheduleIds([draggedLesson.id, droppedLesson.id]);
                await swapFaculty(data);
            } else {
                const data: any = {
                    faculty_id: draggedLesson.faculty_id,
                    id: draggedLesson.id,
                    batch_id: droppedLesson.batch_id,
                    date: droppedLesson.date,
                    slot_time: convertTimeTo24TimeFormat(droppedLesson.time),
                    location_id: draggedLesson.location_id,
                    subject_id: draggedLesson.subject_id,
                };
                setScheduleIds([draggedLesson.id]);
                await replaceFaculty(data);
            }
            setShowConfirmModal(false);
        };
        const { mutate: replaceFaculty } = useMutation<any>({
            mutationFn: useUpdateScheduleFaculty,
            onSuccess: (data) => {
                toast({
                    title: data.message,
                    duration: 2000,
                    variant: 'default',
                    description: 'Thank You...',
                });
                refresh(scheduleIds); // Close the dialog after submission
            },
            onError: (error: any) => {
                toast({
                    title: error?.response?.data?.message || 'Invalid data',
                    variant: 'destructive',
                    duration: 5000,
                    description: 'Please Try Again..',
                });
            },
        });
        const { mutate: swapFaculty } = useMutation<any>({
            mutationFn: useSwapSchedule,
            onSuccess: (data) => {
                toast({
                    title: data.message,
                    duration: 2000,
                    variant: 'default',
                    description: 'Thank You...',
                });
                refresh(scheduleIds); // Close the dialog after submission
            },
            onError: (error: any) => {
                toast({
                    title: error?.response?.data?.message || 'Invalid data',
                    variant: 'destructive',
                    duration: 5000,
                    description: 'Please Try Again..',
                });
            },
        });
        const isSelected = (id: string) =>
            selectedFaculties.includes(id) || selectedFaculties?.length === 0;
        return (
            <>
                {lesson['id'] ? (
                    <Popover>
                        <div>
                            {lesson?.displayError && <ErrorPingIcon />}
                            <div
                                className={`w-16 h-16 ${isSelected(lesson?.faculty?.id) ? '' : 'opacity-20'} text-xs p-1.5 border border-l-[12px]  flex text-center  flex-col items-center justify-center`}
                                style={{
                                    borderColor: color.hex(),
                                    color: color.darken(0.7).hex(),
                                    backgroundColor: allowToAddLesson
                                        ? color.lighten(0.95).hex()
                                        : '',
                                }}
                                draggable={lesson?.faculty?.id ? true : false}
                                onDragOver={(event) => handleDragOver(event)}
                                onDragStart={(event) => handleDragStart(event, lesson)}
                                onDrop={(event) => handleDrop(event, lesson)}>
                                <PopoverTrigger asChild>
                                    <span className="hover:font-bold  cursor-pointer">
                                        {lesson?.faculty?.faculty_code}
                                    </span>
                                </PopoverTrigger>
                            </div>
                        </div>

                        <PopoverContent
                            style={{
                                borderColor: color.hex(),
                                boxShadow:
                                    '0 4px 6px -1px ' +
                                    color.rgb() +
                                    ', 0 2px 4px -2px ' +
                                    color.rgb(),
                            }}
                            className="relative w-[28rem] z-50 bg-background border-2 p-4">
                            <PopoverClose className="ml-auto">
                                <X size={15} />
                            </PopoverClose>
                            <ScheduleDetails item={lesson} onEdit={onEdit} refresh={refresh} />
                        </PopoverContent>
                    </Popover>
                ) : (
                    <>
                        <div
                            onClick={handleCardClick}
                            onDragOver={(event) => handleDragOver(event)}
                            onDrop={(event) =>
                                handleDrop(event, {
                                    ...label,
                                    isHoliday: isHoliday,
                                    batch_name: '',
                                })
                            }
                            className={`group flex w-16 ${type === 'special' ? 'h-20' : ' h-16'} cursor-pointer justify-center border border-neutral-300  flex-col text-sm relative items-center ${allowToAddLesson ? ' bg-background' : ''} hover:bg-primary`}>
                            <div className="hidden group-hover:flex items-center justify-center h-full">
                                <Plus size={28} className="text-background" />
                            </div>
                            {isHoliday ? (
                                <span className="group-hover:hidden text-gray-400">Holiday</span>
                            ) : (
                                <span className="group-hover:hidden">-</span>
                            )}
                        </div>
                    </>
                )}
                {/* Confirmation Modal */}
                <Dialog open={showConfirmModal} onOpenChange={setShowConfirmModal}>
                    <DialogContent className="p-6 space-y-2 max-w-2xl">
                        <Label className="text-lg font-bold text-center">
                            {droppedLesson?.id ? 'Swap Schedules?' : 'Move Schedule?'}
                        </Label>

                        {/* Swap/Move Action Description */}
                        <div className="text-center text-gray-700">
                            <Label className="text-base">
                                {droppedLesson?.id
                                    ? 'Please confirm if you want to swap the selected schedules?'
                                    : 'Please confirm if you want to move the schedule to the new slot?'}
                            </Label>
                        </div>

                        <div className="flex gap-6 justify-center">
                            {/* Dragged Lesson Card */}
                            <div
                                className="p-4 border-2 border-l-[12px] flex flex-col space-y-2  justify-center shadow-lg rounded-lg bg-gray-50"
                                style={{ borderColor: draggedLesson?.subject?.subject_color_code }}>
                                <span className="text-base font-semibold">
                                    {draggedLesson?.faculty?.faculty_code}
                                </span>
                                <span className="text-sm text-gray-600">
                                    {draggedLesson?.batch?.batch_code}
                                </span>
                                <span className="text-xs text-gray-500">
                                    {moment(draggedLesson?.date).format('DD MMM, yyyy')}
                                </span>
                                <span className="text-xs text-gray-500">
                                    {draggedLesson?.formatted_time}
                                </span>
                            </div>

                            {/* Arrow Icon */}
                            <div className="flex items-center justify-center text-gray-500">
                                {droppedLesson?.id ? (
                                    <ArrowLeftRight className="w-8 h-8" />
                                ) : (
                                    <ArrowRight className="w-8 h-8" />
                                )}
                            </div>

                            {/* Dropped Lesson Card */}
                            <div
                                className="p-4 border-2 border-l-[12px] flex flex-col space-y-2 justify-center shadow-lg rounded-lg bg-gray-50"
                                style={{
                                    borderColor: droppedLesson?.faculty?.color_code || 'gray',
                                }}>
                                {droppedLesson?.id ? (
                                    <>
                                        <span className="text-base font-semibold">
                                            {droppedLesson?.faculty?.faculty_code}
                                        </span>
                                        <span className="text-sm text-gray-600">
                                            {droppedLesson?.batch?.batch_code}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {moment(droppedLesson?.date).format('DD MMM, yyyy')}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {droppedLesson?.formatted_time}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="text-base font-semibold">
                                            {droppedLesson?.isHoliday ? 'Holiday' : 'New Slot'}
                                        </span>
                                        <span className="text-sm text-gray-600">
                                            {droppedLesson?.batch_code}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {moment(droppedLesson?.date).format('DD MMM, yyyy')}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {droppedLesson?.time}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>

                        <DialogFooter className="flex justify-end space-x-4">
                            <Button variant="outline" onClick={() => setShowConfirmModal(false)}>
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm}>Confirm</Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
);
export const TimeSlot: React.FC<{ slot: { slot_time: string; slots: any }; type: string }> =
    React.memo(({ slot, type }) => {
        const times = slot.slot_time ? convertTimeTo12HourFormat(slot.slot_time) : '';
        const timesArray = times.split('-') || '';
        return (
            <div
                className={`w-16 h-6 text-xs font-medium p-0.5 text-center flex flex-col items-center justify-center`}>
                {type !== 'special' && <span>{timesArray[0]}</span>}
            </div>
        );
    });
export const SpecialTimeSLot: React.FC<{ slot: any }> = React.memo(({ slot }) => {
    return (
        <div
            className={`relative font-semibold  bg-secondaryColour border-neutral-300 text-primaryColour  flex items-center justify-center`}>
            {slot?.lesson?.id ? (
                <>
                    <span className="text-xs" data-tooltip-id={`tooltip-M-${slot?.lesson?.id}`}>
                        {moment(slot?.lesson?.slot_time?.split('-')[0], 'HH:mm')?.format('hh:mm A')}
                    </span>
                </>
            ) : (
                <></>
            )}
        </div>
    );
});
export const DateCard: React.FC<{
    type: any;
    date: { day: number; label: string; date: Date };
    timeSlotList: any;
    blankSlots: any;
}> = React.memo(({ type, date, timeSlotList, blankSlots }) => {
    return (
        <Card className="flex flex-col border-0  shadow-none mb-2">
            <CardContent
                className={`${blankSlots?.length == 0 && timeSlotList?.length < 3 ? (timeSlotList?.length === 2 ? 'text-sm' : 'text-xs') : ''}  border-0  bg-muted/40  w-auto  h-auto p-1.5 text-center  flex-col items-center justify-center`}>
                <div className="flex h-12 justify-center flex-col border text-primary shadow">
                    <span className="text-base font-medium">
                        {moment(date.date).format('Do MMM, dddd')}
                    </span>
                </div>
                <div className="flex  mt-1 space-x-2 ">
                    {timeSlotList.map((slot: any, index: any) => (
                        <div key={index} className="flex-col ">
                            <TimeSlot
                                key={`${type}_${date.day}_${slot.id}`}
                                slot={slot}
                                type={type}
                            />
                        </div>
                    ))}
                    {blankSlots.map((slot: any, index: any) => (
                        <div key={slot.id || index} className="flex-col  ">
                            <TimeSlot
                                key={`${type}_${date.day}_${index}`}
                                slot={{ slot_time: '', slots: '' }}
                                type={type}
                            />
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
});

export const ScheduleTimesCard: React.FC<any> = React.memo(
    ({
        type,
        batchSlotData,
        dates,
        allDetails,
        handleCardClick,
        refresh,
        selectedFaculties = [],
    }) => {
        if (allDetails && allDetails?.length > 0) {
            return (
                <div className="inline-flex p-2 pt-0 pl-0 relative">
                    <div className="flex flex-col sticky top-0 left-0 z-20">
                        <div className="flex flex-col sticky m-[8px] ml-1 bg-card top-0 z-50">
                            <div className="h-[5.3rem] bg-muted/40">
                                <span className="w-full h-full flex text-center items-center justify-center">
                                    <img
                                        className="w-12 h-12"
                                        src={`/images/${type}.png`}
                                        alt={type}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="sticky top-0 p-1.5 left-0 flex flex-col bg-card space-y-1">
                            {batchSlotData[type + 'Batches']?.map((batch: any) => (
                                <BatchSlot key={`batch_${batch.id}`} type={type} batch={batch} />
                            ))}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex h-full flex-col mt-1">
                            <div className="sticky top-0 z-10">
                                <div className="flex">
                                    <div className="flex space-x-0.5">
                                        {dates.map((date: { day: any; label: any; date: Date }) => (
                                            <DateCard
                                                key={`date_${type}_${date.day}_${date.label}`}
                                                date={date}
                                                type={type}
                                                blankSlots={batchSlotData['extra'][type]}
                                                timeSlotList={batchSlotData[type + 'Slots']}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex h-full overflow-x-auto scrollbar-hide">
                                <div className="flex space-x-0.5">
                                    {allDetails &&
                                        allDetails?.map((detail: any, detailIndex: number) => (
                                            <div
                                                key={`detail_${type}_${detailIndex}`}
                                                className="flex-col space-y-1 p-1.5">
                                                {detail?.[type + 'Lessons']?.['lessons']?.map(
                                                    (lesson: any[], lessonIndex: number) => (
                                                        <div
                                                            key={`lesson_${type}_${lessonIndex}`}
                                                            className="flex space-x-2">
                                                            {lesson.map(
                                                                (slot: any, slotIndex: number) => (
                                                                    <div
                                                                        key={`slot_${type}_${lessonIndex}_${slotIndex}`}
                                                                        className="flex flex-col">
                                                                        {type === 'special' && (
                                                                            <SpecialTimeSLot
                                                                                slot={slot}
                                                                            />
                                                                        )}
                                                                        <SlotCard
                                                                            key={`slotcard_${type}_${lessonIndex}_${slotIndex}`}
                                                                            label={{
                                                                                date: detail.date,
                                                                                time:
                                                                                    type ===
                                                                                    'special'
                                                                                        ? ''
                                                                                        : slot.time,
                                                                                batch_id:
                                                                                    slot.batch_id,
                                                                                batch_code:
                                                                                    slot.batch_code,
                                                                            }}
                                                                            onEdit={handleCardClick}
                                                                            lesson={slot?.lesson}
                                                                            refresh={refresh}
                                                                            type={type}
                                                                            onCreateSchedule={
                                                                                handleCardClick
                                                                            }
                                                                            allowToAddLesson={
                                                                                slot?.allowToAddLesson
                                                                            }
                                                                            isHoliday={
                                                                                slot?.isHoliday
                                                                            }
                                                                            selectedFaculties={
                                                                                selectedFaculties
                                                                            }
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                            {detail?.extra?.[type]?.length > 0 &&
                                                                detail?.extra?.[type]?.map(
                                                                    (
                                                                        extraItem: any,
                                                                        extraIndex: number
                                                                    ) => (
                                                                        // <div
                                                                        //     key={`extra_${type}_${extraIndex}`}
                                                                        //     className={`relative w-16 ${type === 'special' ? 'h-20' : ' h-16'} flex space-x-2 border border-neutral-300 text-primaryColour flex items-center justify-center`}>
                                                                        //     <div></div>
                                                                        // </div>
                                                                        <div
                                                                            key={`extra_${type}_${extraIndex}`}
                                                                            onClick={() =>
                                                                                type ===
                                                                                    'special' &&
                                                                                handleCardClick({
                                                                                    date: detail.date,
                                                                                })
                                                                            }
                                                                            className={`group flex w-16 ${type === 'special' ? 'h-20 hover:bg-primary  cursor-pointer' : ' h-16'} justify-center border border-neutral-300  flex-col text-sm relative items-center `}>
                                                                            {type === 'special' && (
                                                                                <div className="hidden group-hover:flex items-center justify-center h-full">
                                                                                    <Plus
                                                                                        size={28}
                                                                                        className="text-background"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <span className="group-hover:hidden"></span>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }
);
