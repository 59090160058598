import apiService, { fetchById, fetchData, get, QueryParams } from '@/lib/api';
import { CreateOrUpdateBatchData, DeleteItemData, DoubtSessionData } from '@/types';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

export const useGetBatches = (queryParams: QueryParams) => {
    const url = `/getbatchdata`;
    const queryKey: QueryKey = ['batches', queryParams];
    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            return get(url, { params: queryParams }); // Replace with your fetch logic
        },
    };
    const query = useQuery<any, Error>(options);
    return query;
};

export const useFetchBatchTypeData = (params: QueryParams) => {
    return useQuery({
        queryKey: ['batchTypeData', params],
        queryFn: async () => fetchData('getBatchtype', params),
        initialData: () => {
            return [];
        },
    });
};
export const useFetchSlotTimeData = (params: QueryParams) => {
    return useQuery({
        queryKey: ['getSlotTime', params],
        queryFn: async () => fetchData('getSlotTime', params),
        initialData: () => {
            return [];
        },
    });
};
export const useFetchBatchStreamData = (params: QueryParams) => {
    return useQuery({
        queryKey: ['getBatchStream', params],
        queryFn: async () => fetchData('getBatchStream', params),
        initialData: () => {
            return [];
        },
    });
};

export const useFetchBatch = (id: string) => {
    return useQuery({
        queryKey: ['batch', id],
        queryFn: () => fetchById('showBatchById', id),
    });
};
export const useGetBatchDetails = () => {
    return useMutation({
        mutationFn: (id?: any) => fetchById('showBatchById', id),
    });
};

export const useFetchBatchDetails = (id: string) => {
    return useQuery({
        queryKey: ['batchDetails', id],
        queryFn: () => fetchById('getBatchDetails', id),
    });
};
async function createBatch({ postData }: { postData: CreateOrUpdateBatchData }) {
    try {
        const response = await apiService.post(`/createbatch`, postData);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useCreateBatchData = () => {
    // const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (params: CreateOrUpdateBatchData) => createBatch({ postData: params }),
    });
};
async function updateBatch({ id, postData }: { id: string; postData: CreateOrUpdateBatchData }) {
    try {
        const response = await apiService.patch(`/update/${id}`, postData);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const useUpdateBatchData = () => {
    return useMutation({
        mutationFn: async ({ id, postData }: { id: string; postData: CreateOrUpdateBatchData }) =>
            await updateBatch({ id, postData }),
    });
};
export async function deleteBatchItem({ itemToDelete }: { itemToDelete: DeleteItemData }) {
    try {
        const response = await apiService.delete(`/delete/${itemToDelete.id}`);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useDeleteBatchData = () => {
    return useMutation({
        mutationFn: async (params: DeleteItemData) => deleteBatchItem({ itemToDelete: params }),
    });
};
export async function fetchSubjects(queryParams: QueryParams) {
    try {
        const response = await apiService.get(`/getSubject`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useFetchSubjects = (queryParams: QueryParams) => {
    const queryKey: QueryKey = ['subjects', queryParams];
    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: () => fetchSubjects(queryParams),
        initialData: () => {
            return []; // Or use appropriate initial data
        },
    };
    const query = useQuery<any, Error>(options);
    return query;
};

export async function fetchFacultyData({ queryKey }: { queryKey: [string, QueryParams] }) {
    const [_key, { page, sortBy, sortOrder, q, location_id, stream_code }] = queryKey;

    try {
        const response = await apiService.get(`/getfacultydata`, {
            params: {
                q,
                page,
                sortBy,
                sortOrder,
                location_id,
                stream_code,
            },
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const useFetchFacultyData = (params: QueryParams) => {
    return useQuery({
        queryKey: ['facultyData', params],
        queryFn: async () => fetchFacultyData({ queryKey: ['facultyData', params] }),
    });
};

export const useGetAllBatches = (queryParams: QueryParams) => {
    const url = `/getAllBatches`;
    const queryKey: QueryKey = ['all-batches', queryParams];
    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            return get(url, { params: queryParams }); // Replace with your fetch logic
        },
    };
    const query = useQuery<any, Error>(options);
    return query;
};
