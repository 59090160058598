import { useMemo, useRef, useState, useEffect } from 'react';
import * as d3 from 'd3';

const MARGIN = { top: 30, right: 60, bottom: 40, left: 40 };

type LollipopProps = {
    height: number;
    data: any[];
    minBarWidth?: number;
    maxBarWidth?: number;
};

export const Lollipop = ({ height, data, minBarWidth = 25, maxBarWidth = 80 }: LollipopProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [tooltip, setTooltip] = useState<any | null>(null);
    const [scrollOffset, setScrollOffset] = useState(0); // Track horizontal scroll position

    const boundsHeight = height - MARGIN.top - MARGIN.bottom;

    const boundsWidth = containerRef.current
        ? containerRef.current.getBoundingClientRect().width - MARGIN.right - MARGIN.left
        : 1192;

    const dynamicBarWidth = useMemo(() => {
        const calculatedWidth = boundsWidth / data.length;
        return Math.min(Math.max(calculatedWidth, minBarWidth), maxBarWidth);
    }, [boundsWidth, data.length, minBarWidth, maxBarWidth]);

    const adjustedBoundsWidth = data.length * dynamicBarWidth;

    const groups = data.map((d) => d.name);
    const groupsIds = data.map((d) => d.details.faculty_id);
    const xScale = useMemo(() => {
        return d3.scaleBand().domain(groups).range([0, adjustedBoundsWidth]).padding(0.3);
    }, [data, adjustedBoundsWidth]);

    const yScale = useMemo(() => {
        const [, max] = d3.extent(data.map((d) => d.value));
        return d3
            .scaleLinear()
            .domain([0, max + 10])
            .range([boundsHeight, 0]);
    }, [data, boundsHeight]);
    const handleMouseEnter = (d: any, circleX: number, circleY: number) => {
        if (containerRef.current) {
            setTooltip({
                visible: true,
                x: circleX,
                y: circleY,
                data: d,
            });
        }
    };

    const allShapes = data.map((d, i) => {
        const x = xScale(d.name) ?? 0;
        const circleX = x + xScale.bandwidth() / 2;
        const circleY = yScale(d.value);
        return (
            <g key={i}>
                <line
                    x1={circleX}
                    y1={yScale(0)}
                    y2={circleY}
                    x2={circleX}
                    stroke={d.subject_color_code}
                    strokeWidth={3}
                />
                <circle
                    cy={circleY}
                    cx={circleX}
                    stroke={d.subject_color_code}
                    fill={d.subject_color_code}
                    strokeWidth={1}
                    r={6}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={() => handleMouseEnter(d, circleX, circleY)}
                    onMouseLeave={() => setTooltip(null)}
                />
            </g>
        );
    });

    const grid = yScale
        .ticks(5)
        .slice(1)
        .map((value, i) => (
            <g key={i}>
                <line
                    x1={0}
                    x2={adjustedBoundsWidth}
                    y1={yScale(value)}
                    y2={yScale(value)}
                    stroke="#808080"
                    opacity={0.2}
                />
            </g>
        ));

    const yAxisLabels = yScale.ticks(5).map((value, i) => (
        <g key={i}>
            <text
                x={-10}
                y={yScale(value) - 30}
                textAnchor="end"
                alignmentBaseline="central"
                fontSize={9}
                stroke="#808080"
                opacity={1}>
                {value}
            </text>
        </g>
    ));

    // Track horizontal scroll and update tooltip positioning
    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            if (container) {
                setScrollOffset(container.scrollLeft); // Capture the horizontal scroll position
                setTooltip(null); // Hide tooltip when scrolling
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <div className="z-30 bg-background" style={{ position: 'absolute', top: MARGIN.top }}>
                <svg width={MARGIN.left} height={height}>
                    <g transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}>
                        {yAxisLabels}
                    </g>
                </svg>
            </div>

            <div
                ref={containerRef}
                style={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    position: 'relative',
                }}>
                <svg
                    width={adjustedBoundsWidth + 45}
                    height={height}
                    preserveAspectRatio="xMinYMin meet">
                    <g
                        width={adjustedBoundsWidth}
                        height={boundsHeight}
                        transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}>
                        {grid}
                        {allShapes}
                        <g>
                            {groups.map((name, i) => {
                                const x = xScale(name) ?? 0;
                                return (
                                    <a
                                        href={`/faculties/${groupsIds[i]}`}
                                        className="hover:font-bold text-lg"
                                        target="_blank"
                                        rel="noreferrer">
                                        <text
                                            key={i}
                                            x={x + xScale.bandwidth() / 2}
                                            y={boundsHeight + 9}
                                            textAnchor="end"
                                            alignmentBaseline="central"
                                            stroke="#808080"
                                            transform={`rotate(-45, ${x + xScale.bandwidth() / 2}, ${boundsHeight})`}
                                            fontSize={12}>
                                            {name}
                                        </text>
                                    </a>
                                );
                            })}
                        </g>
                    </g>
                </svg>
            </div>

            {/* Tooltip */}
            {tooltip && tooltip.visible && (
                <div
                    className="absolute border border-l-8 shadow-md bg-background w-52 text-sm p-2 z-[100]"
                    style={{
                        borderColor: tooltip.data.subject_color_code,
                        left: (() => {
                            const tooltipWidth = 240; // Width of the tooltip
                            const tooltipX = tooltip.x + MARGIN.left - scrollOffset; // Default position

                            // Calculate maximum left position based on tooltipX
                            const maxLeft = Math.max(0, tooltipX); // Ensure it doesn't go less than 0

                            // Adjust position if the tooltip exceeds the window width
                            if (tooltipX + tooltipWidth > window.innerWidth) {
                                return tooltipX - 130; // Position it near the right edge
                            }

                            return maxLeft; // Default positioning
                        })(),
                        top: tooltip.y + MARGIN.top - 150, // Adjust above the circle
                        pointerEvents: 'none',
                    }}>
                    <div className="font-bold">{tooltip.data.name}</div>
                    <div className="text-xs mb-2">{tooltip.data.full_name}</div>
                    <div className="ml-1 font-bold">
                        <div>
                            {tooltip.data.value}%
                            <span className="text-xs ml-1  font-normal">Utilizations</span>
                        </div>
                        <div>
                            {tooltip.data.details.expected_sessions}
                            <span className="text-xs font-normal ml-1">Expected Sessions</span>
                        </div>
                        <div>
                            {tooltip.data.details.completed_sessions}
                            <span className="text-xs font-normal ml-1">Completed Sessions</span>
                        </div>
                        <div>
                            {tooltip.data.details.leave_count}
                            <span className="text-xs font-normal ml-1">Leaves</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
