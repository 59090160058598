// Function to update password
import apiService from '@/lib/api';
import { useMutation } from '@tanstack/react-query';
async function createPassword({
    postData,
    token,
}: {
    postData: { password: string };
    token: string;
}) {
    try {
        const response = await apiService.post(`/users/passwordUpdate?token=${token}`, postData);
        return response; // Return response for further use
    } catch (error) {
        console.error('Error updating password:', error);
        throw error; // Rethrow error to be handled in the mutation
    }
}
export const useCreatePassword = (token: string) => {
    return useMutation({
        mutationFn: async (password: string) => {
            const postData = { password }; // Prepare data
            return createPassword({ postData, token }); // Call the updatePassword function
        },
    });
};
