import React, { useRef, useState, memo } from 'react';
import * as d3 from 'd3';
import { scale } from '@observablehq/plot';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { X } from 'lucide-react';
import SmallPieChart from '../shared/small-pie-chart';
import { useFetchBatch, useGetBatchDetails } from '@/queries/batch-query';
import { BatchSubjects } from '@/pages/batch/batch-subjects';
import { isColorDark } from '@/common/functions';

const MARGIN = { top: 30, right: 30, bottom: 20, left: 50 };

type Tooltip = {
    visible: boolean;
    value?: { code: string; day: string; value: number };
    x: number;
    y: number;
};

type HeatmapProps = {
    containerWidth?: number;
    height: number;
    data: any[];
    rows: string[];
};

const BatchHeatMap: React.FC<HeatmapProps> = ({ containerWidth = 0, height, data, rows }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [tooltip, setTooltip] = useState<Tooltip | null>(null);

    const faculties = Array.from(new Set(data.map((d) => d.code)));

    // const cellWidth = (containerWidth - MARGIN.left - MARGIN.right) / faculties.length;
    // const cellHeight = (height - MARGIN.top - MARGIN.bottom) / rows.length;
    const cellHeight = 28;
    // Create a color scale for each code using d3.scaleOrdinal
    // Create a color scale for each code using d3.scaleOrdinal
    interface ColorScaleInfo {
        color_code: d3.ScaleSequential<string>;
    }
    // Adjusted codeColorScales to be dependent on both code and day with finer color gradations
    const codeColorScales: { [key: string]: ColorScaleInfo } = faculties.reduce(
        (scales, code) => {
            const colorCode: any = data.filter((d) => d.code === code) || null;
            colorCode.forEach((colorEntry: any) => {
                const day = colorEntry.day; // Assuming day is a property in the colorCode entry
                const key = `${code}_${day}`; // Unique key based on code and day
                const interpolateCustom = d3
                    .scaleLinear<string>()
                    .domain([1, 0.5, 0]) // Assuming values range from 0 to 1
                    .range(['#bf0603', '#ffd500', '#7cb518']); // Define the color range

                const value = d3.scaleSequential<string>(interpolateCustom).domain([0, 100]);
                scales[key] = { color_code: value }; // Use the unique key
            });
            return scales;
        },
        {} as { [key: string]: ColorScaleInfo }
    );

    return (
        <div
            ref={containerRef}
            className="flex flex-row overflow-x-auto"
            style={{ height: `${height}px`, position: 'relative' }}>
            <DaysColumn rows={rows} cellHeight={cellHeight} />
            <div>
                <div className="flex flex-row  sticky top-0 z-30">
                    {faculties.map((code, index) => (
                        <CodeHeader key={index} code={code} cellHeight={cellHeight} />
                    ))}
                </div>
                {rows.map((day, dayIndex) => (
                    <div className="relative" key={day}>
                        <div className="flex flex-row" style={{ height: `${cellHeight}px` }}>
                            {faculties.map((code, codeIndex) => {
                                const value =
                                    data.find((d) => d.code === code && d.day === day)?.value || 0;
                                const hideValue =
                                    data.find((d) => d.code === code && d.day === day)?.hideValue ||
                                    0;
                                const details =
                                    data.find((d) => d.code === code && d.day === day)?.details ||
                                    {};
                                const key = `${code}_${day}`; // Create the key for lookup
                                const scaleInfo = codeColorScales[key] || {};
                                const { color_code } = scaleInfo; // Destructure scale and additionalParam
                                return (
                                    <Cell
                                        key={`${code}-${day}`}
                                        code={code}
                                        value={value}
                                        hideValue={hideValue}
                                        details={details}
                                        color={color_code(value)} // Use code-specific color scale
                                        cellHeight={cellHeight}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Separate component for rendering the days column
const DaysColumn = memo(({ rows, cellHeight }: { rows: string[]; cellHeight: number }) => (
    <div className="flex-col sticky left-0 z-40 bg-background">
        <div className="flex w-auto mr-2 " style={{ height: `${cellHeight}px` }}>
            <div className="flex justify-center items-center bg-background">
                <span className="text-xs"></span>
            </div>
        </div>
        {rows.map((day) => (
            <div key={day} className="flex w-auto  mr-2" style={{ height: `${cellHeight}px` }}>
                <div className="flex justify-center items-center">
                    <span className="text-xs">{day}</span>
                </div>
            </div>
        ))}
    </div>
));

// Separate component for each code header
const CodeHeader = memo(({ code, cellHeight }: { code: string; cellHeight: number }) => {
    const baseWidth = 8.8; // Adjust this value based on your font size and desired spacing
    const cellWidth = `${code.length * baseWidth + 15}px`;

    return (
        <div
            className="flex justify-center items-center border-b-2 border-gray-400  bg-background truncate p-2"
            style={{ width: cellWidth, height: `${cellHeight}px` }}>
            <span className="text-xs">{code}</span>
        </div>
    );
});

// Separate component for each cell in the heatmap
const Cell = memo(
    ({
        code,
        value,
        details,
        hideValue,
        color,
        cellHeight,
    }: {
        code: string;
        value: number;
        details?: any;
        hideValue: boolean;
        color: string;
        cellHeight: number;
    }) => {
        const baseWidth = 8.8; // Adjust this value based on your font size and desired spacing
        const cellWidth = `${code.length * baseWidth + 15}px`;
        const dark = isColorDark(color);
        const [clickedData, setClickedData] = useState<any>();
        const { mutate: fetchBatchProgress, data: batch } = useGetBatchDetails();
        function handleClick() {
            setClickedData(details);
            fetchBatchProgress(details.batch_id);
        }
        const facultyNames = batch?.faculties
            ?.filter((faculty: any) =>
                faculty.subject.some((sub: any) => sub.id === details.subject_id)
            )
            ?.map((faculty: any) => (
                <span key={faculty.faculty_code}>
                    <b>{faculty.faculty_code}</b> - {faculty.first_name} {faculty.last_name}
                </span>
            ));
        return !hideValue ? (
            <Popover>
                <PopoverTrigger asChild>
                    <div
                        className="flex justify-center items-center border   transition duration-200 ease-in-out cursor-pointer p-2"
                        style={{
                            width: cellWidth,
                            height: `${cellHeight}px`,
                            backgroundColor: color,
                            color: dark ? 'white' : 'black',
                        }}
                        onClick={handleClick}
                        aria-label={code}>
                        <span className="text-xs font-semibold">{Math.abs(details.leads)}</span>
                    </div>
                </PopoverTrigger>
                <PopoverContent className="relative w-auto z-50 bg-background shadow-2xl border-2 p-4 flex flex-col">
                    <PopoverClose className="ml-auto text-xs">
                        <X size={15} />
                    </PopoverClose>
                    <div className="flex items-center pb-2  space-x-4  cursor-pointer ">
                        <div className="flex flex-col">
                            <SmallPieChart
                                percent={Number.isNaN(details.percentage) ? 0 : details.percentage}
                                goal={details.goal}
                                color={details.subject_color_code} // Subject color code
                                width={80} // Adjust width as needed
                                height={80} // Adjust height as needed
                            />
                            <div className="flex justify-center items-center">
                                <div>
                                    <span className="font-semibold text-sm items-center flex justify-center  ">
                                        <div
                                            className="w-2 h-2 mr-2"
                                            style={{
                                                backgroundColor:
                                                    details.subject_color_code || 'white',
                                            }}></div>
                                        {details.subject_name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 space-y-1">
                            <a
                                href={`/batches/${details.batch_id}`}
                                className="hover:font-bold text-lg"
                                target="_blank"
                                rel="noreferrer">
                                <span>{details?.batch_code}</span>
                            </a>
                            <p className="text-sm mt-1">
                                {facultyNames && facultyNames.length > 0
                                    ? facultyNames.map((name: any, idx: any) => (
                                          <span className="block" key={idx}>
                                              {name}
                                          </span>
                                      ))
                                    : 'N/A'}
                            </p>
                            <div className="mt-1 text-sm">
                                <b>{details.session_per_weeks}</b> session's per week.
                            </div>
                            <div className="mt-1 text-sm">
                                <b className="mr-2">
                                    {details?.completed_sessions} /&nbsp;
                                    {details?.total_session_of_year}
                                </b>
                                sessions completed.
                            </div>
                            <div className="mt-1 text-sm">
                                <b>{details.expectedSessions}</b> Expected sessions to be completed
                                by this week.
                            </div>
                            <div className="mt-1 text-sm">
                                {' '}
                                {details.leads < 0 ? 'Lagging' : 'Leading'} sessions by
                                <b className="mr-2"> {Math.abs(details.leads)}</b>
                                {details.leads >= 0 && <>&#x1F60A;</>}
                                {details.leads < 0 && <>&#x1F61E;</>}
                            </div>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        ) : (
            <div
                className="flex justify-center items-center border transition duration-200 ease-in-out cursor-pointer p-2"
                style={{ width: cellWidth, height: `${cellHeight}px` }}
                aria-label={code}>
                <span className="text-xs">-</span>
            </div>
        );
    }
);

export default BatchHeatMap;
