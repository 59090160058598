import { CalendarClock, RefreshCw } from 'lucide-react';
import React, { useState, useEffect } from 'react';

interface TimerProps {
    createdAt: string; // assuming createdAt is a string representation of a date
    duration: number; // duration in seconds
}

const Timer: React.FC<TimerProps> = ({ createdAt, duration }) => {
    const [remainingTime, setRemainingTime] = useState<number>(duration);

    useEffect(() => {
        const createdAtDate = new Date(createdAt).getTime();
        const expirationTime = createdAtDate + duration * 1000;

        const updateRemainingTime = () => {
            const now = new Date().getTime();
            const timeLeft = Math.max((expirationTime - now) / 1000, 0);
            setRemainingTime(timeLeft);
        };

        const timer = setInterval(updateRemainingTime, 1000);

        return () => clearInterval(timer);
    }, [createdAt, duration]);

    const formatTime = (timeInSeconds: number): string => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.round(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <>
            {remainingTime !== 0 ? (
                <p>Time remaining: {formatTime(remainingTime)}</p>
            ) : (
                <>
                    <RefreshCw className="mr-2" />
                    Refresh Now
                </>
            )}
        </>
    );
};

export default Timer;
