import { Calendar, momentLocalizer, Event as CalEvent } from 'react-big-calendar';
import moment from 'moment';
import Color from 'color';

import './big-calendar.css';
import BigCalendarToolbar, { BigCalendarToolbarProps } from './big-calendar-toolbar';
import BigCalendarEventView from './big-calender-event-view';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
export interface Event extends CalEvent {
    title: string; // Ensure title is defined
    id?: string;
    color?: string;
    schedule?: any;
    schedules?: any;
    scheduleId?: string; // Add this line to include scheduleId in the Event interface
    hasSchedule?: boolean; // Add this line to include hasSchedule in the Event interface
    hasDoubtSessions?: boolean; // Add this line to include hasSchedule in the Event interface
    isScheduleEditable?: boolean;
    date?: string; // Add this line to include date in the Event interface
    style?: {
        // Add style property
        backgroundColor?: string;
        height?: string;
        width?: string;
    };
    locationName?: string;
    batchId?: string;
    subjectId?: string;
    handleEditSchedule?: (scheduleId: any) => void; // Update to function type
    handleDeleteSchedule?: (scheduleId: any) => void;
    faculty_id?: string; // Update faculty_id to string type
}

const BigCalendar = ({
    events,
    backgroundEvents,
    onDateChange,
    currentDate,
    handleEditSchedule,
    handleDeleteSchedule,
    batchId,
    subjectId,
}: {
    events: Event[];
    backgroundEvents?: Event[];
    onDateChange?: (date: Date) => void;
    currentDate?: Date;
    batchId?: string;
    subjectId?: string;
    handleEditSchedule?: (scheduleId: any) => void; // Optional
    handleDeleteSchedule?: (scheduleId: any) => void; // Optional
}) => {
    const localizer = momentLocalizer(moment);

    const minTime = new Date();
    minTime.setHours(7, 0, 0, 0);
    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week
        },
    });

    const eventPropGetter = (
        event: Event,
        sId?: string,
        bId?: string
    ): { className: string; style: React.CSSProperties } => {
        const color = Color(event.color).lighten(0.95);
        const opacity = (() => {
            if (bId && bId !== event.batchId) return '0.3'; // Batch ID condition
            if (sId && sId !== event.schedule.subject_id) return '0.3'; // Subject ID condition
            return ''; // No opacity change if both match or are undefined
        })();
        return {
            className: 'text-sm',
            style: {
                backgroundColor: color.hex(), //`rgb(from ${event.color} calc(r/.6) calc(g/.6) calc(b/.6))`,
                color: Color(event.color).darken(0.7).hex(), //`rgb(from ${event.color} r g b /1.2)`,
                fontWeight: 'bold',
                borderLeftColor: event.color,
                borderRightColor: event.color,
                borderTopColor: event.color,
                borderBottomColor: event.color,
                borderTopWidth: '1px',
                borderRightWidth: '1px',
                borderBottomWidth: '1px',
                borderLeftWidth: '8px',
                opacity,
            },
        };
    };
    const CustomEventWrapper = ({ props }: { props: any }) => {
        const { event, accessors } = props;
        // Get start and end dates from accessors
        const startDate = accessors.start(event);
        const endDate = accessors.end(event);

        // Format the dates using moment.js
        const formattedStartDate = moment(startDate).format('MMMM D, YYYY h:mm A'); // Example: "September 30, 2024 10:00 AM"
        const formattedEndDate = moment(endDate).format('h:mm A'); // Example: "12:00 PM"

        return (
            <Tooltip>
                <TooltipTrigger asChild>{props.children}</TooltipTrigger>
                <TooltipContent>
                    <div className="flex flex-col space-y-2">
                        <div className="text-muted-foreground">
                            {formattedStartDate} - {formattedEndDate}
                        </div>
                        <div>{event.title}</div> {/* Display the event title */}
                    </div>
                </TooltipContent>
            </Tooltip>
        );
    };
    return (
        <Calendar
            localizer={localizer}
            events={events}
            backgroundEvents={backgroundEvents}
            defaultView="week"
            tooltipAccessor={null}
            startAccessor="start"
            endAccessor="end"
            dayLayoutAlgorithm="no-overlap"
            min={minTime} // start time to 7 AM
            className="p-4"
            style={{
                padding: '4px',
            }}
            formats={{
                dayFormat: (date, culture, lz) => localizer.format(date, 'DD, dddd', culture),
                weekdayFormat: (date, culture, lz) => localizer.format(date, 'dddd', culture),
                timeGutterFormat: (date, culture, lz) => localizer.format(date, 'h A', culture),
                dayRangeHeaderFormat: (range, culture, lz) =>
                    '' +
                    localizer.format(range.start, 'MMMM').toUpperCase() +
                    localizer.format(range.start, ' ( DD').toUpperCase() +
                    ' - ' +
                    localizer.format(range.end, 'DD ) YYYY').toUpperCase(),
            }}
            eventPropGetter={(event) => eventPropGetter(event, subjectId, batchId)}
            step={60}
            timeslots={1}
            popup // Enable popup for events
            showMultiDayTimes // Show times for multi-day events
            views={['month', 'week', 'day', 'agenda']}
            date={currentDate || new Date()} // Use passed date, or default to today's date
            onNavigate={(newDate) => {
                if (onDateChange) {
                    onDateChange(newDate);
                }
            }}
            components={{
                toolbar: (props) => {
                    return <BigCalendarToolbar {...(props as BigCalendarToolbarProps)} />;
                },
                event: (eventProps) => (
                    <BigCalendarEventView
                        {...eventProps}
                        handleEditSchedule={handleEditSchedule}
                        handleDeleteSchedule={handleDeleteSchedule}
                    />
                ), // Pass all props correctly
                eventWrapper: (props) => <CustomEventWrapper props={props} />,
            }}
        />
    );
};

export default BigCalendar;
