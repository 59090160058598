import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { User, get, post } from '@/lib/api';

interface AuthContextState {
    user: User | null;
    token: string | null;
    Login: (email: string, password: string) => Promise<boolean | undefined>;
    Logout: () => void;
}
interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

export default function AuthProvider({ children, ...props }: AuthProviderProps) {
    const [user, setUser] = useState<User | null>(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const [token, setToken] = useState<string | null>(() => {
        const storedToken = localStorage.getItem('token');
        return storedToken ? storedToken : null;
    });

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);
    async function userProfile(_id: string) {
        const url = '/getByIdUser/' + _id;
        const data: any = await get(url);
        setUser(data['user']);
    }
    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
    }, [token]);

    const Login = async (email: string, password: string): Promise<boolean> => {
        try {
            // Perform API call to authenticate user
            const loginResponse: any = await post('/login', { email, password });

            // Assuming loginResponse structure, adjust as per your actual API response
            if (loginResponse && loginResponse.access_token && loginResponse.id) {
                setToken(loginResponse.access_token);
                localStorage.setItem('token', loginResponse.access_token);
                await userProfile(loginResponse.id);
                return true; // Login successful
            } else {
                throw new Error('Invalid login response');
            }
        } catch (error) {
            console.error('Login failed:', error);
            return false; // Login failed
        }
    };

    const Logout = () => {
        setUser(null);
        setToken(null);
        // Optionally, remove user data from local storage or session
    };
    return (
        <AuthContext.Provider value={{ user, token, Login, Logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
