import apiService, { fetchById, get, QueryParams, fetchData } from '@/lib/api';
import { QueryKey, UseQueryOptions, useQuery, useMutation } from '@tanstack/react-query';
import { DeleteItemData, CreateOrUpdateFacultyData } from '@/types';

interface FacultySearchParams {
    q?: string;
    limit?: number;
    page?: number;
    gender?: string;
    sortBy?: string;
    sortOrder?: string;
    location_id?: string | number;
}

export const useGetFaculty = (params: FacultySearchParams) => {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = params.location_id ? `/getfacultydata?${queryParams}` : '';

    const queryKey: QueryKey = ['faculties', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
    };

    const query = useQuery<any, Error>(options);

    return query;
};
interface FacultyScheduleCountParams {
    location_id?: string | number;
    starting_date?: string; // Adjust date type as per your API's requirements
    ending_date?: string; // Adjust date type as per your API's requirements
}

export const useGetFacultyScheduleCount = (params: FacultyScheduleCountParams) => {
    // Construct URL based on params
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url =
        params.starting_date && params.ending_date ? `/getFacultiesCount?${queryParams}` : '';

    const queryKey: QueryKey = ['facultyScheduleCount', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
    };

    const query = useQuery<any, Error>(options);

    return query;
};

export async function fetchFacultyData({ queryKey }: { queryKey: [string, QueryParams] }) {
    const [_key, { page, limit, sortBy, sortOrder, q, location_id, stream_code }] = queryKey;

    try {
        const response = await apiService.get(`/getfacultydata`, {
            params: {
                q,
                limit,
                page,
                sortBy,
                sortOrder,
                location_id,
                stream_code,
            },
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const fetchFacultyDataQueryObj = (params: QueryParams) => {
    return {
        queryKey: ['facultyData', params],
        queryFn: async () => fetchFacultyData({ queryKey: ['facultyData', params] }),
    };
};

export const useFetchFacultyData = (params: QueryParams) => {
    return useQuery(fetchFacultyDataQueryObj(params));
};

export const fetchFacultyQueryObj = (id: string) => {
    return {
        queryKey: ['faculty', id],
        queryFn: () => fetchById('showfacultyById', id),
    };
};
export const useFetchFaculty = (id: string) => {
    return useQuery(fetchFacultyQueryObj(id));
};

// Get Faculty list
export const useGetFaculties = (queryParams: QueryParams) => {
    const url = `/getfacultydata`;
    const queryKey: QueryKey = ['faculties', queryParams];
    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            return get(url, { params: queryParams }); // Replace with your fetch logic
        },
    };
    const query = useQuery<any, Error>(options);
    return query;
};
export const useGetDoubtSessions = (queryParams: QueryParams) => {
    const url = `/getDoubtSessions`;
    const queryKey: QueryKey = ['DoubtSessions', queryParams];
    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            const params: Record<string, any> = {
                ...queryParams,
                // Convert location_ids array to a comma-separated string if present
                location_ids: queryParams.location_ids?.join(','),
            };
            try {
                const response = await get(url, { params: params });

                return response.data; // Make sure to return the data from the response
            } catch (error) {
                console.error('Error fetching doubt sessions:', error);
                throw error; // Ensure errors are thrown for React Query to handle
            }
        },
    };
    const query = useQuery<any, Error>(options);
    return query;
};

// Delete Faculty

export async function deleteFacultyItem({ itemToDelete }: { itemToDelete: DeleteItemData }) {
    try {
        const response = await apiService.delete(`/deletefaculty/${itemToDelete.id}`);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const useDeleteFacultyData = () => {
    return useMutation({
        mutationFn: async (params: DeleteItemData) => deleteFacultyItem({ itemToDelete: params }),
    });
};

async function createFaculty({ postData }: { postData: FormData }) {
    try {
        const response = await apiService.post(`/createfaculty`, postData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useCreateFacultyData = () => {
    return useMutation({
        mutationFn: async (formData: FormData) => createFaculty({ postData: formData }),
    });
};

// fetch subject
export const useFetchSubjectData = (params: QueryParams) => {
    return useQuery({
        queryKey: ['subjectData', params],
        queryFn: async () => fetchData('getSubject', params),
        initialData: () => {
            return [];
        },
    });
};

// fetch batchslot

export const useFetchBatchSlotData = (params: QueryParams) => {
    return useQuery({
        queryKey: ['batchSlotData', params],
        queryFn: async () => fetchData('getBatchslot', params),
        initialData: () => {
            return [];
        },
    });
};

// fetch total_sessions and completed_sessions
export const fetchTotalSessionsOfYear = (id: string) => {
    return {
        queryKey: ['facultyDetails', id],
        queryFn: () => fetchById('getTotalSessionsOfYear', id),
    };
};
export const useFetchTotalSessionsOfYear = (id: string) => {
    return useQuery(fetchTotalSessionsOfYear(id));
};

// fetch fetchScheduleData
export const fetchScheduleDataQuery = (id: string) => {
    return {
        queryKey: ['scheduleData', id],
        queryFn: async () => {
            const res = await apiService.get(`/getSchedule?faculty_id=${id}`);
            return res.data || res;
        },
        initialData: () => [],
    };
};
export const useFetchScheduleData = (id: string) => {
    return useQuery(fetchScheduleDataQuery(id));
};

// Fetch facultyLeaveData
export const useFetchFacultyLeaveData = (id: string) => {
    return useQuery({
        queryKey: ['getLeaveData', id],
        queryFn: () => fetchById('getById', id),
    });
};

async function updateFaculty({
    id,
    postData,
}: {
    id: string;
    postData: CreateOrUpdateFacultyData | FormData;
}) {
    try {
        const headers =
            postData instanceof FormData
                ? { 'Content-Type': 'multipart/form-data' }
                : { 'Content-Type': 'application/json' };

        const response = await apiService.post(`/updatefaculty/${id}?_method=PUT`, postData, {
            headers,
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const useUpdateFacultyData = () => {
    return useMutation({
        mutationFn: async ({
            id,
            postData,
        }: {
            id: string;
            postData: CreateOrUpdateFacultyData | FormData;
        }) => await updateFaculty({ id, postData }),
    });
};
