import { TooltipProvider } from '@/components/ui/tooltip';
import Header from './header';
import SideNav from './sidenav';
import { CommonProvider } from '../../providers/common-provider';

function Layout({ children }: { children: React.ReactNode }) {
    return (
        <TooltipProvider>
            <div className="flex min-h-screen w-full flex-col bg-muted/40">
                <SideNav></SideNav>
                <Header></Header>
                <main className="flex-1 items-start gap-4  sm:pl-16 sm:px-6 sm:py-0 md:gap-8 sm:pr-2">
                    {children}
                </main>
            </div>
        </TooltipProvider>
    );
}
export default Layout;

// import { TooltipProvider } from '@/components/ui/tooltip';
// import Header from './header';
// import SideNav from './sidenav';

// function Layout({ children }: { children: React.ReactNode }) {
//     return (
//         <TooltipProvider>
//             <div className="flex min-h-screen w-full flex-col bg-muted/40">
//                 <SideNav></SideNav>
//                 <Header></Header>
//                 <main className="grid flex-1 items-start gap-2 sm:gap-4  sm:pl-14">{children}</main>
//             </div>
//         </TooltipProvider>
//     );
// }
// export default Layout;
