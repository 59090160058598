import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format, startOfMonth, endOfMonth, addDays } from "date-fns";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useEffect } from "react";

interface DatePickerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  initialDate?: Date | undefined;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date | undefined) => void; // Adjusted to handle single date selection
}

export function DatePicker({
  className,
  initialDate,
  minDate,
  maxDate,
  onChange,
  ...props
}: DatePickerProps) {
  const [date, setDate] = React.useState<Date | undefined>(initialDate);
  const [currentMonth, setCurrentMonth] = React.useState<Date>(new Date());
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const isDateInRange = (date: Date) => {
    if (minDate && date < minDate) return false;
    if (maxDate && date > maxDate) return false;
    return true;
  };

  const handleSubmit = (selectedDate: Date) => {
    // Implement validation or other logic if needed
    console.log("Selected date:", selectedDate);
    onChange(selectedDate); // Call the onSubmit prop with the selected date
    setPopoverOpen(false);
  };

  const handleMonthChange = (newMonth: Date) => {
    setCurrentMonth(newMonth);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={popoverOpen} onOpenChange={(open) => setPopoverOpen(open)}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-full justify-start text-left font-normal border mt-1",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "LLL dd, y") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="single" // Changed to single mode
            selected={date}
            onSelect={(selectedDate) => {
              if (selectedDate && isDateInRange(selectedDate)) {
                setDate(selectedDate);
                handleSubmit(selectedDate);
              }
            }}
            month={currentMonth} // Set the current month view
            onMonthChange={handleMonthChange} // Handle month change from calendar's internal navigation
            weekStartsOn={1} // Set week start to Monday in the calendar
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}