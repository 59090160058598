import React, { useEffect, useState } from 'react';
import {
    fetchFacultyQueryObj,
    fetchTotalSessionsOfYear,
    fetchScheduleDataQuery,
    useFetchFacultyLeaveData,
    useGetDoubtSessions,
} from '@/queries/faculty-query';
import { Edit, MapPin, PhoneCall, LucideInfo, Briefcase, User, MailIcon } from 'lucide-react';
import { useParams, useSearchParams } from 'react-router-dom';
import BigCalendar, { Event } from '@/components/shared/big-calendar/big-calendar';
import RoundedProgressBar from '@/components/shared/RoundedProgressBar';
import { FacultyCalHeapMap } from './faculty-cal-heatmap';
import { useQueries } from '@tanstack/react-query';
import { DoubtSessionData, ScheduleItem } from '@/types';
import { startOfWeek, endOfWeek } from 'date-fns';

import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from '../../components/ui/dialog';
import FacultyCreate from './faculty-create';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useCommonProvider } from '@/providers/common-provider';
import { QueryParams } from '@/lib/api';
interface LeaveRecord {
    id: string;
    faculty_id: string;
    batch_slot_name: string;
    dates: string[];
}

const FacultyScheduleCalendar = ({
    schedules,
    leaveRecords,
    doubtSessionData, // Use consistent naming for props
    batchId,
}: {
    schedules: ScheduleItem[];
    leaveRecords: LeaveRecord[];
    doubtSessionData: DoubtSessionData[]; // Correct name here as well
    batchId: string;
}) => {
    const [eventData, setEventData] = useState<{ events: Event[]; backgroundEvents?: Event[] }>({
        events: [],
    });
    const [searchParams, setSearchParams] = useSearchParams();

    const date = searchParams.get('date');

    function handleDateChange(d: Date) {
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            date: moment(d).format('YYYY-MM-DD'),
        });
    }

    useEffect(() => {
        // Format schedule events
        const calEvents: Event[] = Array.isArray(schedules)
            ? schedules.map(
                  (item): Event => ({
                      title: `${item.batch.batch_code}`,
                      start: new Date(`${item.date}T${item.slot_time.split('-')[0]}`),
                      end: new Date(`${item.date}T${item.slot_time.split('-')[1]}`),
                      batchId: `${item.batch.id}`,
                      color: item.subject?.subject_color_code || '#dfdfdf',
                  })
              )
            : [];
        const leaveEvents: Event[] = Array.isArray(leaveRecords)
            ? leaveRecords.flatMap((leave): Event[] => {
                  const events: Event[] = [];

                  // Loop through each day in the leave range
                  for (
                      let currentDate = new Date(leave.dates[0]);
                      currentDate <= new Date(leave.dates[leave.dates.length - 1]);
                      currentDate.setDate(currentDate.getDate() + 1)
                  ) {
                      let startDate: Date, endDate: Date;

                      const formattedDate = currentDate.toISOString().split('T')[0]; // Format date

                      // If the leave is for the Morning slot
                      if (leave.batch_slot_name === 'Morning') {
                          startDate = new Date(`${formattedDate}T07:00:00`);
                          endDate = new Date(`${formattedDate}T11:59:59`);
                      }
                      // If the leave is for the Afternoon slot
                      else if (leave.batch_slot_name === 'Afternoon') {
                          startDate = new Date(`${formattedDate}T12:00:00`);
                          endDate = new Date(`${formattedDate}T23:59:59`);
                      }
                      // Full-day leave
                      else {
                          startDate = new Date(`${formattedDate}T00:00:00`);
                          endDate = new Date(`${formattedDate}T23:59:59`);
                      }

                      // Create an event for each day within the time range
                      events.push({
                          id: `${leave.id}-${formattedDate}`,
                          title: `${leave.batch_slot_name} Leave`, // Morning or Afternoon Leave
                          start: startDate,
                          end: endDate,
                          color: 'gray',
                      });
                  }

                  return events;
              })
            : [];

        const sessionCountMap: { [key: string]: number } = {};

        const doubtSessionEvents: Event[] = Array.isArray(doubtSessionData)
            ? doubtSessionData.map((session): Event => {
                  const start = new Date(`${session.date}T${session.start_time}`);
                  const end = new Date(`${session.date}T${session.end_time}`);
                  const eventDate = start.toDateString();
                  // Increment the session count and create the title
                  sessionCountMap[eventDate] = (sessionCountMap[eventDate] || 0) + 1;
                  const durationInMinutes = (end.getTime() - start.getTime()) / (1000 * 60);
                  const hasDoubtSessions = true;
                  const defaultColor = 'lightgray';
                  const locationName = session.location?.name || 'Unknown Location';

                  return {
                      start,
                      end,
                      color: hasDoubtSessions
                          ? defaultColor // If `hasDoubtSessions` is true, use defaultColor
                          : session.faculty?.color_code || defaultColor,

                      title: `\nDoubt Session`, // Empty title if duration < 60
                      hasDoubtSessions,
                      locationName,
                  };
              })
            : [];

        // Set both schedule and leave events
        setEventData({
            events: [...calEvents, ...doubtSessionEvents],
            backgroundEvents: leaveEvents,
        });
    }, [schedules, leaveRecords, doubtSessionData]);

    return (
        <>
            <BigCalendar
                {...eventData}
                currentDate={date ? new Date(date) : new Date()}
                onDateChange={handleDateChange}
                batchId={batchId}
            />
        </>
    );
};
const FacultyDetailsPage: React.FC = () => {
    const { id } = useParams(); // Get the faculty ID from the URL
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [batchId, setBatchId] = useState<any>('');

    const [
        { data: faculty, error, isLoading },
        { data: totalSessionData },
        { data: scheduleData },
    ] = useQueries({
        queries: [
            fetchFacultyQueryObj(id || ''),
            fetchTotalSessionsOfYear(id || ''),
            fetchScheduleDataQuery(id || ''),
        ],
    });
    // Grouping session data by location_id
    const groupedSessions = totalSessionData?.reduce((groups: any, session: any) => {
        session?.location_names?.forEach((location: any) => {
            const locationId = location.location_id;
            if (!groups[locationId]) {
                groups[locationId] = {
                    location_name: location.location_name,
                    sessions: [],
                };
            }
            groups[locationId].sessions.push(session);
        });
        return groups;
    }, {});
    const { selectedDate, setSelectedDate } = useCommonProvider();
    const [searchParams] = useSearchParams();
    const dateParam = searchParams.get('date');
    const [monthFromDate, setMonthFromDate] = useState(new Date());
    const [monthToDate, setMonthToDate] = useState(new Date());
    // Set fromDate to 30 days ago
    useEffect(() => {
        const currentDate = new Date(); // Current date
        setMonthToDate(currentDate); // Set monthToDate to current date

        // Create a new date object for monthFromDate and set it to 30 days ago
        const fromDate = new Date(currentDate);
        fromDate.setDate(fromDate.getDate() - 31); // 30 days ago
        setMonthFromDate(fromDate);
    }, []);

    const monthFromDateFormatted = monthFromDate.toISOString().split('T')[0];
    const monthToDateFormatted = monthToDate.toISOString().split('T')[0];

    useEffect(() => {
        if (dateParam) {
            const startDate = startOfWeek(dateParam, { weekStartsOn: 1 });
            const endDate = endOfWeek(dateParam, { weekStartsOn: 1 });
            setSelectedDate({
                from: startDate,
                to: endDate,
            });
        } else {
            const currentDate = new Date();
            setSelectedDate({
                from: startOfWeek(currentDate, { weekStartsOn: 1 }),
                to: endOfWeek(currentDate, { weekStartsOn: 1 }),
            });
        }
    }, [dateParam, setSelectedDate]);

    const startingDate = moment(selectedDate?.from).format('YYYY-MM-DD');
    const endingDate = moment(selectedDate?.to).format('YYYY-MM-DD');
    const params: QueryParams = {
        starting_date: startingDate,
        ending_date: endingDate,
        faculty_id: faculty?.id, // Optional, can be an empty string if not used
    };
    const { data: doubtSessionData } = useGetDoubtSessions(params);

    const { data: leaveData } = useFetchFacultyLeaveData(id || '');

    if (!id) return <>Loading...</>;

    if (isLoading) return <>Loading...</>;

    if (error) return <>ERROR: loading faculty details.</>;
    //const faculty = facultyQuery.data;

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        // Refetch faculty details or update state if needed
    };
    console.log('total====', totalSessionData);

    return (
        <div className="flex gap-2">
            <div className="w-full md:w-1/3 lg:w-1/4 scrollbar-hide  h-[calc(100vh-6rem)] p-4 bg-secondary/10 flex flex-col border ">
                <div className="top-4 ml-auto z-10">
                    {/* Edit button to trigger dialog */}
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                        <DialogTrigger asChild>
                            <div>
                                <Edit
                                    className="p-1 hover:text-primary hover:scale-100 transition-transform duration-200 cursor-pointer"
                                    onClick={() => setIsDialogOpen(true)}
                                />
                            </div>
                        </DialogTrigger>

                        <DialogContent className="max-w-xl max-h-screen p-5">
                            <DialogHeader>
                                <DialogTitle>Edit Faculty Details</DialogTitle>
                                <DialogDescription>
                                    Update the details for the selected faculty member.
                                </DialogDescription>
                            </DialogHeader>
                            <div className="bg-muted/40  max-h-[80vh] overflow-auto p-2">
                                <FacultyCreate
                                    faculty={faculty} // Pass faculty data here
                                    onClose={handleDialogClose}
                                />
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                {/* Profile Section */}
                <div className="relative w-full mb-0 flex items-center space-x-4">
                    {/* Profile Picture */}
                    <div className="w-16 h-16">
                        {' '}
                        <img
                            src={faculty?.image_url ? faculty.image_url : '/images/profile.jpeg'}
                            alt="Profile"
                            className="w-full h-full rounded object-cover"
                        />
                    </div>

                    {/* Faculty Info */}
                    <div className=" inset-y-0 flex flex-col justify-center items-start p-2">
                        <div className="text-base font-bold  truncate">
                            <span>
                                {faculty?.first_name} {faculty?.last_name}
                            </span>
                        </div>
                        <div className="text-xs sm:text-sm  mt-1 truncate">
                            <span>{faculty?.faculty_code}</span>
                        </div>
                        <div className="text-xs sm:text-sm  mt-1 flex items-center">
                            <MailIcon size="16" className="text-primary mr-2" />

                            <span className="text-blue-700">{faculty?.mail}</span>
                        </div>
                    </div>
                </div>
                <hr className="relative w-full bg-gray-200 h-[0.2vh] my-4 border-0" />
                <div className="flex items-center justify-center">
                    <div className="flex items-center space-x-2">
                        <PhoneCall size="16" className="text-primary" />
                        <span>{faculty?.phone}</span>
                    </div>
                    <div className="flex items-center  ml-auto mr-4 space-x-2">
                        {
                            <span className="flex flex-col space-y-1">
                                {faculty?.subject.map((subj: any, index: any) => (
                                    <span key={index} className="flex flex-wrap items-center mr-4">
                                        <span
                                            className="w-4 h-4"
                                            style={{
                                                backgroundColor:
                                                    subj?.subject_color_code || 'white',
                                            }}></span>
                                        <span className="ml-2">{subj?.subject_name}</span>
                                    </span>
                                ))}
                                <span className="flex items-center  text-xs sm:text-sm ">
                                    <span
                                        className="h-4 w-4  mr-2"
                                        style={{ backgroundColor: ' lightgray' }}></span>
                                    Doubt Sessions
                                </span>
                            </span>
                        }
                    </div>
                </div>
                <hr className="relative w-full bg-gray-200 my-4 h-[0.2vh] border-0" />
                <div className=" overflow-auto  h-[calc(100vh-20rem)] ">
                    <FacultyCalHeapMap
                        schedules={scheduleData}
                        monthFromDate={monthFromDateFormatted}
                        monthToDate={monthToDateFormatted}
                        faculty_id={faculty?.id}></FacultyCalHeapMap>
                    <hr className="relative w-full bg-gray-200 mb-6  h-[0.2vh] border-0" />
                    <div className="p-2">
                        {groupedSessions ? (
                            Object.keys(groupedSessions).map((locationId, index) => (
                                <div key={locationId} className="mb-4">
                                    {/* Conditional HR Line for multiple locations */}
                                    {Object.keys(groupedSessions).length > 1 && index > 0 && (
                                        <hr className="relative w-full bg-gray-200 mb-6 h-[0.2vh] border-0" />
                                    )}
                                    {/* Location Name */}
                                    <div className="flex items-center space-x-1">
                                        <MapPin size="16" className="text-primary" />
                                        <span className="text-sm font-bold">
                                            {groupedSessions[locationId].location_name}
                                        </span>
                                    </div>

                                    {/* Sessions under this location */}
                                    {groupedSessions[locationId].sessions.map(
                                        (item: {
                                            batch_id: string;
                                            batch_code: string;
                                            total_sessions: number;
                                            completed_sessions: number;
                                            start_date: string;
                                            sessions_per_week: number;
                                        }) => {
                                            const weeks =
                                                moment
                                                    .duration(
                                                        moment().diff(moment(item.start_date))
                                                    )
                                                    .asDays() / 7;
                                            const leads = Math.floor(
                                                item.completed_sessions -
                                                    weeks * item.sessions_per_week
                                            );

                                            return (
                                                <div
                                                    key={item.batch_id}
                                                    onMouseEnter={() => setBatchId(item.batch_id)}
                                                    onMouseLeave={() => setBatchId(null)}
                                                    className="flex items-center bg-gray p-2 cursor-pointer hover:bg-muted">
                                                    <div className="flex-1">
                                                        <h2 className="text-xs text-left">
                                                            {item.batch_code}
                                                        </h2>
                                                    </div>
                                                    <div className="flex-1">
                                                        <RoundedProgressBar
                                                            totalSessions={item.total_sessions}
                                                            completedSessions={
                                                                item.completed_sessions
                                                            }
                                                            sessionsLead={leads}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            ))
                        ) : (
                            <div>No session data available</div>
                        )}
                    </div>
                    <hr className="relative w-full bg-gray-200 mb-4 h-[0.2vh] border-0" />
                    <div>
                        <div className="flex items-center text-base font-bold mb-0 space-x-2">
                            <LucideInfo size="16" className="text-primary" />
                            <span>Personal Information</span>
                        </div>
                        <div className="p-4">
                            <div className="flex items-center space-x-1">
                                <User size="16" className="text-primary" />

                                <span>
                                    {faculty.gender.charAt(0).toUpperCase() +
                                        faculty.gender.slice(1)}
                                </span>
                            </div>
                            {faculty?.age ? (
                                <div className="flex items-center space-x-1 mt-2">
                                    <User size="16" className="text-primary" />
                                    <span>{`${faculty.age} Yrs Old`}</span>
                                </div>
                            ) : null}
                            {faculty?.experience ? (
                                <div className="flex items-center space-x-1 mt-2">
                                    <Briefcase size="16" className="text-primary" />
                                    <span>{`${faculty.experience} Yrs Experience`}</span>
                                </div>
                            ) : null}
                            {faculty?.address ? (
                                <div className="flex items-center space-x-1 mt-2">
                                    <MapPin size="16" className="text-primary" />
                                    <span>{faculty.address}</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col md:w-2/3 lg:w-5/6 overflow-auto p-4 bg-background relative border">
                <div className="h-[calc(100vh-8.1rem)] ">
                    <FacultyScheduleCalendar
                        schedules={scheduleData}
                        leaveRecords={leaveData?.leave_records}
                        doubtSessionData={doubtSessionData}
                        batchId={batchId}
                    />{' '}
                </div>
            </div>
        </div>
    );
};

export default FacultyDetailsPage;
