import { get } from '@/lib/api';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

export const useGetSlotTimes = (url: string) => {
    const queryKey: QueryKey = ['slotTimes', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
    };

    const query = useQuery<any, Error>(options);

    return query;
};
