'use client';

import React, { useEffect, useState } from 'react';
import { CalendarIcon } from '@radix-ui/react-icons';
import {
    addDays,
    format,
    addWeeks,
    subWeeks,
    startOfMonth,
    endOfMonth,
    endOfWeek,
    startOfWeek,
} from 'date-fns';
import { DateRange } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import moment from 'moment';

interface DatePickerWithRangeProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
    numberOfMonths?: number;
    minDate?: Date;
    maxDate?: Date;
    initialDateRange?: DateRange;
    onSubmit: (dateRange: DateRange | undefined) => void; // Added prop for handling submission
    width?: string; // Add width prop
    border?: string;
    borderRedius?: string;
}

export function DatePickerWithRange({
    className,
    width = 'w-[230px]', // default width if none provided
    border = '',
    borderRedius = '',
    numberOfMonths = 2,
    minDate,
    maxDate,
    initialDateRange,
    onSubmit,
    ...props
}: DatePickerWithRangeProps) {
    const [date, setDate] = React.useState<DateRange | undefined>(initialDateRange);
    const [selectedDate, setSelectedDate] = React.useState<DateRange | undefined>(initialDateRange);
    const [currentWeek, setCurrentWeek] = React.useState<Date>(new Date());
    useEffect(() => {
        if (date?.from) {
            setCurrentWeek(date.from);
        }
    }, [date]);
    const [currentMonth, setCurrentMonth] = React.useState<Date>(new Date());
    const [popoverOpen, setPopoverOpen] = React.useState(false);

    const isDateInRange = (d: Date) => {
        const dateToCheck = moment(d).startOf('day');
        const min = minDate ? moment(minDate).startOf('day') : null;
        const max = maxDate ? moment(maxDate).startOf('day') : null;
        if (min && dateToCheck.isBefore(min)) return false;
        if (max && dateToCheck.isAfter(max)) return false;
        return true;
    };
    React.useEffect(() => {
        setDate(initialDateRange);
        setSelectedDate(initialDateRange);
        if (initialDateRange && initialDateRange['from']) setCurrentMonth(initialDateRange['from']);
    }, [initialDateRange]);

    const setToLastWeek = () => {
        const newWeek = subWeeks(currentWeek, 1);
        const from = isDateInRange(startOfWeek(newWeek, { weekStartsOn: 1 }))
            ? startOfWeek(newWeek, { weekStartsOn: 1 })
            : new Date();
        const to = endOfWeek(from, { weekStartsOn: 1 });
        if (isDateInRange(from) && isDateInRange(to)) {
            setCurrentWeek(newWeek);
            setDate({ from, to });
            setCurrentMonth(from);
        }
    };

    const setToNextWeek = () => {
        const newWeek = addWeeks(currentWeek, 1);
        const from = isDateInRange(startOfWeek(newWeek, { weekStartsOn: 1 }))
            ? startOfWeek(newWeek, { weekStartsOn: 1 })
            : new Date();
        const to = endOfWeek(from, { weekStartsOn: 1 });
        if (isDateInRange(from) && isDateInRange(to)) {
            setCurrentWeek(newWeek);
            setDate({ from, to });
            setCurrentMonth(from);
        }
    };
    const handleCancel = () => {
        setDate(initialDateRange);
        setPopoverOpen(false);
    };

    const handleSubmit = () => {
        setSelectedDate(date);
        // Implement the submit functionality as needed
        console.log('Selected range:', date);
        onSubmit(date); // Call the onSubmit prop with the selected date range
        setPopoverOpen(false);
    };

    const handleMonthChange = (newMonth: Date) => {
        setCurrentMonth(newMonth);
    };

    const setToCurrentWeek = () => {
        const newWeek = new Date();
        setCurrentWeek(newWeek);
        const from = isDateInRange(startOfWeek(newWeek, { weekStartsOn: 1 }))
            ? startOfWeek(newWeek, { weekStartsOn: 1 })
            : new Date();
        const to = endOfWeek(from, { weekStartsOn: 1 });
        setDate({ from, to });
        setCurrentMonth(from);
    };

    const setToCurrentMonth = () => {
        const from = startOfMonth(new Date());
        const to = endOfMonth(new Date());
        setDate({ from, to });
        setCurrentMonth(from);
    };

    const setToNextMonth = () => {
        const from = startOfMonth(addDays(endOfMonth(new Date()), 1));
        const to = endOfMonth(from);
        setDate({ from, to });
        setCurrentMonth(from);
    };

    return (
        <div className={cn('grid gap-2', className)}>
            <Popover open={popoverOpen} onOpenChange={(open) => setPopoverOpen(open)}>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={'outline'}
                        className={cn(
                            `${width} ${border} ${borderRedius} justify-start text-left font-normal ',
                            !date && 'text-muted-foreground`
                        )}>
                        <span>
                            <CalendarIcon className="mr-2 h-4 w-4" />
                        </span>
                        {date?.from ? (
                            date.to ? (
                                <>
                                    {format(date.from, 'LLL dd, y')} -{' '}
                                    {format(date.to, 'LLL dd, y')}
                                </>
                            ) : (
                                format(date.from, 'LLL dd, y')
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        selected={date}
                        fromDate={minDate}
                        onSelect={(range) => {
                            console.log(range);
                            setDate(range);
                        }}
                        numberOfMonths={numberOfMonths}
                        month={currentMonth} // Set the current month view
                        onMonthChange={handleMonthChange} // Handle month change from calendar's internal navigation
                        weekStartsOn={1} // Set week start to Monday in the calendar
                    />
                    <div className="flex gap-2 justify-center p-2">
                        <Button variant="outline" size="icon" onClick={setToLastWeek}>
                            {'<'}
                        </Button>
                        <Button variant="outline" onClick={setToCurrentWeek}>
                            Current Week
                        </Button>
                        <Button variant="outline" size="icon" onClick={setToNextWeek}>
                            {'>'}
                        </Button>
                    </div>
                    <div className="flex gap-2 justify-between p-4">
                        <Button variant="outline" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="default" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
}
