import apiService, { get } from '@/lib/api';
import { QueryKey, UseQueryOptions, useQuery, useMutation } from '@tanstack/react-query';

export const useGetLocationsWithCounts = () => {
    // Construct URL based on params
    const url = `/getScheduleCountByLocation`;

    const queryKey: QueryKey = ['getLocationsWithCounts', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            const result = await get(url); // Replace with your fetch logic
            return result;
        },
    };
    const query = useQuery<any, Error>(options);

    return query;
};

// API function for fetching faculty weekly schedule
export async function getFacultyWeeklySchedule(params?: any) {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = `/getFacultyWeeklyScheduleCount?${queryParams}`;

    try {
        const response = await apiService.get(url); // Replace with your fetch logic
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useGetFacultyWeeklySchedule = () => {
    return useMutation({
        mutationFn: (params?: any) => getFacultyWeeklySchedule(params),
    });
};

// -------------------------------------------------------
export async function FacultyUtilization(params?: any) {
    try {
        const queryParams = new URLSearchParams(params as Record<string, string>).toString();
        const url = `/calculateFacultyProgress?${queryParams}`;
        const response = await apiService.get(url); // Replace with your fetch logic

        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const useGetFacultyUtilization = () => {
    return useMutation({
        mutationFn: (params?: any) => FacultyUtilization(params),
    });
};

//----------------------------------------------------------------------
export async function getBatchProgress(params?: any) {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = `/getBatchProgress?${queryParams}`;

    try {
        const response = await apiService.get(url); // Replace with your fetch logic
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useGetBatchProgress = () => {
    return useMutation({
        mutationFn: (params?: any) => getBatchProgress(params),
    });
};
