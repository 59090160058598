import { GetLocationList } from '@/common/functions';
import CustomCalendar from '@/components/shared/custom-calendar/custom-calendar';
import { useCommonProvider } from '@/providers/common-provider';
import { useGetLeaves } from '@/queries/leave-query';
import { Check } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { LeaveForm } from './leave-form';
import { useFetchFacultyData } from '@/queries/faculty-query';
import { useToast } from '@/components/ui/use-toast';
import { useGetAllBatches } from '@/queries/batch-query';
import { useGetHolidays } from '@/queries/holiday-query';
import { Description } from '@radix-ui/react-dialog';

interface CarouselProfileCardProps {
    item: any;
    selectedLocations: string[];
    setSelectedLocations: (data: any) => void;
}
const CarouselProfileCard: React.FC<CarouselProfileCardProps> = ({
    item,
    selectedLocations,
    setSelectedLocations,
}) => {
    const toggleSelection = (id: string) => {
        setSelectedLocations((prev: string[]) =>
            prev.includes(id)
                ? prev.filter((locationId: string) => locationId !== id)
                : [...prev, id]
        );
    };

    const isSelected = (id: string) => selectedLocations.includes(id);

    return (
        <div className="pt-1 basis-0 ">
            <div className="p-1">
                <div
                    key={item.id}
                    className={`p-3 rounded  cursor-pointer transition-all duration-300`}
                    onClick={() => toggleSelection(item.id)}>
                    <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">{item.name}</h3>
                        <div
                            className={`w-6 h-6 border-2 flex justify-center items-center ${
                                isSelected(item.id) ? 'border-primary' : 'border-gray-300'
                            }`}>
                            {isSelected(item.id) && <Check className="text-primary w-4 h-4" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
interface Event {
    title: string;
    records: { name: string; type: string; time: string; id: string }[];
    start: Date;
    end: Date;
    type: string;
}
const transformLeaves = (leaves: any): Event[] => {
    const flattenedLeaves = leaves?.flatMap((leave: any) =>
        leave.dates.split(',').map((date: string) => ({
            ...leave,
            dateArray: leave.dates,
            dates: date.trim(),
        }))
    );

    const groupedByDates = _.groupBy(flattenedLeaves, 'dates');
    const sortedGroupedByDates = _.mapValues(groupedByDates, (group) =>
        _.orderBy(group, 'batch_slot_name', ['desc'])
    );
    const events: Event[] = [];

    _.forEach(sortedGroupedByDates, (records, date) => {
        const addOrUpdateEvent = (
            title: string,
            slotRecords: any[],
            timeRange: { start: string; end: string }
        ) => {
            const eventRecords = slotRecords.map((record) => ({
                name: `${record.faculty.faculty_code}`,
                color: record.faculty?.subject?.[0]?.subject_color_code || 'white',
                type: 'leave',
                time: record.batch_slot_name === 'FULLDAY' ? '' : record.batch_slot_name,
                id: record.id,
                data: record,
            }));

            events.push({
                title,
                records: eventRecords,
                start: new Date(`${date} ${timeRange.start}`),
                end: new Date(`${date} ${timeRange.end}`),
                type: 'Leave',
            });
        };
        addOrUpdateEvent('Full Day', records, { start: '07:00:00', end: '22:00:00' });
    });

    return events;
};
const transformHolidays = (holidays: any) => {
    const events = _.flatMap(holidays, (holiday) => {
        const description: any = [];
        if (holiday.locations?.length > 0) {
            description.push(holiday.locations?.map((item: any) => item.name).join(', '));
        }
        if (holiday.batch_slots?.length > 0) {
            description.push(holiday.batch_slots?.map((item: any) => item.name).join(', '));
        }
        if (holiday.batch_types?.length > 0) {
            description.push(holiday.batch_types?.map((item: any) => item.name).join(', '));
        }
        if (holiday.batches?.length > 0) {
            description.push(holiday.batches?.map((item: any) => item.batch_code).join(', '));
        }
        holiday.description = description;
        return _.map(holiday.dates, (date) => ({
            title: holiday.name,
            data: {
                records: holiday || [], // Adjust according to your records structure
            },
            start: new Date(`${date} 00:00:00`), // Full-day event starts at midnight
            end: new Date(`${date} 23:59:59`), // Full-day event ends at 23:59:59
            type: 'Holiday',
        }));
    });

    // Group events by date and merge data
    const mergedEvents = _.map(
        _.groupBy(events, (event) => event.start.toDateString()),
        (groupedEvents) => {
            return {
                title: 'Holiday', // Keep the title from the first event
                start: groupedEvents[0].start, // Start time from the first event
                end: groupedEvents[0].end, // End time from the first event
                type: 'Holiday',
                records: _.flatMap(groupedEvents, 'data.records'), // Merge records from all events
            };
        }
    );

    return mergedEvents;
};

function LeaveCalendar() {
    const { selectedLocation, locationList } = useCommonProvider();
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [events, setEvents] = useState<any[]>([]);
    const [backgroundEvents, setBackgroundEvents] = useState<any[]>([]);
    const [facultyList, setFacultyList] = useState<any>([]);
    const [batchList, setBatchList] = useState<any>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [addDetails, setAddDetails] = useState<any>([]);
    const [editData, setEditData] = useState<any>();
    const { toast } = useToast();
    const date = searchParams.get('date');

    const params = {
        page: 1,
        sortBy: 'first_name',
        sortOrder: 'DESC',
        q: '',
    };
    const { data: faculties } = useFetchFacultyData(params);
    const { data: batches } = useGetAllBatches({
        sortBy: 'id',
        sortOrder: 'ASC',
    });
    useEffect(() => {
        if (batches) {
            const dataByLocationId = _.groupBy(batches.data, (batch) => {
                return batch.locations.length > 0 ? batch.locations[0].name : 'Unknown Location';
            });
            // Step 2: Flatten the grouped data and create options array
            const flattenedOptions = Object.values(dataByLocationId)
                .flat()
                .map((item, index) => ({
                    value: item.id,
                    label: item?.batch_code,
                    group:
                        item?.locations.length > 0 ? item?.locations[0].name : 'Unknown Location',
                }));

            setBatchList(flattenedOptions);
        }
    }, [batches]);
    useEffect(() => {
        const groupedByLocationId = _.groupBy(faculties, (faculty) => {
            return faculty.location?.length > 0 ? faculty.location[0].name : 'Unknown Location';
        });
        // Step 2: Flatten the grouped data and create options array
        const flattenedOptions = Object.values(groupedByLocationId)
            .flat()
            .map((item, index) => ({
                value: item.id,
                label:
                    item?.faculty_code +
                    '- ' +
                    item?.first_name +
                    ' ' +
                    item?.last_name +
                    ' ( ' +
                    item?.subject?.map((subject: any) => subject.subject_code).join(', ') +
                    ' ) | ' +
                    item?.location?.map((location: any) => location.name).join(', '),
                group: item?.location.length > 0 ? item?.location[0].name : 'Unknown Location',
            }));

        setFacultyList(flattenedOptions);
    }, [faculties]);
    useEffect(() => {
        setSelectedLocations(locationList.map((location: any) => location.id));
    }, [locationList]);
    function handleDateChange(d: Date) {
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            date: moment(d).format('YYYY-MM-DD'),
        });
    }

    const { data: leaveData, refetch: refetchLeaveData } = useGetLeaves(
        selectedLocations.join(',') || ''
    );
    const { data: holidayList = [], refetch: refetchHolidayList } = useGetHolidays(
        selectedLocations.join(',') || ''
    );

    useEffect(() => {
        refetchLeaveData();
        refetchHolidayList();
    }, [selectedLocations]);
    useEffect(() => {
        if (leaveData) {
            setEvents(transformLeaves(leaveData));
        }
    }, [leaveData]);
    useEffect(() => {
        if (holidayList) {
            setBackgroundEvents(transformHolidays(holidayList));
        }
    }, [holidayList]);
    function handleAddEvent(value: Date[]) {
        setEditData(null);
        setAddDetails({ dateRange: { from: new Date(value[0]), to: new Date(value[1]) } });
        setIsDialogOpen(true);
    }
    function handleEditEvent(record: any) {
        // setLeaveDeleteId(records.id);
        setEditData(record);
        setIsDialogOpen(true);
    }
    function refresh() {
        setEditData(null);
        setIsDialogOpen(false);
        refetchLeaveData();
        refetchHolidayList();
    }
    return (
        <div className="flex flex-col md:flex-row gap-2">
            {/* Sidebar (Left section) */}
            <div className="w-full md:w-1/3 lg:w-1/5 h-[calc(100vh-6rem)] p-4 bg-background border overflow-auto">
                {/* Sidebar content */}
                <GetLocationList />
                <div className="flex justify-between items-center mt-4">
                    <label className="text-base font-medium text-center leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mb-1 ml-1">
                        Locations
                    </label>
                    <div className="ml-1 flex justify-center">
                        {selectedLocations.length === locationList.length ? (
                            <button
                                className="px-2 py-1  rounded"
                                onClick={() => setSelectedLocations([])}>
                                Deselect All
                            </button>
                        ) : (
                            <button
                                className="px-2 py-1 rounded"
                                onClick={() =>
                                    setSelectedLocations(locationList.map((loc: any) => loc.id))
                                }>
                                Select All
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex flex-col overflow-auto justify-center rounded  my-2 flex-grow ml-1  scrollbar-hide">
                    {locationList?.map((item: any) => (
                        <CarouselProfileCard
                            setSelectedLocations={setSelectedLocations}
                            selectedLocations={selectedLocations}
                            item={item}
                            key={item.id}
                        />
                    ))}
                </div>
            </div>
            <LeaveForm
                isOpen={isDialogOpen}
                defaultFormValues={addDetails}
                facultyList={facultyList}
                batchList={batchList}
                locationList={locationList}
                editData={editData}
                onClose={refresh}
            />
            {/* Main Content (Right section) */}
            {selectedLocations && selectedLocations?.length > 0 && (
                <div className="w-full md:w-2/3 lg:w-4/5 h-[calc(100vh-6rem)] overflow-auto p-4 bg-background">
                    {/* Main content */}
                    <CustomCalendar
                        events={events}
                        backgroundEvents={backgroundEvents}
                        currentDate={date ? new Date(date) : new Date()}
                        onDateChange={handleDateChange}
                        onAddEvent={handleAddEvent}
                        onEditEvent={handleEditEvent} // Ensure this handler is passed
                    />
                </div>
            )}
        </div>
    );
}

export default LeaveCalendar;
