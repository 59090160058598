import apiService from '@/lib/api';
import { ForgotPassword } from '@/types';
import { useMutation } from '@tanstack/react-query';
async function forgotPassword({ postData }: { postData: ForgotPassword }) {
    try {
        const response = await apiService.post(
            `/password/forgot`, // Removed extra }
            postData
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useForgotPassword = () => {
    return useMutation({
        mutationFn: async (params: ForgotPassword) => forgotPassword({ postData: params }),
    });
};
