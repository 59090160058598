import { endOfWeek, startOfWeek } from 'date-fns';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { DateRange } from 'react-day-picker';
import { useAuth } from './auth-provider';

interface CommonContextType {
    userDetail: any;
    setUserDetail: React.Dispatch<React.SetStateAction<any>>;
    selectedLocation: string | undefined;
    setSelectedLocation: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedBatch: string | undefined;
    setSelectedBatch: React.Dispatch<React.SetStateAction<string>>;
    selectedScheduleType: string;
    setSelectedScheduleType: React.Dispatch<React.SetStateAction<string>>;
    selectedDate: DateRange | undefined;
    setSelectedDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
    scheduleTypeList: []; // Adjusted to string array
    setScheduleTypeList: React.Dispatch<React.SetStateAction<[]>>; // Adjusted to accept string array
    locationList: any | undefined; // Adjusted to string array
    setLocationList: React.Dispatch<React.SetStateAction<any>>; // Adjusted to accept string array
    batchList: any | undefined; // Adjusted to string array
    setBatchList: React.Dispatch<React.SetStateAction<any>>; // Adjusted to accept string array
    timeSlotList: any | undefined; // Adjusted to string array
    setTimeSlotList: React.Dispatch<React.SetStateAction<any>>; // Adjusted to accept string array
    subjectList: any | undefined;
    setSubjectList: (subjects: any[]) => void;
    selectedSubject: string | undefined; // Add this line
    setSelectedSubject: React.Dispatch<React.SetStateAction<string | undefined>>; // Add this line
}

const CommonContext = createContext<CommonContextType | undefined>(undefined);

export const CommonProvider: React.FC<{ children: ReactNode }> = React.memo(({ children }) => {
    const [userDetail, setUserDetail] = useState<any>({});
    const [selectedLocation, setSelectedLocation] = useState<string>();
    const [selectedBatch, setSelectedBatch] = useState<string>('');
    const [selectedScheduleType, setSelectedScheduleType] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>();

    const [scheduleTypeList, setScheduleTypeList] = useState<[]>([]); // Initialize as empty array
    const [locationList, setLocationList] = useState<any>([]); // Initialize as empty array
    const [batchList, setBatchList] = useState<[]>([]); // Initialize as empty array
    const [timeSlotList, setTimeSlotList] = useState<[]>([]); // Initialize as empty array
    const [subjectList, setSubjectList] = useState<any[]>([]); // Initialize setSubjectList here
    const [selectedSubject, setSelectedSubject] = useState<string | undefined>(undefined);

    const { user } = useAuth();
    useEffect(() => {
        setUserDetail(user);
    }, [user]);
    useEffect(() => {
        if (userDetail) {
            setSelectedBatch('ALL');
        }
    }, [userDetail, locationList]);
    return (
        <CommonContext.Provider
            value={{
                userDetail,
                setUserDetail,
                selectedLocation,
                setSelectedLocation,
                selectedBatch,
                setSelectedBatch,
                selectedScheduleType,
                setSelectedScheduleType,
                selectedDate,
                setSelectedDate,
                scheduleTypeList,
                setScheduleTypeList,
                locationList,
                setLocationList,
                batchList,
                setBatchList,
                timeSlotList,
                setTimeSlotList,
                subjectList,
                setSubjectList,
                selectedSubject,
                setSelectedSubject,
            }}>
            {children}
        </CommonContext.Provider>
    );
});

export const useCommonProvider = (): CommonContextType => {
    const context = useContext(CommonContext);
    if (!context) {
        throw new Error('useCommonProvider must be used within a CommonProvider');
    }
    return context;
};

export default CommonContext;
