import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Navigate } from 'react-big-calendar';
import { DatePickerWithRange } from '@/components/shared/date-range-picker';
import { useCommonProvider } from '@/providers/common-provider';
import { useSearchParams } from 'react-router-dom';
import { startOfWeek, endOfWeek, addWeeks, subWeeks } from 'date-fns';
import { DateRange } from 'react-day-picker';
import moment from 'moment';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
interface ViewNamesGroupProps {
    views: string[];
    view: string;
    messages: { [key: string]: string };
    onView: (viewName: string) => void;
}

const ViewNamesGroup: React.FC<ViewNamesGroupProps> = ({ views, view, messages, onView }) => {
    const handleViewClick = useCallback((name: string) => () => onView(name), [onView]);
    console.log(views);
    return (
        <>
            {views.map((name) => (
                <button
                    type="button"
                    key={name}
                    className={clsx({ 'rbc-active': view === name })}
                    onClick={handleViewClick(name)}>
                    {messages[name]}
                </button>
            ))}
        </>
    );
};

export interface CustomCalendarToolbarProps {
    date: Date;
    label: string;
    localizer: {
        messages: {
            previous: string;
            today: string;
            next: string;
            [key: string]: string;
        };
    };
    onNavigate: (action: 'PREV' | 'TODAY' | 'NEXT') => void;
    onView: (viewName: string) => void;
    view: string;
    views: string[];
}

const CustomCalendarToolbar: React.FC<CustomCalendarToolbarProps> = ({
    date,
    label,
    localizer: { messages },
    onNavigate,
    onView,
    view,
    views,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>();
    // const views = ['week', 'agenda'];
    const dateParam = searchParams.get('date');

    useEffect(() => {
        if (dateParam) {
            const startDate = startOfWeek(dateParam, { weekStartsOn: 1 });
            const endDate = endOfWeek(dateParam, { weekStartsOn: 1 });
            setSelectedDate({
                from: startDate,
                to: endDate,
            });
        } else {
            const currentDate = new Date();
            setSelectedDate({
                from: startOfWeek(currentDate, { weekStartsOn: 1 }),
                to: endOfWeek(currentDate, { weekStartsOn: 1 }),
            });
        }
    }, [dateParam, setSelectedDate]);

    const handleonChange = (value: DateRange | undefined) => {
        if (value) {
            setSelectedDate(value);
            setSearchParams({
                ...Object.fromEntries(searchParams), // Keep existing search params
                date: moment(value.from).format('YYYY-MM-DD'),
            });
        }
    };

    const handleNavigate = (action: 'PREV' | 'NEXT' | 'TODAY') => {
        if (selectedDate && selectedDate.from && selectedDate.to) {
            // Ensure selectedDate and its properties are defined
            let newDateRange: DateRange | undefined;

            if (action === 'PREV') {
                newDateRange = {
                    from: subWeeks(selectedDate.from, 1),
                    to: subWeeks(selectedDate.to, 1),
                };
            } else if (action === 'NEXT') {
                newDateRange = {
                    from: addWeeks(selectedDate.from, 1),
                    to: addWeeks(selectedDate.to, 1),
                };
            } else if (action === 'TODAY') {
                newDateRange = {
                    from: startOfWeek(new Date(), { weekStartsOn: 1 }),
                    to: endOfWeek(new Date(), { weekStartsOn: 1 }),
                };
            }

            if (newDateRange) {
                setSelectedDate(newDateRange);
                setSearchParams({
                    ...Object.fromEntries(searchParams),
                    date: moment(newDateRange.from).format('YYYY-MM-DD'),
                });
                handleonChange(newDateRange);
                onNavigate(action); // Notify calendar to update view
            }
        }
    };

    return (
        <div className="rbc-toolbar">
            <span className="flex">
                <button
                    type="button"
                    onClick={() => handleNavigate('PREV')}
                    aria-label={messages.previous}>
                    &#60;
                </button>
                <div className="flex flex-col">
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={'outline'}
                                className={cn(
                                    'w-[280px] justify-start text-left font-normal',
                                    !selectedDate?.from && 'text-muted-foreground'
                                )}>
                                {selectedDate?.from ? (
                                    selectedDate.to ? (
                                        <>
                                            {format(selectedDate.from, 'LLL dd, y')} -{' '}
                                            {format(selectedDate.to, 'LLL dd, y')}
                                        </>
                                    ) : (
                                        format(selectedDate.from, 'PPP')
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single" // Use "single" mode if the calendar component only supports single dates
                                selected={selectedDate?.from} // Only pass a single date
                                onSelect={(newDate: any) =>
                                    handleonChange(
                                        newDate ? { from: newDate, to: newDate } : undefined
                                    )
                                } // Wrap in DateRange
                                initialFocus
                                weekStartsOn={1} // Set week start to Monday in the calendar
                            />
                        </PopoverContent>
                    </Popover>
                </div>
                <button
                    type="button"
                    onClick={() => handleNavigate('NEXT')}
                    aria-label={messages.next}>
                    &#62;
                </button>
            </span>
            <span className="ml-auto">
                <ViewNamesGroup view={view} views={views} messages={messages} onView={onView} />
            </span>
        </div>
    );
};

export default CustomCalendarToolbar;
