import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { BookOpenText, Edit, Home, Mail, MapPin, Phone, Trash, User } from 'lucide-react';
import { Cake, Briefcase } from 'lucide-react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const FacultyDetailPage: React.FC = () => {
    interface Event {
        title: string;
        start: Date;
        end: Date;
        color?: string;
    }

    interface EventComponentProps {
        event: Event;
    }

    const localizer = momentLocalizer(moment);
    const eventPropGetter = (event: Event): { style: React.CSSProperties } => {
        const backgroundColor = event.color || '#dfdfdf';
        return { style: { backgroundColor, color: '#000' } };
    };

    const EventComponent: React.FC<EventComponentProps> = ({ event }) => (
        <span className="text-xs block">
            <strong>{event.title}</strong>
            <span className="float-right">
                {moment(event.start).format('HH:mm')} to {moment(event.end).format('HH:mm')}
            </span>
        </span>
    );

    return (
        <div className="flex">
            <div className="w-full md:w-2/3 mr-3 lg:w-5/6 h-[calc(100vh-6rem)] overflow-auto rounded-lg bg-background shadow-lg">
                <Calendar
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    className="p-4"
                    style={{ height: '93%' }}
                    eventPropGetter={eventPropGetter}
                    step={30}
                    timeslots={1}
                    popup // Enable popup for events
                    showMultiDayTimes // Show times for multi-day events
                    views={['month', 'week', 'day', 'agenda']}
                    // components={{
                    //     month: {
                    //         event: EventComponent,
                    //         toolbar: (props) => <CustomToolbar view="month" {...props} />,
                    //     },
                    //     week: {
                    //         toolbar: (props) => <CustomToolbar view="week" {...props} />,
                    //     },
                    //     day: {
                    //         toolbar: (props) => <CustomToolbar view="day" {...props} />,
                    //     },
                    //     agenda: {
                    //         toolbar: (props) => <CustomToolbar view="agenda" {...props} />,
                    //     },
                    // }}
                />
            </div>
            <div className="w-full md:w-1/4 lg:w-1/5 h-[calc(100vh-6rem)] overflow-auto scrollbar-hide p-4 rounded-lg bg-background shadow-lg">
                <div className="text-center flex flex-col items-center p-4 shadow bg-muted relative">
                    <div className="absolute top-1 right-1 mt-1 mr-1">
                        <div className="flex m-1">
                            <Edit className="p-1" />
                            {/* <Trash className="p-1" /> */}
                        </div>
                    </div>
                    <Avatar className="w-20 h-20 shadow-xl border-2 rounded-md">
                        <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
                        <AvatarFallback>CN</AvatarFallback>
                    </Avatar>
                    <div className="mt-2 text-lg font-medium flex space-x-2 items-center">
                        <span>Ankita Patel</span>
                    </div>
                    <div className="flex flex-col space-y-2 mt-3 w-full">
                        <div className="flex items-center space-x-1">
                            <Mail size="16" className="text-primary" />
                            <span>ankita@gmail.com</span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <Phone size="16" className="text-primary" />
                            <span>+91 9876543210</span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <MapPin size="16" className="text-primary" />
                            <span>Swargate, Apte</span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <BookOpenText size="16" className="text-primary" />
                            <div className="flex items-center rounded-lg">
                                <div
                                    className="w-3 h-3"
                                    style={{ backgroundColor: '#f87b62' }}></div>
                                <span className="m-1 text-sm">English</span>
                            </div>
                            <div className="flex items-center rounded-lg">
                                <div
                                    className="w-3 h-3"
                                    style={{ backgroundColor: '#398F35' }}></div>
                                <span className="m-1 text-sm">History</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col space-y-2 p-3 shadow-lg bg-muted rounded-lg mt-3">
                    <div className="flex items-center">
                        <User className="text-primary mr-1" />
                        <span className="text-sm">Female</span>
                    </div>
                    <div className="flex items-center">
                        <Cake className="text-primary mr-1" />
                        <span className=" text-sm">34 years</span>
                    </div>
                    <div className="flex items-center">
                        <Briefcase className="text-primary mr-1" />
                        <span className=" text-sm">3 years of Experience</span>
                    </div>
                    <div className="flex items-center">
                        <Home className="text-primary mr-1" />
                        <span className=" text-sm">87, Bandra, Trichy - 537678</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FacultyDetailPage;
