import { ScheduleItem } from '@/types';
import CalHeatmap from 'cal-heatmap';

import 'cal-heatmap/cal-heatmap.css';
import { useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { QueryParams } from '@/lib/api';
import { useGetDoubtSessions } from '@/queries/faculty-query';

export const FacultyCalHeapMap = ({
    schedules,
    monthFromDate,
    monthToDate,
    faculty_id,
}: {
    schedules: ScheduleItem[];
    monthFromDate: string;
    monthToDate: string;
    faculty_id: string;
}) => {
    const params: QueryParams = {
        starting_date: monthFromDate,
        ending_date: monthToDate,
        faculty_id: faculty_id, // Optional, can be an empty string if not used
    };
    const { data: doubtSessionData } = useGetDoubtSessions(params);

    const hrsData = schedules.map((s) => {
        const [startTime, endTime] = s.slot_time.split('-');
        const start = moment(new Date(s.date + ' ' + startTime));
        const end = moment(new Date(s.date + ' ' + endTime));
        return {
            date: s.date,
            hrs: moment.duration(end.diff(start)).asMinutes(),
            start: start,
            end: end,
            slot_time: s.slot_time,
        };
    });
    const groupByData = _.groupBy(hrsData, 'date');

    const aggregateData = _.map(groupByData, (value: any, key: string) => {
        return {
            date: key,
            count: _.reduce(value, (result, obj) => ++result, 0),
            minStart: _.minBy(value, 'start') || value[0].start,
            maxEnd: _.maxBy(value, 'end') || value[0].end,
            totalMins: _.reduce(value, (result, obj) => result + obj.hrs, 0),
        };
    }).map((o) => {
        return {
            date: o.date,
            count: o.count,
            totalHrs: o.totalMins / 60,
            minStart: o.minStart,
            maxEnd: o.maxEnd,
            totalDuration: moment.duration(o.maxEnd.end.diff(o.minStart.start)).asHours(),
            intensity: o.totalMins >= 360 ? 2 : 1,
        };
    });
    const lastMonthOffset = moment().add(-1, 'month');
    const stat1 = aggregateData.filter(
        (aggObj) => moment(aggObj.date) >= lastMonthOffset && aggObj.totalDuration > 10
    ).length;
    const totalSessionsInMonth = _.sumBy(
        aggregateData.filter((aggObj) => moment(aggObj.date) >= lastMonthOffset),
        'count'
    );
    const durationWeeks = moment.duration(moment().diff(lastMonthOffset)).asWeeks();

    const heatMapContainer = useRef<HTMLDivElement>(null);

    // const data = [
    //     { date: '2024-08-01', intensity: 3, score: 30 },
    //     { date: '2024-08-02', intensity: 6, score: 70 },
    // ];

    const startDate = moment().add(-3, 'month').startOf('month');
    if (heatMapContainer.current) {
        heatMapContainer.current.innerHTML = '';
    }
    const cal = new CalHeatmap();
    cal.paint(
        {
            data: { source: aggregateData, x: 'date', y: 'totalDuration' },
            date: { start: startDate.toDate() },
            range: 5,
            scale: {
                color: {
                    type: 'quantize',
                    scheme: 'Reds',
                    domain: [0, 12],
                },
            },
            domain: {
                type: 'month',
            },
            subDomain: { type: 'day', radius: 2 },
            itemSelector: '#faculty-cal',
        },
        []
    );
    return (
        <>
            <div className="min-h-[352px] max-h-[352px]">
                <div id="legend" className="text-xs  p-2 text-center">
                    Heatmap for working hrs
                </div>
                <div
                    className="inline-block min-h-[98px]  max-h-[98px] w-100"
                    id="faculty-cal"
                    ref={heatMapContainer}></div>
                <hr className="relative w-full bg-gray-200 my-2 h-[0.2vh] border-0" />
                <div className="p-2">
                    <div className="py-2">
                        <strong>Last 30 Days</strong>
                    </div>
                    <div className="py-2">
                        <strong>
                            {((totalSessionsInMonth * 100) / (durationWeeks * 18)).toFixed(0)} %
                        </strong>
                        <span className="text-slate-700"> Utilisation </span>
                    </div>
                    <div className="py-2">
                        <strong>{doubtSessionData?.length || 0} </strong>
                        <span className="text-slate-700">
                            doubt session{doubtSessionData?.length === 1 ? '' : 's'}
                        </span>
                    </div>
                    <div className="py-2">
                        <strong>{stat1} Days</strong>{' '}
                        <span className="text-slate-700">worked for more than 10 hours.</span>
                    </div>
                </div>
            </div>
        </>
    );
};
