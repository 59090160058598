import React, { useRef, useState, memo } from 'react';
import * as d3 from 'd3';
import { scale } from '@observablehq/plot';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { X } from 'lucide-react';
import { useGetSchedules } from '@/queries/schedule-query';
import moment from 'moment';
import BigCalendar from '../shared/big-calendar/big-calendar';
import { Badge } from '../ui/badge';
import { convertTimeTo12HourFormat, isColorDark } from '@/common/functions';

const MARGIN = { top: 30, right: 30, bottom: 20, left: 50 };

type Tooltip = {
    visible: boolean;
    value?: { code: string; day: string; value: number };
    x: number;
    y: number;
};

type HeatmapProps = {
    containerWidth?: number;
    height: number;
    data: any[];
    rows: string[];
};

const Heatmap: React.FC<HeatmapProps> = ({ containerWidth = 0, height, data, rows }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [tooltip, setTooltip] = useState<Tooltip | null>(null);
    const [scrollOffset, setScrollOffset] = useState(0); // Track horizontal scroll position

    const faculties = Array.from(new Set(data.map((d) => d.code)));

    // const cellWidth = (containerWidth - MARGIN.left - MARGIN.right) / faculties.length;
    // const cellHeight = (height - MARGIN.top - MARGIN.bottom) / rows.length;
    const cellHeight = 28;
    // Create a color scale for each code using d3.scaleOrdinal
    // Create a color scale for each code using d3.scaleOrdinal
    interface ColorScaleInfo {
        color_code: d3.ScaleSequential<string>;
    }
    // Adjusted codeColorScales to be dependent on both code and day
    const codeColorScales: { [key: string]: ColorScaleInfo } = faculties.reduce(
        (scales, code) => {
            const colorCode: any = data.filter((d) => d.code === code) || null;
            colorCode.forEach((colorEntry: any) => {
                const day = colorEntry.day; // Assuming day is a property in the colorCode entry
                const key = `${code}_${day}`; // Unique key based on code and day

                const interpolateColor = colorEntry.subject_color_code
                    ? d3.interpolateRgb(d3.interpolateOrRd(0), colorEntry.subject_color_code)
                    : d3.interpolateRdYlGn;

                const maxValue = d3.max(data, (d) => d.value) || 1;

                const value = d3
                    .scaleSequential<string>(interpolateColor)
                    .domain([0, colorEntry.subject_color_code ? maxValue : 1]);
                scales[key] = { color_code: value }; // Use the unique key
            });
            return scales;
        },
        {} as { [key: string]: ColorScaleInfo }
    );
    return (
        <div
            ref={containerRef}
            className="flex flex-row overflow-x-auto"
            style={{ height: `${height}px`, position: 'relative' }}>
            <DaysColumn rows={rows} cellHeight={cellHeight} />
            <div>
                <div className="flex flex-row  sticky top-0 z-30">
                    {faculties?.map((code, index) => (
                        <CodeHeader key={index} code={code} cellHeight={cellHeight} />
                    ))}
                </div>
                {rows?.map((day, dayIndex) => (
                    <div className="relative" key={day}>
                        <div className="flex flex-row" style={{ height: `${cellHeight}px` }}>
                            {faculties?.map((code, codeIndex) => {
                                const value =
                                    data.find((d) => d.code === code && d.day === day)?.value || 0;
                                const details =
                                    data.find((d) => d.code === code && d.day === day)?.details ||
                                    {};
                                const key = `${code}_${day}`; // Create the key for lookup
                                const scaleInfo = codeColorScales[key] || {};
                                const { color_code } = scaleInfo; // Destructure scale and additionalParam
                                return (
                                    <Cell
                                        key={`${code}-${day}`}
                                        code={code}
                                        value={value}
                                        details={details}
                                        color={color_code ? color_code(value) : '#fff'} // Use code-specific color scale
                                        cellHeight={cellHeight}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Separate component for rendering the days column
const DaysColumn = memo(({ rows, cellHeight }: { rows: string[]; cellHeight: number }) => (
    <div className="flex-col sticky left-0 z-40 bg-background">
        <div className="flex w-auto mr-2 " style={{ height: `${cellHeight}px` }}>
            <div className="flex justify-center items-center bg-background">
                <span className="text-xs"></span>
            </div>
        </div>
        {rows?.map((day) => (
            <div key={day} className="flex w-auto  mr-2" style={{ height: `${cellHeight}px` }}>
                <div className="flex justify-center items-center">
                    <span className="text-xs">{day}</span>
                </div>
            </div>
        ))}
    </div>
));

// Separate component for each code header
const CodeHeader = memo(({ code, cellHeight }: { code: string; cellHeight: number }) => {
    const baseWidth = 8.8; // Adjust this value based on your font size and desired spacing
    const cellWidth = `${code.length * baseWidth + 15}px`;

    return (
        <div
            className="flex justify-center items-center border-b-2 border-gray-400  bg-background truncate p-2"
            style={{ width: cellWidth, height: `${cellHeight}px` }}>
            <span className="text-xs">{code}</span>
        </div>
    );
});

// Separate component for each cell in the heatmap
const Cell = memo(
    ({
        code,
        value,
        color,
        details,
        cellHeight,
    }: {
        code: string;
        value: number;
        details: any;
        color: string;
        cellHeight: number;
    }) => {
        color = details.leave !== '' ? '#e8e8e8' : color;
        const baseWidth = 8.8; // Adjust this value based on your font size and desired spacing
        const cellWidth = `${code.length * baseWidth + 15}px`;
        const [popoverData, setPopoverData] = useState<any>(null);
        const dark = isColorDark(color);
        const { data: scheduleList } = useGetSchedules(
            popoverData
                ? {
                      starting_date: moment(popoverData.schedule_date).format('YYYY-MM-DD'),
                      ending_date: moment(popoverData.schedule_date).format('YYYY-MM-DD'),
                      faculty_id: popoverData.faculty_id,
                  }
                : {},
            popoverData ? true : false
        );
        console.log(details);
        return (
            <Popover>
                <PopoverTrigger asChild>
                    <div
                        className="flex relative justify-center items-center border border-white transition duration-200 ease-in-out cursor-pointer p-2"
                        style={{
                            width: cellWidth,
                            height: `${cellHeight}px`,
                            backgroundColor: color,
                            color: dark ? 'white' : 'black',
                        }}
                        onClick={() => setPopoverData(details)}
                        aria-label={code}>
                        <span className="text-xs">
                            {details.leave !== '' && value === 0 ? 'L' : value}
                        </span>
                        {details.leave !== '' && value !== 0 && (
                            <span className="absolute bottom-0 right-0 text-xs">L</span>
                        )}
                    </div>
                </PopoverTrigger>
                <PopoverContent className="relative w-[28rem] z-50 bg-background shadow-2xl border-2 p-4 flex flex-col">
                    <div className="flex">
                        <span className="mb-2">{moment(details?.schedule_date).format('LL')}</span>
                        <PopoverClose className="ml-auto">
                            <X size={15} />
                        </PopoverClose>
                    </div>
                    <div className="flex flex-col mb-3">
                        <a
                            href={`/faculties/${details.faculty_id}`}
                            className="hover:font-bold text-lg"
                            target="_blank"
                            rel="noreferrer">
                            <span className="mb-2 ">
                                {popoverData?.faculty} - {popoverData?.first_name}{' '}
                                {popoverData?.last_name} ( {details?.subject_name} )
                            </span>
                        </a>
                    </div>
                    {value == 0 ? (
                        <div className="text-center text-sm">No Schedule Exist</div>
                    ) : (
                        <div className="grid grid-cols-2 gap-4  max-h-[16rem] overflow-auto">
                            {scheduleList?.map((schedule: any) => (
                                <ScheduleCard key={schedule.id} item={schedule} />
                            ))}
                        </div>
                    )}
                </PopoverContent>
            </Popover>
        );
    }
);
const ScheduleCard: React.FC<any> = ({ item }) => {
    return (
        <div>
            <div
                className="flex cursor-pointer bg-muted flex-col text-sm shadow-md border-l-8 relative py-2"
                style={{
                    borderColor: `${item?.subject?.subject_color_code}`,
                }}>
                {/* <PingIcon /> */}
                <span className="flex mx-2 flex-col items-start relative truncate ">
                    <span className="font-semibold">{item?.batch?.batch_code}</span>
                </span>
                <div className="flex flex-col mx-2 text-xs">
                    <span className="mt-0.5">{convertTimeTo12HourFormat(item?.slot_time)}</span>
                </div>
            </div>
        </div>
    );
};

export default Heatmap;
