'use client';

import React, { useEffect, useState } from 'react';
import { CalendarIcon } from '@radix-ui/react-icons';
import { format, startOfWeek, endOfWeek, addWeeks, subWeeks } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import moment from 'moment';

interface WeekPickerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
    minDate?: Date;
    maxDate?: Date;
    initialDateRange?: DateRange;
    onSubmit: (dateRange: DateRange | undefined) => void; // Prop for handling submission
    width?: string; // Add width prop
    border?: string;
    borderRadius?: string;
}

export function WeekPicker({
    className,
    width = 'w-[230px]', // default width if none provided
    border = '',
    borderRadius = '',
    minDate,
    maxDate,
    initialDateRange,
    onSubmit,
    ...props
}: WeekPickerProps) {
    const [date, setDate] = useState<DateRange | undefined>(initialDateRange);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [currentWeek, setCurrentWeek] = React.useState<Date>(new Date());
    useEffect(() => {
        if (date?.from) {
            setCurrentWeek(date.from);
        }
    }, [date]);

    const [currentMonth, setCurrentMonth] = React.useState<Date>(new Date());

    const isDateInRange = (d: Date) => {
        const dateToCheck = moment(d).startOf('day');
        const min = minDate ? moment(minDate).startOf('day') : null;
        const max = maxDate ? moment(maxDate).startOf('day') : null;
        if (min && dateToCheck.isBefore(min)) return false;
        if (max && dateToCheck.isAfter(max)) return false;
        return true;
    };

    useEffect(() => {
        setDate(initialDateRange);
        if (initialDateRange && initialDateRange['from']) setCurrentMonth(initialDateRange['from']);
    }, [initialDateRange]);

    const handleSubmit = () => {
        onSubmit(date);
        setPopoverOpen(false);
    };

    const handleCancel = () => {
        setDate(initialDateRange);
        setPopoverOpen(false);
    };

    const setToLastWeek = () => {
        const newWeek = subWeeks(currentWeek, 1);
        const from = startOfWeek(newWeek, { weekStartsOn: 1 });
        const to = endOfWeek(from, { weekStartsOn: 1 });
        if (isDateInRange(from) && isDateInRange(to)) {
            setCurrentWeek(newWeek);
            setDate({ from, to });
            setCurrentMonth(from);
        }
    };
    const setToCurrentWeek = () => {
        const newWeek = new Date();
        setCurrentWeek(newWeek);
        const from = startOfWeek(newWeek, { weekStartsOn: 1 });
        const to = endOfWeek(from, { weekStartsOn: 1 });
        setDate({ from, to });
        setCurrentMonth(from);
    };
    const setToNextWeek = () => {
        const newWeek = addWeeks(currentWeek, 1);
        const from = startOfWeek(newWeek, { weekStartsOn: 1 });
        const to = endOfWeek(from, { weekStartsOn: 1 });
        if (isDateInRange(from) && isDateInRange(to)) {
            setCurrentWeek(newWeek);
            setDate({ from, to });
            setCurrentMonth(from);
        }
    };
    const handleWeekSelect = (selectedDate: Date) => {
        console.log(selectedDate);
        const from = startOfWeek(selectedDate, { weekStartsOn: 1 }); // Week starts on Monday
        const to = endOfWeek(selectedDate, { weekStartsOn: 1 });
        // if (isDateInRange(from) && isDateInRange(to)) {
        setDate({ from, to });
        // }
    };
    const handleMonthChange = (month: Date) => {
        setCurrentMonth(month);
    };

    return (
        <div className={cn('grid gap-2', className)}>
            <Popover
                open={popoverOpen}
                onOpenChange={(open) => {
                    if (!open) {
                        // Reset the date only if it's different from the initial date range
                        if (
                            date?.from?.getTime() !== initialDateRange?.from?.getTime() ||
                            date?.to?.getTime() !== initialDateRange?.to?.getTime()
                        ) {
                            setDate(initialDateRange);
                        }
                    }
                    setPopoverOpen(open);
                }}>
                <PopoverTrigger asChild>
                    <Button
                        id="week-picker"
                        variant={'outline'}
                        className={cn(
                            `${width} ${border} ${borderRadius} justify-start text-left font-normal`,
                            !date && 'text-muted-foreground'
                        )}>
                        <span>
                            <CalendarIcon className="mr-2 h-4 w-4" />
                        </span>
                        {date?.from ? (
                            <>
                                {format(date.from, 'LLL dd, y')} - {format(date.to!, 'LLL dd, y')}
                            </>
                        ) : (
                            <span>Select a week</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range" // Keep range mode
                        selected={date}
                        fromDate={minDate}
                        toDate={maxDate}
                        onDayClick={(event) => {
                            handleWeekSelect(event);
                        }}
                        month={currentMonth} // Set the current month view
                        onMonthChange={handleMonthChange} // Handle month changes
                        weekStartsOn={1} // Set week start to Monday
                    />
                    <div className="flex gap-2 justify-center p-2">
                        <Button variant="outline" size="icon" onClick={setToLastWeek}>
                            {'<'}
                        </Button>
                        <Button variant="outline" onClick={setToCurrentWeek}>
                            Current Week
                        </Button>
                        <Button variant="outline" size="icon" onClick={setToNextWeek}>
                            {'>'}
                        </Button>
                    </div>
                    <div className="flex gap-2 justify-between p-4">
                        <Button variant="outline" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="default" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
}
