// LoadingContext.tsx
import React, { ReactNode, createContext, useContext, useState } from 'react';

// Define context type
type LoadingContextType = {
    isLoading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

// Create context with initial values
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

// Custom hook to use loading context
export const useLoadingProvider = (): LoadingContextType => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoadingProvider must be used within a LoadingProvider');
    }
    return context;
};
// Context provider component
export const LoadingProvider: React.FC<{ children: ReactNode }> = React.memo(({ children }) => {
    const [isLoading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ isLoading, setLoading }}>
            {children}
        </LoadingContext.Provider>
    );
});
