import { ColumnDef } from '@tanstack/react-table';
import React, { useState } from 'react';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../components/ui/dialog'; // Replace with your actual dialog library imports
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import DataTable from '@/components/shared/data-table';
import { DataTableSkeleton } from '@/components/shared/data-table-skeleton';
import { useDeleteUserData, useGetUsers } from '@/queries/users-query';
import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '@/lib/api';
import { useCommonProvider } from '@/providers/common-provider';
import { User } from '@/types';
import { useCommonSearch } from '@/components/shared/common-search';
import { Button } from '@/components/ui/button';
import { PlusCircle, ArrowUpDown, MoreHorizontal } from 'lucide-react';
import { Toaster } from '@/components/ui/toaster';
import { GetLocationList } from '@/common/functions';
import { LocationComponent } from '@/common/dropdowns';
import UserSubmit from './user-submit';
import { useToast } from '@/components/ui/use-toast';

function UserListPage() {
    const { toast } = useToast();
    const { selectedLocation } = useCommonProvider();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUserIdDelete, setSelectedUserIdDelete] = useState<any>();
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || 1);
    const limit = Number(searchParams.get('limit') || 50);
    const sortBy = String(searchParams.get('sortBy') || 'updated_at');
    const sortOrder = String(searchParams.get('sortOrder') || 'DESC');
    const { searchQuery } = useCommonSearch();
    const params: QueryParams = {
        page,
        limit,
        sortBy: sortBy,
        sortOrder: sortOrder,
        q: searchQuery || ' ',
        ...(selectedLocation && { location_id: selectedLocation }), // Conditionally add location_id
    };
    const [userId, setUserId] = useState<any>();
    const handleEdit = (id: any | undefined) => {
        if (id !== undefined) {
            setUserId(id);
            setIsDialogOpen(true);
        } else {
            console.log('ID is undefined');
        }
    };

    const columns: ColumnDef<User>[] = [
        {
            accessorKey: 'name',
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Name
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => <div className="">{row.original.name}</div>,
        },
        {
            accessorKey: 'email',
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Email
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => <div>{row.original.email}</div>,
        },
        {
            accessorKey: 'contact_number',
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                    Phone
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: ({ row }) => <div>{row.original.contact_number}</div>,
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem
                            onClick={() => handleEdit(row.original.id)}
                            className=" cursor-pointer ">
                            Edit
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            onClick={() => setSelectedUserIdDelete(row.original.id)}
                            className=" cursor-pointer ">
                            Delete
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
        },
    ];
    const { data, isLoading, isError, refetch } = useGetUsers(params);
    const { mutate: deleteUserData } = useDeleteUserData();
    const handleDeleteUser = () => {
        if (!selectedUserIdDelete) return;
        deleteUserData(
            { id: selectedUserIdDelete },
            {
                onSuccess: () => {
                    toast({
                        title: 'User Successfully Deleted',
                        description: 'The user was deleted successfully!',
                    });
                    refetch();
                },
                onError: (error: any) => {
                    console.error('Deletion failed:', error);
                    toast({
                        title: 'Deletion Failed',
                        description: 'There was an error deleting the user.',
                        variant: 'destructive', // Error variant
                    });
                },
            }
        );
        setSelectedUserIdDelete(null); // Reset selected batch ID
    };

    if (isError) {
        return <div>Error loading data</div>;
    }

    return (
        <div className="w-full h-[calc(100vh-6rem)] ooverflow-auto p-4 bg-background relative border">
            <Toaster />
            <div className="flex justify-between mb-4   ">
                <div className="mr-4">
                    {<GetLocationList />}
                    <LocationComponent showLabel={false} />
                </div>
                <div className="mt-1">
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                        <DialogTrigger asChild>
                            <Button
                                variant="outline"
                                className="text-primary rounded shadow"
                                onClick={() => {
                                    setIsDialogOpen(true);
                                }}>
                                <PlusCircle className="h-3.5 w-3.5 mr-2" />
                                New User
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="mx-auto p-3">
                            <DialogHeader>
                                {userId ? (
                                    <>
                                        <DialogTitle>Update User</DialogTitle>
                                        <DialogDescription>
                                            Update the user details
                                        </DialogDescription>
                                    </>
                                ) : (
                                    <>
                                        <DialogTitle>Create User</DialogTitle>
                                        <DialogDescription>Create a new user</DialogDescription>
                                    </>
                                )}
                            </DialogHeader>
                            <div className="bg-bg-muted/40">
                                <UserSubmit
                                    refetch={refetch}
                                    setUserId={setUserId}
                                    userId={userId} // Change here from userGetById to userId
                                    onClose={() => {
                                        setIsDialogOpen(false);
                                    }}
                                />
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>{' '}
            {isLoading ? (
                <DataTableSkeleton
                    columnCount={50}
                    filterableColumnCount={2}
                    searchableColumnCount={1}
                />
            ) : (
                <div>
                    {data && (
                        <DataTable
                            columns={columns}
                            data={data?.users?.data} // Use `data?.data` as the array of users is inside the `data` field
                            pageCount={Math.ceil((data?.users.total || 0) / limit)} // Use `total` from `data` for pagination
                        />
                    )}
                    <AlertDialog
                        open={!!selectedUserIdDelete}
                        onOpenChange={(open) => !open && setSelectedUserIdDelete(null)}>
                        <AlertDialogTrigger asChild>
                            <Button variant="outline" className="hidden" />
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This user will be deleted permanently. Do you want to continue ?
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setSelectedUserIdDelete(null)}>
                                    Cancel
                                </AlertDialogCancel>
                                <AlertDialogAction onClick={handleDeleteUser}>
                                    Continue
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            )}
        </div>
    );
}

export default UserListPage;
