import apiService, { get, QueryParams } from '@/lib/api';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

export const useGetLocations = (url: string) => {
    const queryKey: QueryKey = ['locations', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
    };

    const query = useQuery<any, Error>(options);

    return query;
};

export async function fetchLocationData(queryParsms: QueryParams): Promise<Array<any>> {
    const { page, limit, sortBy, sortOrder, q } = queryParsms;
    try {
        return apiService.get(`/getlocation`, {
            params: {
                q,
                limit,
                page,
                sortBy,
                sortOrder,
            },
        });
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useFetchLocationData = (params: QueryParams) => {
    return useQuery({
        queryKey: ['locationData', params],
        queryFn: async (): Promise<Array<any>> => fetchLocationData(params),
        initialData: () => {
            return [];
        },
    });
};
