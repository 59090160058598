import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SearchProviderProps {
    children: ReactNode;
}
interface SearchProviderState {
    searchQuery: string;
    setSearchQuery: (query: string) => void;
}

const SearchProviderContext = createContext<SearchProviderState | undefined>(undefined);

export default function SearchProvider({ children }: SearchProviderProps) {
    const [searchQuery, setSearchQuery] = useState<string>('');
    return (
        <SearchProviderContext.Provider value={{ searchQuery, setSearchQuery }}>
            {children}
        </SearchProviderContext.Provider>
    );
}

export const useCommonSearch = () => {
    const context = useContext(SearchProviderContext);
    if (context === undefined)
        throw new Error('useCommonSearch must be used within a SearchProvider');
    return context;
};
