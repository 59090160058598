import apiService, { del, fetchById, fetchData, get, patch, post, QueryParams } from '@/lib/api';
import { DoubtSessionData } from '@/types';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
async function createDoubtSessions({ postData }: { postData: DoubtSessionData }) {
    try {
        const response = await apiService.post(`/createDoubtSessions`, postData);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const useCreateDoubtSessionData = () => {
    // const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (params: DoubtSessionData) => createDoubtSessions({ postData: params }),
    });
};

interface DoubtSessionSearchParams {
    location_ids?: string | number;
    starting_date?: string; // Adjust date type as per your API's requirements
    ending_date?: string; // Adjust date type as per your API's requirements
    batch_id?: string | undefined;
    stream_code?: string;
}
export const useGetDoubtSessions = (params: DoubtSessionSearchParams, enable: boolean) => {
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = `/getDoubtSessions?${queryParams}`; //params.location_id ? `/getDoubtSession?${queryParams}` : '';
    const queryKey: QueryKey = ['doubtSessions', url];
    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            if (url && url !== '') {
                const result = await get(url); // Replace with your fetch logic
                return result;
            }
            throw new Error('URL is not provided.');
        },
        enabled: !!enable,
    };

    const query = useQuery<any, Error>(options);

    return query;
};

// Define your mutation function with correct type signature
export const useDeleteDoubtSession = async (id: any): Promise<unknown> => {
    const response = await del('/deleteDoubtSession/' + id);
    return response; // Assuming response.data is of type any
};

// Define your mutation function with correct type signature
export const useSubmitDoubtSession = async (formData: any): Promise<any> => {
    if (formData.id) {
        const response = await patch(`/updateDoubtSession/${formData.id}`, formData);
        return response; // Assuming response.data is of type any
    } else {
        const response = await post(`/createSingleDoubtSession`, formData);
        return response; // Assuming response.data is of type any
    }
};
