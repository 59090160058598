import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import React from 'react';
import { useMatches } from 'react-router-dom';

function Breadcrumbs() {
    const matches = useMatches();

    // Get the last match
    const lastMatch = matches[matches.length - 1];

    // If the last match has a handle with breadcrumbs, render them
    const crumbs = Array.isArray(lastMatch?.handle) ? lastMatch.handle : [];

    return (
        <Breadcrumb className="hidden md:flex">
            <BreadcrumbList>
                {/* Home / Dashboard breadcrumb */}
                <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                        <a href="/">Home</a>
                    </BreadcrumbLink>
                </BreadcrumbItem>

                {/* Dynamic breadcrumbs */}
                {crumbs.map((crumb: any, index: number) => (
                    <React.Fragment key={index}>
                        <BreadcrumbSeparator />
                        {index === crumbs.length - 1 ? (
                            // Render the last breadcrumb as the page name
                            <BreadcrumbItem>
                                <BreadcrumbPage>{crumb.name}</BreadcrumbPage>
                            </BreadcrumbItem>
                        ) : (
                            // Render the other breadcrumbs as links
                            <BreadcrumbItem>
                                <BreadcrumbLink asChild>
                                    <a href={crumb.path}>{crumb.name}</a>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        )}
                    </React.Fragment>
                ))}
            </BreadcrumbList>
        </Breadcrumb>
    );
}

export default Breadcrumbs;
