import { routes } from './routes';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ThemeProvider from '@/providers/theme-provider';
import SearchProvider from './components/shared/common-search';
import AuthProvider from './providers/auth-provider';
import { CommonProvider } from './providers/common-provider';
import { LoadingProvider } from './providers/loading-provider';
import Loader from './common/loader';
import { Toaster } from './components/ui/toaster';

export const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider storageKey="app-theme">
                <LoadingProvider>
                    <Toaster />
                    <AuthProvider>
                        <SearchProvider>
                            <CommonProvider>
                                <Loader />
                                <RouterProvider router={routes} />
                            </CommonProvider>
                        </SearchProvider>
                    </AuthProvider>
                </LoadingProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
