import { del, get, patch, post, put } from '@/lib/api';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

// Define your mutation function with correct type signature
export const useSubmitHoliday = async (formData: any): Promise<any> => {
    if (formData.id) {
        const response = await put(`/holidays/${formData.id}`, formData);
        return response; // Assuming response.data is of type any
    } else {
        const response = await post('/holidays', formData);
        return response; // Assuming response.data is of type any
    }
};
export const useGetHolidays = (id: string) => {
    // Construct URL based on params
    const url = `/holidays?location_id=` + id;

    const queryKey: QueryKey = ['facultyScheduleCount', url];

    const options: UseQueryOptions<any, Error> = {
        queryKey: queryKey,
        queryFn: async () => {
            const result = await get(url); // Replace with your fetch logic
            return result;
        },
    };
    const query = useQuery<any, Error>(options);

    return query;
};

// Define your mutation function with correct type signature
export const useDeleteHoliday = async (id: any): Promise<unknown> => {
    console.log(id);
    const response = await del('/holidays/' + id);
    return response; // Assuming response.data is of type any
};
